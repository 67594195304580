import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@/base-components/Tab';
import React, { Fragment, useEffect, useState } from 'react';
import Lucide from '@/base-components/Lucide';
import { useAuth } from '@/context/AmplifyAuthProvider';
import LoadingIcon from '@/base-components/LoadingIcon';
import { friendlyCount } from '@/utils/friendly_count';
import { useAppDispatch, useAppSelector } from '@/stores/redux/hooks';
import ChangePasswordForm from '@/components/ChangePasswordForm';
import VerticalBarChart from '@/components/VerticalBarChart';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { updateUser } from '@/graphql/mutations';
import avatarImg1 from '@/assets/images/avatar-1.jpg';
import avatarImg2 from '@/assets/images/avatar-2.png';
import avatarImg3 from '@/assets/images/avatar-3.png';
import avatarImg4 from '@/assets/images/avatar-4.png';
import avatarImg5 from '@/assets/images/avatar-5.png';
import avatarImg6 from '@/assets/images/avatar-6.png';
import avatarImg7 from '@/assets/images/avatar-7.png';
import { IUser } from '@/types/IUser';
import * as Yup from 'yup';

const ProfilePage = () => {
  // A preprocess function for phone numbers in Vietnam
  const preprocessPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber) {
      // If value does not start with +
      if (!phoneNumber.startsWith('+')) {
        // If value starts with 0, replace 0 with +84
        if (phoneNumber.startsWith('0')) {
          phoneNumber = phoneNumber.replace('0', '+84');
        } else {
          // Else add +84 to the beginning
          phoneNumber = '+84' + phoneNumber;
        }
      }
    }

    return phoneNumber;
  };

  // const studentEmail = (user: IUser) => {
  //   return !!user?.email.includes('ueh.edu.vn' || 'hutech.edu.vn');
  // };

  const { user, successMessage, errorMessage, setSuccessMessage, setErrorMessage } = useAuth();
  const [activeTab, setActiveTab] = useState('Tổng quan');
  const [initialValues, setInitialValues] = useState({
    email: user?.email,
    firstName: user?.firstName,
    lastName: user?.lastName,
    phoneNumber: user?.phoneNumber ? preprocessPhoneNumber(user?.phoneNumber) : '',
    password: '',
    studentEmail: ''
  });
  const userToken = useAppSelector((state) => state.userToken.token);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Reset errorMessage and successMessage on unmount
  useEffect(() => {
    return () => {
      setErrorMessage('');
      setSuccessMessage('');
    };
  }, [setErrorMessage, setSuccessMessage]);

  if (!user || !userToken || !userToken.id) {
    return (
      <div className='flex h-full w-full items-center justify-center'>
        <LoadingIcon icon='grid' className='h-12 w-12' />
      </div>
    );
  }

  const tabNavigation = ['Tổng quan', 'Đổi mật khẩu', 'Thông tin tài khoản', 'Xác thực sinh viên'];
  const handleTabClick = (index: number) => {
    setActiveTab(tabNavigation[index]);
    console.log('Clicked tab ' + tabNavigation[index]);
  };

  // get user's spent tokens history

  // Data for VerticalBarChart
  const data = [
    {
      date: '2021-01-01',
      value: userToken.spentTokenAmount
    }
  ];

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email không hợp lệ').required('Email không được để trống'),
    password: Yup.string().required('Mật khẩu không được để trống').min(8, 'Mật khẩu phải có ít nhất 8 ký tự'),
    firstName: Yup.string().required('Tên không được để trống'),
    lastName: Yup.string().required('Họ không được để trống'),
    phoneNumber: Yup.string().required('Số điện thoại không được để trống').min(10, 'Số điện thoại không hợp lệ'),
    confirmPassword: Yup.string()
      .required('Xác nhận mật khẩu không được để trống')
      .oneOf([Yup.ref('password'), null], 'Mật khẩu không khớp'),
    studentEmail: Yup.string()
      .email('Email không hợp lệ')
      .required('Email không được để trống')
      .matches(/(ueh.edu.vn|hutech.edu.vn)$/, 'Email phải là email sinh viên')
  });

  const onSubmit = (values: typeof initialValues) => {
    const { email, firstName, lastName, password, phoneNumber } = values;

    if (values) {
      const updatedUser = {
        email,
        firstName,
        lastName,
        phoneNumber
      };
      const action = {
        type: updateUser,
        payload: updatedUser
      };
      console.log('action', action);
      dispatch(action);
    }
  };

  const handleResetPasswordNavigate = () => {
    navigate('/auth/reset-password');
  };

  //random avatar image
  const imageCollection = [avatarImg1, avatarImg2, avatarImg3, avatarImg4, avatarImg5, avatarImg6, avatarImg7];
  const randomAvatarImg = () => {
    const randomIndex = Math.floor(Math.random() * imageCollection.length);
    return imageCollection[randomIndex];
  };

  return (
    <Fragment>
      <div className='intro-y mt-8 flex items-center'>
        <h2 className='mr-auto text-lg font-medium'>
          Xin chào, {user.firstName} {user.lastName} !
        </h2>
      </div>
      <TabGroup>
        {/* BEGIN: Profile Info */}
        <div className='intro-y box mt-5 px-5 pt-5'>
          <div className='-mx-5 flex flex-col border-b border-slate-200/60 pb-5 dark:border-darkmode-400 lg:flex-row'>
            <div className='flex flex-1 items-center justify-center px-5 lg:justify-start'>
              <div className='image-fit relative h-20 w-20 flex-none sm:h-24 sm:w-24 lg:h-32 lg:w-32'>
                <img alt='Milu chatbot' className='rounded-full' src={avatarImg6} />
              </div>
              <div className='ml-5'>
                <div className='w-24 truncate text-lg font-medium sm:w-40 sm:whitespace-normal'>{}</div>
              </div>
            </div>
            <div className='mt-6 flex-1 border-x border-t border-slate-200/60 px-5 pt-5 dark:border-darkmode-400 lg:mt-0 lg:border-t-0 lg:pt-0'>
              <div className='text-center font-medium lg:mt-3 lg:text-left'>Thông tin tài khoản</div>
              <div className='mt-4 flex flex-col items-center justify-center lg:items-start'>
                <div className='flex items-center truncate sm:whitespace-normal'>
                  <Lucide icon='Mail' className='mr-2 h-4 w-4' />
                  {user.email}
                </div>
                <div className='mt-3 flex items-center truncate sm:whitespace-normal'>
                  <Lucide icon='Phone' className='mr-2 h-4 w-4' /> {user.phoneNumber}
                </div>
              </div>
            </div>
            <div className='mt-6 flex flex-1 items-center justify-center border-t border-slate-200/60 px-5 pt-5 dark:border-darkmode-400 lg:mt-0 lg:border-0 lg:pt-0'>
              <div className='w-20 rounded-md py-3 text-center'>
                <div className='text-xl font-medium text-primary'>{friendlyCount(userToken.spentTokenAmount)}</div>
                <div className='text-slate-500'>Số Intelium đã sử dụng</div>
              </div>
              <div className='w-20 rounded-md py-3 text-center'>
                <div className='text-xl font-medium text-primary'>{friendlyCount(userToken.totalTokenAmount - userToken.spentTokenAmount)}</div>
                <div className='text-slate-500'>Số Intelium còn lại</div>
              </div>
            </div>
          </div>
          <TabList className='nav-link-tabs flex-col justify-center text-center sm:flex-row lg:justify-start'>
            {tabNavigation.map((tabName) => (
              <Tab
                key={tabName}
                fullWidth={false}
                className='flex cursor-pointer items-center py-4'
                onClick={() => handleTabClick(tabNavigation.indexOf(tabName))}>
                <Lucide icon='User' className='mr-2 h-4 w-4' /> {tabName}
              </Tab>
            ))}
          </TabList>
        </div>
        {/* END: Profile Info */}

        <TabPanels className='mt-5'>
          {activeTab === tabNavigation[0] ? (
            <TabPanel>
              <VerticalBarChart data={data} width={document.querySelector('.intro-y.box')?.clientWidth} height={300} />
            </TabPanel>
          ) : activeTab === tabNavigation[1] ? (
            <TabPanel>
              <ChangePasswordForm />
            </TabPanel>
          ) : activeTab === tabNavigation[2] ? (
            <TabPanel>
              <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                <Form className='grid grid-cols-12 gap-6'>
                  <div className='intro-y box col-span-12'>
                    <div className='flex items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400 sm:py-3'>
                      <h2 className='mr-auto text-base font-medium'>Thông tin tài khoản</h2>
                    </div>
                    <div className='grid grid-cols-1 gap-6 p-5'>
                      <div className='grid grid-cols-1 gap-6 px-20'>
                        <div className='flex'>
                          <label className='mb-2 mt-5 block w-1/6 text-sm font-medium text-gray-700 dark:text-gray-300'>Tên</label>
                          <Field type='text' name='firstName' className='intro-x login__input form-control block py-3 px-4' placeholder='Tên' />
                          <ErrorMessage name='firstName'>{(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}</ErrorMessage>
                        </div>
                        <div className='flex'>
                          <label className='mb-2 mt-5 block w-1/6 text-sm font-medium text-gray-700 dark:text-gray-300'>Họ</label>
                          <Field type='text' name='lastName' className='intro-x login__input form-control block py-3 px-4' placeholder='Họ' />
                          <ErrorMessage name='lastName'>{(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}</ErrorMessage>
                        </div>
                        <div className='flex'>
                          <label className='mb-2 mt-5 block w-1/6 text-sm font-medium text-gray-700 dark:text-gray-300'>Email</label>
                          <Field type='email' name='email' className='intro-x login__input form-control block py-3 px-4' placeholder='Email' />
                          <ErrorMessage name='email'>{(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}</ErrorMessage>
                        </div>
                        <div className='flex'>
                          <label className='mb-2 mt-5 block w-1/6 text-sm font-medium text-gray-700 dark:text-gray-300'>Số điện thoại</label>
                          <Field
                            type='text'
                            name='phoneNumber'
                            className='intro-x login__input form-control block py-3 px-4'
                            placeholder='Số điện thoại'
                          />
                          <ErrorMessage name='phoneNumber'>
                            {(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}
                          </ErrorMessage>
                        </div>
                        <div className='flex'>
                          <label className='mb-2 mt-5 block w-1/6 text-sm font-medium text-gray-700 dark:text-gray-300'>Mật khẩu</label>
                          <div className='intro-x login__input form-control block py-3'>
                            <Field
                              type='password'
                              name='password'
                              className='intro-x login__input form-control block py-3 px-4'
                              placeholder='Mật khẩu'
                              value='********'
                            />
                            <a className='mt-2 text-primary' onClick={handleResetPasswordNavigate}>
                              Cài lại mật khẩu
                            </a>
                          </div>
                        </div>
                        <div className='flex justify-center gap-4'>
                          <button type='submit' className='btn btn-primary mt-3 w-fit py-3 px-4 align-top xl:mr-3 xl:w-32'>
                            Xác nhận
                          </button>

                          <button
                            type='reset'
                            className='btn btn-outline-secondary mt-3 w-fit py-3 px-4 align-top xl:mr-3 xl:w-32'
                            onClick={() => setInitialValues(initialValues)}>
                            Hủy bỏ
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </TabPanel>
          ) : (
            <TabPanel>
              <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                <Form className='grid grid-cols-12 gap-6'>
                  <div className='intro-y box col-span-12'>
                    <div className='flex items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400 sm:py-3'>
                      <h2 className='mr-auto text-base font-medium'>Xác thực tài khoản sinh viên</h2>
                    </div>
                    <div className='grid grid-cols-1 gap-6 p-5'>
                      <div className='grid grid-cols-1 gap-6 px-20'>
                        {successMessage && (
                          <div className='mt-4 flex items-center text-center text-green-500'>
                            <Lucide icon='CheckCircle' className='mr-2 text-green-500' />
                            <div className='text-lg'>{successMessage}</div>
                          </div>
                        )}
                        {errorMessage && (
                          <div className='mt-4 flex items-center text-center text-red-500'>
                            <Lucide icon='XCircle' className='mr-2 text-red-500' />
                            <div className='text-lg'>{errorMessage}</div>
                          </div>
                        )}
                        <div className='flex'>
                          <label className='mb-2 mt-5 block w-1/6 text-sm font-medium text-gray-700 dark:text-gray-300'>Email sinh viên</label>
                          <Field
                            type='text'
                            name='studentCode'
                            className='intro-x login__input form-control block py-3 px-4'
                            placeholder='Email sinh viên'
                          />
                          <ErrorMessage name='studentEmail'>
                            {(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}
                          </ErrorMessage>
                        </div>
                        <div className='flex justify-center gap-4'>
                          <button type='submit' className='btn btn-primary mt-3 w-fit py-3 px-4 align-top xl:mr-3 xl:w-32'>
                            Xác nhận
                          </button>

                          <button
                            type='reset'
                            className='btn btn-outline-secondary mt-3 w-fit py-3 px-4 align-top xl:mr-3 xl:w-32'
                            onClick={() => setInitialValues(initialValues)}>
                            Hủy bỏ
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </TabPanel>
          )}
        </TabPanels>
      </TabGroup>
    </Fragment>
  );
};

export default ProfilePage;
