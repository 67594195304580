import Lucide from '../../base-components/Lucide';
import React, { memo, useEffect, useRef } from 'react';
import LoadingIcon from '../../base-components/LoadingIcon';
import moment from 'moment';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { useAppSelector } from '@/stores/redux/hooks';
import { selectAllMessages } from '@/stores/redux/chat-room/chat-room-slice';
import HumanMessage from './HumanMessage';
import ChatbotMessage from './ChatbotMessage';
import chatbotImg from '@/assets/images/logo-milu.png';
import avatarImg from '@/assets/images/avatar-6.png';
import { selectAllAgents } from '@/stores/redux/agents/agents-slice';
import { useChat } from '@/context/ChatProvider';

const MessageBoard = () => {
  const inputEl = useRef<HTMLTextAreaElement>(null);
  const chatContentDiv = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const messages = useAppSelector((state) => selectAllMessages(state));
  const currentChatRoom = useAppSelector((state) => state.chat.chatRoom);
  const { socket, isWaitingForResponse, sendMessage } = useChat();
  const agents = useAppSelector((state) => selectAllAgents(state));

  useEffect(() => {
    if (chatContentDiv.current) {
      chatContentDiv.current.scrollTop = chatContentDiv.current.scrollHeight;
    }
  }, [messages]);

  const handleKeyInput = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Check if enter key is pressed and there is no "ctrl" or "shift" key pressed
    if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
      e.preventDefault();
      if (isWaitingForResponse.current) {
        return;
      } else {
        const messageText = inputEl.current?.value.trim();
        if (messageText) {
          sendMessage(messageText);
        }
        inputEl.current!.value = '';
      }
    }
  };

  if (!socket || !user || !currentChatRoom.isReady || !currentChatRoom.id) {
    return (
      <div className='flex h-full w-full items-center justify-center'>
        <LoadingIcon icon='grid' className='h-12 w-12' />
      </div>
    );
  }

  const handleResetMessageBoard = () => {
    // TODO (MinhLuan): reset message board later
    // const action = window.confirm('Are you sure you want to reset the message board?');
    // if (action) {
    //   //reset chat room
    //   dispatch(chatRoomActions.resetChatRoom());
    // }
  };

  return (
    <div className='absolute inset-0 flex flex-col sm:h-[60rem] md:h-[55rem] lg:h-full'>
      <div className='hidden border-b border-slate-200/60 px-5 py-4 dark:border-darkmode-400 sm:block md:flex md:flex-row'>
        <div className='flex items-center'>
          <div className='image-fit relative h-10 w-10 flex-none sm:h-12 sm:w-12'>
            <img alt='Milu chatbot' className='rounded-full' src={chatbotImg} />
          </div>
          <div className='ml-3 mr-auto'>
            <div className='text-base font-medium'>{agents.find((agent) => agent.id === currentChatRoom.agentID)?.name || 'Milu'}</div>
            <div className='text-xs text-slate-500 sm:text-sm'>I am a chatbot. I am glad to answer your questions.</div>
          </div>
        </div>
        <div className='mt-3 flex items-center sm:ml-auto sm:mt-0'>
          {/*<button className='button button--primary button--sm' onClick={handleResetMessageBoard}>*/}
          {/*  <ArrowPathIcon className='h-6 w-6' />*/}
          {/*</button>*/}
        </div>
      </div>
      <div className='scrollbar-hidden flex-1 overflow-y-scroll px-5' ref={chatContentDiv}>
        <div className='mb-10 mt-5 text-center text-xs text-slate-400 dark:text-slate-500'>{moment().format('DD MMMM YYYY')}</div>
        {messages.map((message, index) => {
          if (message.userID === user?.id) {
            return <HumanMessage key={index} message={message} photo={avatarImg} />;
          } else {
            return <ChatbotMessage key={index} message={message} photo={chatbotImg} />;
          }
        })}
      </div>
      <div className='flex items-center border-t border-slate-200/60 pt-4 pb-10 dark:border-darkmode-400 sm:py-4'>
        <textarea
          className='chat__box__input form-control h-16 resize-none border-transparent px-5 py-3 shadow-none focus:border-transparent focus:ring-0 dark:bg-darkmode-600'
          rows={1}
          placeholder='Type your message...'
          onKeyPress={handleKeyInput}
          ref={inputEl}></textarea>
        <a
          className='mr-5 flex h-8 w-8 flex-none items-center justify-center rounded-full bg-primary text-white sm:h-10 sm:w-10'
          onClick={(e) => {
            e.preventDefault();
            if (isWaitingForResponse.current) {
              return;
            } else {
              const messageText = inputEl.current?.value.trim();
              if (messageText) {
                sendMessage(messageText);
              }
              inputEl.current!.value = '';
            }
          }}>
          {isWaitingForResponse.current ? (
            <LoadingIcon icon='three-dots' className='h-4 w-4' color='white' />
          ) : (
            <Lucide icon='Send' className='h-4 w-4' />
          )}
        </a>
      </div>
    </div>
  );
};

export default memo(MessageBoard);
