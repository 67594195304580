import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '@/context/AmplifyAuthProvider';
import Lucide from '@/base-components/Lucide';

const ChangePasswordForm = () => {
  const { changePassword, errorMessage, successMessage } = useAuth();

  const handleSubmit = async (values: any) => {
    const { oldPassword, newPassword } = values;

    if (oldPassword === newPassword) {
      alert('Mật khẩu mới không được trùng với mật khẩu cũ');
      return;
    }

    // Change password
    try {
      await changePassword(oldPassword, newPassword);
    } catch (error) {
      console.log('Error changing password: ', error);
    }
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required('Required'),
    newPassword: Yup.string()
      .required('Mật khẩu không được để trống')
      .min(8, 'Mật khẩu phải có ít nhất 8 ký tự')
      .max(50, 'Mật khẩu không được quá 50 ký tự')
      .notOneOf([Yup.ref('oldPassword'), null], 'Mật khẩu mới không được trùng với mật khẩu cũ'),
    confirmPassword: Yup.string()
      .required('Xác nhận mật khẩu không được để trống')
      .oneOf([Yup.ref('newPassword'), null], 'Mật khẩu xác nhận không khớp')
  });

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      <Form className='grid grid-cols-12 gap-6'>
        <div className='intro-y box col-span-12'>
          <div className='flex items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400 sm:py-3'>
            <h2 className='mr-auto text-base font-medium'>Thay đổi mật khẩu</h2>
          </div>
          <div className='grid grid-cols-1 gap-6 p-5'>
            <div className='grid grid-cols-1 gap-6 px-20'>
              {/*BEGIN: Success or error message. Success message in green, error message in red*/}
              {successMessage && (
                <div className='mt-4 flex items-center text-center text-green-500'>
                  <Lucide icon='CheckCircle' className='mr-2 text-green-500' />
                  <div className='text-lg'>{successMessage}</div>
                </div>
              )}
              {errorMessage && (
                <div className='mt-4 flex items-center text-center text-red-500'>
                  <Lucide icon='XCircle' className='mr-2 text-red-500' />
                  <div className='text-lg'>{errorMessage}</div>
                </div>
              )}
              {/* END: Success or error message */}
              <div className='mt-3'>
                <Field id='oldPassword' type='password' className='form-control' placeholder='Mật khẩu cũ' name='oldPassword' />
                <ErrorMessage name='oldPassword'>{(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}</ErrorMessage>
              </div>
              <div className='mt-3'>
                <Field id='newPassword' type='password' className='form-control' placeholder='Mật khẩu mới' name='newPassword' />
                <ErrorMessage name='newPassword'>{(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}</ErrorMessage>
              </div>
              <div className='mt-3'>
                <Field id='confirmPassword' type='password' className='form-control' placeholder='Xác thực mật khẩu mới' name='confirmPassword' />
                <ErrorMessage name='confirmPassword'>{(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}</ErrorMessage>
              </div>
              <div className='flex justify-center'>
                <button className='btn btn-primary mt-5' type='submit'>
                  Đổi mật khẩu
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
export default ChangePasswordForm;
