import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import chat from './chat';
import sideMenuSlice from './side-menu/side-menu-slice';
import usersSlice from '@/stores/redux/users/users-slice';
import userTokenSlice from '@/stores/redux/user-token/user-token-slice';
import cartSlice from '@/stores/redux/cart/cart-slice';
import productSlice from '@/stores/redux/product/product-slice';
import agentsSlice from '@/stores/redux/agents/agents-slice';

const store = configureStore({
  reducer: {
    sideMenu: sideMenuSlice.reducer,
    chat: chat,
    agents: agentsSlice.reducer,
    users: usersSlice.reducer,
    userToken: userTokenSlice.reducer,
    cart: cartSlice.reducer,
    products: productSlice.reducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Simple Thunk type
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

export default store;
