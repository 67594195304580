import { useRecoilValue, useSetRecoilState } from 'recoil';
import { darkMode as darkModeStore, darkModeValue } from '../stores/recoil/dark-mode';
import dom from '@left4code/tw-starter/dist/js/dom';
import classnames from 'classnames';
import { Fragment } from 'react';
import Tippy from '../base-components/Tippy';

const DarkModeSwitcher = () => {
  const darkMode = useRecoilValue(darkModeStore);
  const setDarkModeValue = useSetRecoilState(darkModeValue);

  const setDarkModeClass = () => {
    darkMode ? dom('html').addClass('dark') : dom('html').removeClass('dark');
  };

  const switchMode = () => {
    setDarkModeValue(() => !darkMode);
    localStorage.setItem('darkMode', !darkMode);
    setDarkModeClass();
  };

  setDarkModeClass();

  return (
    <Fragment>
      {/* BEGIN: Dark Mode Switcher */}
      <div className='dark-mode-switcher cursor-pointer shadow-md box border w-full h-12 flex items-center justify-center z-50' onClick={switchMode}>
        <Tippy content={darkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'} placement='top'>
          <div
            className={classnames({
              'dark-mode-switcher__toggle border': true,
              'dark-mode-switcher__toggle--active': darkMode
            })}></div>
        </Tippy>
      </div>
      {/* END: Dark Mode Switcher */}
    </Fragment>
  );
};

export default DarkModeSwitcher;
