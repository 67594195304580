import { createElement, useEffect, useRef } from 'react';
import Tippy from '../base-components/Tippy';
import PropTypes from 'prop-types';
import dom from '@left4code/tw-starter/dist/js/dom';

const toggleTooltip = (el) => {
  if (dom(window).width() <= 1260) {
    el._tippy.enable();
  } else {
    el._tippy.disable();
  }
};

const initTooltipEvent = (tippyRef) => {
  window.addEventListener('resize', () => {
    toggleTooltip(tippyRef);
  });
};

const SideMenuTooltip = (props) => {
  const tippyRef = useRef();

  useEffect(() => {
    toggleTooltip(tippyRef.current);
    initTooltipEvent(tippyRef.current);
  }, []);

  const { tag, ...computedProps } = props;
  return createElement(
    Tippy,
    {
      ...computedProps,
      tag: props.tag,
      options: { placement: 'left' },
      getRef: (el) => {
        tippyRef.current = el;
      }
    },
    props.children
  );
};

SideMenuTooltip.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

SideMenuTooltip.defaultProps = {
  tag: 'span'
};

export default SideMenuTooltip;
