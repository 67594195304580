import dom from '@left4code/tw-starter/dist/js/dom';
import { ReactComponent as LogoImg } from '@/assets/images/logo.svg';
import { ReactComponent as IllustrationImg } from '@/assets/images/illustration.svg';
import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import PasswordStrengthBar from 'react-password-strength-bar';
import Lucide from '../base-components/Lucide';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AmplifyAuthProvider';
import LoadingIcon from '../base-components/LoadingIcon';
import { isValidPhoneNumber } from 'libphonenumber-js';
import logoImg from '@/assets/images/logo-milu-large.png';

// A preprocess function for phone numbers in Vietnam
const preprocessPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber) {
    // If value does not start with +
    if (!phoneNumber.startsWith('+')) {
      // If value starts with 0, replace 0 with +84
      if (phoneNumber.startsWith('0')) {
        phoneNumber = phoneNumber.replace('0', '+84');
      } else {
        // Else add +84 to the beginning
        phoneNumber = '+84' + phoneNumber;
      }
    }
  }

  return phoneNumber;
};

const SignUpPage = () => {
  const [password, setPassword] = useState<string>('');
  const navigate = useNavigate();
  const { isSignedIn, signUp, errorMessage, successMessage, setErrorMessage, setSuccessMessage } = useAuth();

  useEffect(() => {
    dom('body').removeClass('main').removeClass('error-page').addClass('login');
  }, []);

  // Navigate to dashboard if already signed in
  useEffect(() => {
    if (isSignedIn) {
      navigate('/', { replace: true });
    }
  }, [isSignedIn, navigate]);

  // Reset errorMessage and successMessage on unmount
  useEffect(() => {
    return () => {
      setErrorMessage('');
      setSuccessMessage('');
    };
  }, [setErrorMessage, setSuccessMessage]);

  const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    password: '',
    confirmPassword: ''
  };

  Yup.addMethod(Yup.string, 'phoneNumberValidate', function (message) {
    return this.test('phoneNumber', message, function (value) {
      const { path, createError } = this;
      if (value) {
        value = preprocessPhoneNumber(value);
        const isValid = isValidPhoneNumber(value);
        return isValid || createError({ path, message });
      } else {
        return createError({ path, message: 'Vui lòng nhập số điện thoại' });
      }
    });
  });

  const validationSchema = Yup.object().shape({
    // @ts-ignore
    email: Yup.string().email('Email không hợp lệ').required('Vui lòng nhập email'),
    firstName: Yup.string().required('Vui lòng nhập tên'),
    lastName: Yup.string().required('Vui lòng nhập họ'),
    // @ts-ignore
    phoneNumber: Yup.string().phoneNumberValidate('Số điện thoại không hợp lệ'),
    password: Yup.string().required('Vui lòng nhập mật khẩu').min(8, 'Mật khẩu phải có ít nhất 8 ký tự').max(50, 'Mật khẩu không được quá 50 ký tự'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Mật khẩu không khớp')
      .required('Vui lòng nhập lại mật khẩu')
  });

  const onSubmit = async (values: any) => {
    const { email, firstName, lastName, password, phoneNumber } = values;

    // Preprocess phone number
    const phoneNumberPreprocessed = preprocessPhoneNumber(phoneNumber);

    try {
      await signUp(email, password, firstName, lastName, phoneNumberPreprocessed);

      // Redirect to code verification page
      navigate('/auth/code-verification', {
        state: {
          email,
          password
        }
      });
    } catch (err) {
      // Handle error
      console.log('Sign up error: ', err);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <div>
      <div className='container sm:px-10'>
        <div className='block grid-cols-2 gap-4 xl:grid'>
          {/* BEGIN: Register Info */}
          <div className='hidden min-h-screen flex-col xl:flex'>
            <a href='/auth/signup' className='-intro-x flex items-center pt-5'>
              <img src={logoImg} alt='Milu chatbot' className='h-12 w-12' />
              <span className='logo__text ml-1 text-2xl font-extrabold text-white'>Milu</span>
              <span className='text-2xl font-light text-white'> AI</span>
            </a>
            <div className='my-auto'>
              <IllustrationImg className='-intro-x -mt-16 w-1/2' />
              <div className='-intro-x mt-10 text-4xl font-medium leading-tight text-white'>
                Một vài bước nữa để đăng nhập
                <br /> vào tài khoản của bạn.
              </div>
              <div className='-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-slate-400'>Trò chuyện với trợ lý thông minh AI ngay.</div>
            </div>
          </div>
          {/* END: Register Info */}
          {/* BEGIN: Register Form */}
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ isSubmitting }) => (
              <Form className='my-10 flex h-screen py-5 xl:my-0 xl:h-auto xl:py-0'>
                <div className='m-auto w-full rounded-md bg-white py-8 px-5 shadow-md dark:bg-darkmode-600 sm:w-3/4 sm:px-8 lg:w-2/4 xl:ml-20 xl:w-auto xl:bg-transparent xl:p-0 xl:shadow-none'>
                  <h2 className='intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl'>Đăng ký</h2>
                  {/*BEGIN: Success or error message. Success message in green, error message in red*/}
                  {successMessage && (
                    <div className='mt-4 flex items-center text-center text-green-500'>
                      <Lucide icon='CheckCircle' className='mr-2 text-green-500' />
                      <div className='text-lg'>{successMessage}</div>
                    </div>
                  )}
                  {errorMessage && (
                    <div className='mt-4 flex items-center text-center text-red-500'>
                      <Lucide icon='XCircle' className='mr-2 text-red-500' />
                      <div className='text-lg'>{errorMessage}</div>
                    </div>
                  )}
                  {/* END: Success or error message */}
                  <div className='intro-x mt-2 text-center text-slate-400 dark:text-slate-400 xl:hidden'>
                    Một vài bước nữa để đăng nhập vào tài khoản của bạn. Trò chuyện với trợ lý thông minh AI ngay.
                  </div>
                  <div className='intro-x mt-8'>
                    <Field type='text' name='firstName' className='intro-x login__input form-control block py-3 px-4' placeholder='Tên' />
                    <ErrorMessage name='firstName'>{(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}</ErrorMessage>
                    <Field type='text' name='lastName' className='intro-x login__input form-control mt-4 block py-3 px-4' placeholder='Họ' />
                    <ErrorMessage name='lastName'>{(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}</ErrorMessage>
                    <Field type='email' name='email' className='intro-x login__input form-control mt-4 block py-3 px-4' placeholder='Email' />
                    <ErrorMessage name='email'>{(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}</ErrorMessage>
                    <Field
                      type='text'
                      name='phoneNumber'
                      className='intro-x login__input form-control mt-4 block py-3 px-4'
                      placeholder='Số điện thoại'
                    />
                    <ErrorMessage name='phoneNumber'>{(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}</ErrorMessage>
                    <Field
                      type='password'
                      name='password'
                      className='intro-x login__input form-control mt-4 block py-3 px-4'
                      placeholder='Mật khẩu'
                      onKeyUp={handlePasswordChange}
                    />
                    <ErrorMessage name='password'>{(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}</ErrorMessage>
                    <PasswordStrengthBar password={password} />
                    <Field
                      type='password'
                      name='confirmPassword'
                      className='intro-x login__input form-control mt-4 block py-3 px-4'
                      placeholder='Xác nhận mật khẩu'
                    />
                    <ErrorMessage name='confirmPassword'>{(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}</ErrorMessage>
                  </div>
                  <div className='intro-x mt-5 text-center xl:mt-8 xl:text-left'>
                    {!isSubmitting ? (
                      <button type='submit' className='btn btn-primary w-full py-3 px-4 align-top xl:mr-3 xl:w-32'>
                        Đăng ký
                      </button>
                    ) : (
                      <button type='submit' className='btn btn-outline-primary w-full py-3 px-4 align-top xl:mr-3 xl:w-32' disabled>
                        <LoadingIcon icon='spinning-circles' className='mr-1 h-5 w-5 -translate-x-4' />{' '}
                        <span className='-translate-x-3'>Đang đăng ký</span>
                      </button>
                    )}
                    <Link to='/auth/signin'>
                      <button className='btn btn-outline-secondary mt-3 w-full py-3 px-4 align-top xl:mt-0 xl:w-32'>Đăng nhập</button>
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          {/* END: Register Form */}
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
