import React, { Fragment } from 'react';
import TopBar from '@/components/TopBar';
import Footer from '@/components/Footer';

export default function PaymentPolicyPage() {
  return (
    <Fragment>
      <TopBar />
      <div className='bg-white'>
        <div className='relative overflow-hidden'>
          <main>
            {/* Feature section with screenshot */}
            <div className='relative overflow-hidden bg-gray-900 pt-16'>
              <div aria-hidden='true' className='absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-900' />
              <div className='relative'>
                <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
                  <div>
                    <div className='my-6'>
                      <span className='block bg-gradient-to-r from-teal-200 to-cyan-400  bg-clip-text text-4xl font-bold tracking-tight text-transparent pt-16'>
                        Chính sách bảo mật thanh toán của Promete
                      </span>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>Mục đích áp dụng</p>
                      <p className='mt-4 text-lg font-light text-white'>
                        Hệ thống thanh toán trên website https://milu.promete.ai/ (“Milu”) được kết nối với các đối tác thứ ba như Asiapay, các Ngân
                        hàng nội địa và Quốc tế (“Đối Tác Cổng Thanh Toán”) đã được cấp phép hoạt động hợp pháp tại Việt Nam. Theo đó, các tiêu chuẩn
                        bảo mật thanh toán của Milu đảm bảo tuân thủ theo các tiêu chuẩn bảo mật của Đối Tác Cổng Thanh Toán, các tổ chức đã được Ngân
                        hàng nhà nước Việt Nam thẩm định về độ an toàn bảo mật và cấp phép hoạt động chính thức.
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>Quy định cụ thể</p>

                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Chính sách bảo mật thanh toán đảm bảo tuân thủ các tiêu chuẩn bảo mật của các Đối Tác Cổng Thanh Toán bao gồm: <br />
                        • Tiêu chuẩn bảo mật dữ liệu trên internet SSL (Secure Sockets Layer)
                        <br />
                        • Chứng nhận tiêu chuẩn bảo mật dữ liệu thông tin thanh toán (PCI DSS) do Trustwave cung cấp.
                        <br />
                        • Tiêu chuẩn mã hóa MD5 128 bit.
                        <br />
                        • Mật khẩu sử dụng một lần (OTP) được gửi qua SMS để đảm bảo việc truy cập tài khoản được xác thực hoặc mã hóa 3D của thẻ Visa
                        và MasterCard (được xác nhận bởi Visa và MasterCard về tính bảo mật).
                        <br />
                        • Các nguyên tắc và quy định bảo mật thông tin trong ngành tài chính ngân hàng theo quy định của Ngân hàng nhà nước Việt Nam.
                        <br />
                        • Milu không trực tiếp lưu trữ thông tin thẻ khách hàng. Việc bảo mật thông tin thẻ thanh toán khách hàng được thực hiện bởi
                        Đối Tác Cổng Thanh Toán.
                        <br />
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl pb-10'>
                        Milu cam kết đảm bảo thực hiện nghiêm túc các biện pháp bảo mật cần thiết cho mọi hoạt động thanh toán thực hiện trên trang
                        thương mại điện tử https://milu.promete.ai/.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='border-t border-gray-200 bg-gray-900 pb-12'>
                <Footer />
                {/*<p className='text-center text-base font-light text-gray-400'>&copy; 2023 Promete, Inc. All rights reserved.</p>*/}
              </div>
            </div>
          </main>
        </div>
      </div>
    </Fragment>
  );
}
