import { createEntityAdapter, createSlice, PayloadAction, Update } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IMessage } from '@/types/IMessage';
import { IChatRoom } from '@/types/IChatRoom';
import { keys } from 'ts-transformer-keys';
import { messageContentHash } from '@/utils/message_content_hasher';
import { generate_uuid } from '@/utils/uuid_generator';

const entityAdapter = createEntityAdapter<IMessage>({
  selectId: (message) => message.id,
  sortComparer: (a, b) => {
    // Sort the message with empty createdAt to the bottom
    if (!a.createdAt && b.createdAt) {
      return 1;
    } else if (a.createdAt && !b.createdAt) {
      return -1;
    } else if (!a.createdAt && !b.createdAt) {
      return 0;
    }

    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  }
});

const initialState = entityAdapter.getInitialState({
  ...keys<IChatRoom>().reduce((acc, key) => {
    if (key === 'hidden') {
      acc[key] = false;
    } else {
      acc[key] = '';
    }

    return acc;
  }, {} as IChatRoom),
  isReady: false,
  streamMessageID: ''
});

const removeOldMessages = (state: typeof initialState) => {
  // Only limit 1000 messages per chat room
  if (state.ids.length > 1000) {
    console.log('Too many messages, removing old messages');
    const idsToRemove = state.ids.slice(0, state.ids.length - 1000);
    entityAdapter.removeMany(state, idsToRemove);
  }
};

const chatRoomSlice = createSlice({
  name: 'chat/chatRoom',
  initialState,
  reducers: {
    setChatRoom(state, action: PayloadAction<IChatRoom>) {
      // Only update the chat room if the chat room id is different
      if (state.id !== action.payload.id) {
        for (const key of keys<IChatRoom>()) {
          state[key] = action.payload[key] as never;
        }

        // Clear the messages
        entityAdapter.removeAll(state);

        // Set the chat room as ready not ready
        state.isReady = false;
      }
    },
    addMessage: (state, action: PayloadAction<IMessage>) => {
      entityAdapter.upsertOne(state, action.payload);

      // Make sure we don't have too many messages
      removeOldMessages(state);
    },
    addOrUpdateMessagesFromDatastore: (state, action: PayloadAction<IMessage[]>) => {
      // If it is in stream mode, do not add the messages, as it might flash the screen
      if (state.streamMessageID) {
        return;
      }

      const removeIDs = [] as string[];
      for (const message of action.payload) {
        // As we are updating the id, we need to remove the old one first, then add the new one
        for (const key of Object.keys(state.entities).reverse()) {
          const m = state.entities[key] as IMessage;
          if (m.contentHash === message.contentHash && m.id !== message.id) {
            console.log('Found the message to update: ', m.id, ' -> ', message.id);
            removeIDs.push(key);
            break;
          }
        }

        // Add the message
        entityAdapter.upsertOne(state, message);
      }

      // Remove the old messages
      entityAdapter.removeMany(state, removeIDs);

      // Make sure we don't have too many messages
      removeOldMessages(state);
    },
    addStreamMessage: (state, action: PayloadAction<IMessage>) => {
      if (!state.streamMessageID) {
        state.streamMessageID = 'streamMessage' + '-' + generate_uuid();
        action.payload.id = state.streamMessageID;
        entityAdapter.upsertOne(state, action.payload);
      } else {
        const streamMessage = {
          ...entityAdapter.getSelectors().selectById(state, state.streamMessageID)
        } as IMessage;
        if (streamMessage) {
          streamMessage.text += action.payload.text;
          // Make sure the createdAt is 'empty' so that the message will be sorted to the bottom
          streamMessage.createdAt = '';
          entityAdapter.upsertOne(state, streamMessage);
        }
      }
    },
    updateAndEndStreamMessage: (state, action: PayloadAction<IMessage>) => {
      if (state.streamMessageID) {
        // Calculate the content hash of the new message
        const newMessageContentHash = messageContentHash(action.payload);

        // Remove the stream message
        entityAdapter.removeOne(state, state.streamMessageID);

        // Set the stream message id to empty
        state.streamMessageID = '';

        // Add the new message
        entityAdapter.upsertOne(state, {
          ...action.payload,
          contentHash: newMessageContentHash
        });
      }
    },
    removeMessage: (state, action: PayloadAction<string>) => {
      entityAdapter.removeOne(state, action.payload);
    },
    updateMessage: (state, action: PayloadAction<Update<IMessage>>) => {
      entityAdapter.updateOne(state, action.payload);
    },
    resetChatRoom: () => {
      return initialState;
    },
    setChatRoomReady: (state, action: PayloadAction<boolean>) => {
      state.isReady = action.payload;
    },
    removeChatRoom: (state) => {
      return initialState;
    }
  }
});

export const selectAllMessages = (state: RootState) => {
  return entityAdapter.getSelectors().selectAll(state.chat.chatRoom);
};

export const selectActiveChatRoom = (state: RootState) => {
  return state.chat.chatRoom;
};

export default chatRoomSlice;
export const chatRoomActions = chatRoomSlice.actions;
