import DarkModeSwitcher from '../components/DarkModeSwitcher';
import dom from '@left4code/tw-starter/dist/js/dom';
import { ReactComponent as ErrorIllustration } from '@/assets/images/error-illustration.svg';
import { useEffect } from 'react';

const ErrorPage = () => {
  useEffect(() => {
    dom('body').removeClass('main').removeClass('login').addClass('error-page');
  }, []);

  return (
    <>
      <div>
        <DarkModeSwitcher />
        <div className='container'>
          {/* BEGIN: Error Page */}
          <div className='error-page flex h-screen flex-col items-center justify-center text-center lg:flex-row lg:text-left'>
            <div className='-intro-x lg:mr-20'>
              <ErrorIllustration className='h-48 lg:h-auto' />
            </div>
            <div className='mt-10 text-white lg:mt-0'>
              <div className='intro-x text-8xl font-medium'>404</div>
              <div className='intro-x mt-5 text-xl font-medium lg:text-3xl'>Oops. Trang này hiện không có.</div>
              <div className='intro-x mt-3 text-lg'>Bạn có thể đã điền sai địa chỉ hoặc trang này đã bị dời đi.</div>
              <button className='intro-x btn mt-10 border-white py-3 px-4 text-white dark:border-darkmode-400 dark:text-slate-200'>
                Quay lại trang chủ
              </button>
            </div>
          </div>
          {/* END: Error Page */}
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
