import React, { Fragment, useState } from 'react';
import logoImg from '@/assets/images/robot-milu2.png';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/stores/redux/hooks';
import { selectAllAgents } from '@/stores/redux/agents/agents-slice';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { selectActiveChatRoom } from '@/stores/redux/chat-room/chat-room-slice';
import MessageBoard from '@/components/Chat/MessageBoard';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { motion, useMotionValue, useTransform } from 'framer-motion';

const MobileMenu = () => {
  // 3d card
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [-100, 100], [-30, 30]);

  const navigate = useNavigate();
  const agents = useAppSelector((state) => selectAllAgents(state));
  const activeChatRoom = useAppSelector(selectActiveChatRoom);
  const [chatContentHeight] = useState('calc(100vh - 7rem)');

  return (
    <Fragment>
      {/* BEGIN: Mobile Menu */}
      <div className='pt-5'>
        <div
          className={classnames({
            'md:hidden ': true
          })}>
          <div className='flex flex-col'>
            <div className=' bg-primary-500 flex h-16 items-center justify-start'>
              <a
                className='mr-auto flex items-center space-x-2 overflow-visible'
                href='src/components/MobileMenu#'
                onClick={(event) => {
                  event.preventDefault();
                  navigate('/');
                }}>
                <ArrowLeftIcon className='h-8 w-8 text-primary dark:text-gray-300' />
                {/*<LogoImg className='h-8 w-8 text-primary dark:text-gray-300' />*/}
                <motion.div
                  style={{ x, y, rotateX, rotateY, z: 100 }}
                  drag
                  dragElastic={0.18}
                  dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
                  whileTap={{ cursor: 'grabbing' }}>
                  <img src={logoImg} className='h-20 overflow-visible text-primary dark:text-gray-300' alt='logo' />
                </motion.div>
                <div className='flex flex-col'>
                  <div className='text-base font-medium text-primary dark:text-gray-300 sm:text-lg'>
                    {agents.find((agent) => agent.id === activeChatRoom.agentID)?.name || 'Milu'}
                  </div>
                  <div className='text-xs text-primary dark:text-slate-500 sm:text-sm'>
                    {agents.find((agent) => agent.id === activeChatRoom.agentID)?.language === 'English'
                      ? 'I am a chatbot. I am glad to answer your questions.'
                      : 'Tôi là trợ lý AI. Hân hạnh trả lời mọi câu hỏi của bạn.'}
                  </div>
                </div>
              </a>
            </div>
            <div className='intro-y border-primary-500 col-span-12 border dark:border-gray-700 md:p-0 lg:col-span-9 2xl:col-span-10'>
              <div className='flex flex-col'>
                {/*Calculate the height of the parent element and set the height of the child element to the height of the parent element, using Tailwind CSS.*/}
                <div className='chat__box box overflow-y-scroll sm:block' style={{ height: chatContentHeight }}>
                  {/*/!* BEGIN: Chat Active *!/*/}
                  {/*If the activeChatRoom is loaded, show the message board.*/}
                  {activeChatRoom && activeChatRoom.agentID && activeChatRoom.chatRoomName ? (
                    // This is an existing chat room.
                    <MessageBoard />
                  ) : (
                    <div className='intro-y flex h-full items-center justify-center'>
                      <div className='text-center'>
                        <div className='text-3xl font-medium'>Welcome to Milu Chatbot</div>
                        <div className='mt-1 text-gray-600'>Click on the chatbot icon to start chatting</div>
                      </div>
                    </div>
                  )}
                  {/*/!* END: Chat Active *!/*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* END: Mobile Menu */}
      </div>
    </Fragment>
  );
};

export default MobileMenu;
