import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICartItem } from '@/types/helpers/ICartItem';
import { RootState } from '@/stores/redux/store';

const cartEntityAdapter = createEntityAdapter<ICartItem>({
  selectId: (cartItem) => cartItem.id
});

const initialState = cartEntityAdapter.getInitialState({
  totalQuantity: 0,
  totalPriceInVND: 0
});

type ICartState = typeof initialState;

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state: ICartState, action: PayloadAction<ICartItem>) => {
      const existingCartItem = state.entities[action.payload.id];
      if (existingCartItem) {
        existingCartItem.quantity++;
      } else {
        cartEntityAdapter.addOne(state, action.payload);
      }
      state.totalQuantity++;
      state.totalPriceInVND += action.payload.pricePerUnit;
    },
    removeOneItem: (state: ICartState, action: PayloadAction<string>) => {
      // Check the quantity of the item to be removed
      const existingCartItem = state.entities[action.payload];
      if (existingCartItem) {
        if (existingCartItem.quantity === 1) {
          cartEntityAdapter.removeOne(state, action.payload);
        } else {
          existingCartItem.quantity--;
        }
        state.totalQuantity--;
        state.totalPriceInVND -= existingCartItem.pricePerUnit;
      }
    },
    removeItem: (state: ICartState, action: PayloadAction<string>) => {
      const existingCartItem = state.entities[action.payload];
      if (existingCartItem) {
        state.totalQuantity -= existingCartItem.quantity;
        state.totalPriceInVND -= existingCartItem.quantity * existingCartItem.pricePerUnit;
        cartEntityAdapter.removeOne(state, action.payload);
      }
    },
    clearCart: () => {
      return initialState;
    }
  }
});

export const { selectAll: selectAllCartItems } = cartEntityAdapter.getSelectors((state: RootState) => state.cart);

export const { addItem, removeOneItem, removeItem, clearCart } = cartSlice.actions;

export const cartActions = cartSlice.actions;
export default cartSlice;
