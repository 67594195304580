import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProduct } from '@/types/helpers/IProduct';
import { RootState } from '@/stores/redux/store';

export const productAdapter = createEntityAdapter<IProduct>({
  selectId: (product) => product.id
});

// generate 3 products for initial state
const products: IProduct[] = [
  {
    id: '1',
    name: 'Intelium Sample',
    imageUrl: 'https://picsum.photos/200/300',
    pricePerUnit: 25000,
    numOfTokens: 10000,
    description: 'Nạp tiền vào tài khoản để trải nghiệm và ứng dụng trợ lý ảo Milu vào các công việc của bạn.',
    numOfUsers: 1
  },
  {
    id: '2',
    name: 'Intelium Ration',
    imageUrl: 'https://picsum.photos/200/300',
    pricePerUnit: 200000,
    numOfTokens: 100000,
    description: 'Nạp tiền vào tài khoản để trải nghiệm và ứng dụng trợ lý ảo Milu vào các công việc của bạn.',
    numOfUsers: 1
  },
  {
    id: '3',
    name: 'Intelium Container',
    imageUrl: 'https://picsum.photos/200/300',
    pricePerUnit: 1000000,
    numOfTokens: 500000,
    description: 'Nạp tiền vào tài khoản để trải nghiệm và ứng dụng trợ lý ảo Milu vào các công việc của bạn.',
    numOfUsers: 10
  }
];

// @ts-ignore
const initialState = productAdapter.getInitialState({
  ids: products.map((product) => product.id),
  entities: products.reduce((acc, product) => {
    acc = { ...acc, [product.id]: product };
    return acc;
  }, [] as IProduct[])
});

const ProductSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getProducts: (state, action: PayloadAction<IProduct[]>) => {
      productAdapter.setAll(state, action.payload);
    }
  }
});

export const { selectAll: selectAllProducts } = productAdapter.getSelectors((state: RootState) => state.products);

export default ProductSlice;
