/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTokenKey = /* GraphQL */ `
  query GetTokenKey($id: ID!) {
    getTokenKey(id: $id) {
      id
      tokenAmount
      isUsed
      giftCard {
        id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        giftCardTokenKeyId
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tokenKeyGiftCardId
    }
  }
`;
export const listTokenKeys = /* GraphQL */ `
  query ListTokenKeys(
    $filter: ModelTokenKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTokenKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tokenAmount
        isUsed
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tokenKeyGiftCardId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTokenKeys = /* GraphQL */ `
  query SyncTokenKeys(
    $filter: ModelTokenKeyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTokenKeys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tokenAmount
        isUsed
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tokenKeyGiftCardId
      }
      nextToken
      startedAt
    }
  }
`;
export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      chatRoomName
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      messages {
        nextToken
        startedAt
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageTimestamp
      lastMessageText
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listChatRooms = /* GraphQL */ `
  query ListChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChatRooms = /* GraphQL */ `
  query SyncChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatRooms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const chatRoomByName = /* GraphQL */ `
  query ChatRoomByName(
    $chatRoomName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomByName(
      chatRoomName: $chatRoomName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const chatRoomsByUser = /* GraphQL */ `
  query ChatRoomsByUser(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomsByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserToken = /* GraphQL */ `
  query GetUserToken($id: ID!) {
    getUserToken(id: $id) {
      id
      totalTokenAmount
      spentTokenAmount
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      userTokenUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listUserTokens = /* GraphQL */ `
  query ListUserTokens(
    $filter: ModelUserTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserTokens = /* GraphQL */ `
  query SyncUserTokens(
    $filter: ModelUserTokenFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserTokens(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userTokenByUserId = /* GraphQL */ `
  query UserTokenByUserId(
    $userTokenUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTokenByUserId(
      userTokenUserId: $userTokenUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getGiftCard = /* GraphQL */ `
  query GetGiftCard($id: ID!) {
    getGiftCard(id: $id) {
      id
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      tokenKey {
        id
        tokenAmount
        isUsed
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tokenKeyGiftCardId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      giftCardTokenKeyId
    }
  }
`;
export const listGiftCards = /* GraphQL */ `
  query ListGiftCards(
    $filter: ModelGiftCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGiftCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        giftCardTokenKeyId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGiftCards = /* GraphQL */ `
  query SyncGiftCards(
    $filter: ModelGiftCardFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGiftCards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        giftCardTokenKeyId
      }
      nextToken
      startedAt
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      amountInVND
      tokenAmount
      channel
      note
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amountInVND
        tokenAmount
        channel
        note
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPayments = /* GraphQL */ `
  query SyncPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        amountInVND
        tokenAmount
        channel
        note
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      text
      timestamp
      promptTokens
      completionTokens
      isDefaultMessage
      ownerUserID
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      chatRoomID
      chatRoom {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        timestamp
        promptTokens
        completionTokens
        isDefaultMessage
        ownerUserID
        userID
        chatRoomID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        text
        timestamp
        promptTokens
        completionTokens
        isDefaultMessage
        ownerUserID
        userID
        chatRoomID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const chatRoomMessages = /* GraphQL */ `
  query ChatRoomMessages(
    $chatRoomID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomMessages(
      chatRoomID: $chatRoomID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        timestamp
        promptTokens
        completionTokens
        isDefaultMessage
        ownerUserID
        userID
        chatRoomID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      firstName
      lastName
      phoneNumber
      messages {
        nextToken
        startedAt
      }
      chatRooms {
        nextToken
        startedAt
      }
      userToken {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      giftCards {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      tokenKeys {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserTokenId
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAgent = /* GraphQL */ `
  query GetAgent($id: ID!) {
    getAgent(id: $id) {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAgents = /* GraphQL */ `
  query ListAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAgents = /* GraphQL */ `
  query SyncAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAgents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const agentByName = /* GraphQL */ `
  query AgentByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    agentByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
