import dom from '@left4code/tw-starter/dist/js/dom';
// import { ReactComponent as LogoImg } from '@/assets/images/logo.svg';
import logoImg from '@/assets/images/logo-milu-large.png';
import { ReactComponent as IllustrationImg } from '@/assets/images/illustration.svg';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import Notification from '../base-components/Notification';
import Lucide from '../base-components/Lucide';
import { useAuth } from '@/context/AmplifyAuthProvider';
import LoadingIcon from '../base-components/LoadingIcon';

const CodeVerification = () => {
  const navigate = useNavigate();
  const [notification, setNotification] = useState<NotificationType | null>(null);
  const { isSignedIn, errorMessage, successMessage, setErrorMessage, setSuccessMessage, codeVerify } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const location = useLocation();

  // Success notification
  const codeVerificationNotification = useRef<ReactElement>();
  const successNotificationToggle = () => {
    setNotification({
      header: 'Code verified successfully',
      message: 'Navigating to dashboard...',
      type: 'success'
    });
  };

  // Get current user email and password from location
  useEffect(() => {
    if (!location || !location.state) {
      return;
    }

    // @ts-ignore
    setEmail(location.state.email);
    // @ts-ignore
    setPassword(location.state.password);
  }, [location]);

  useEffect(() => {
    if (notification) {
      if (codeVerificationNotification && codeVerificationNotification.current) {
        // Show notification
        // @ts-ignore
        codeVerificationNotification.current.showToast();
      }
    }
  }, [notification]);

  useEffect(() => {
    dom('body').removeClass('main').removeClass('error-page').addClass('login');
  }, []);

  // Navigate to dashboard if already signed in
  useEffect(() => {
    if (isSignedIn) {
      navigate('/', { replace: true });
    }
  }, [isSignedIn, navigate]);

  // Reset errorMessage and successMessage on unmount
  useEffect(() => {
    return () => {
      setErrorMessage('');
      setSuccessMessage('');
    };
  }, [setErrorMessage, setSuccessMessage]);

  const initialValues = {
    verificationCode: ''
  };

  const validationSchema = Yup.object().shape({
    verificationCode: Yup.string()
      .required('Verification code is required')
      .matches(/^\d{6}$/, 'Invalid verification code')
  });

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const onSubmit = async (values: any) => {
    const { verificationCode } = values;

    if (!verificationCode) {
      return;
    }

    try {
      await codeVerify(verificationCode, email);

      // Success notification
      successNotificationToggle();

      // Set redirect to true to redirect to dashboard in 2 seconds
      await sleep(2000);
      navigate('/', { replace: true });
    } catch (error) {
      console.log('Code verification error', error);
    }
  };

  return (
    <div>
      {/* BEGIN: Notification Content */}
      <Notification
        getRef={(el) => {
          if (el) {
            codeVerificationNotification.current = el;
          }
        }}
        options={{
          duration: 2000,
          callback: () => {
            setNotification(null);
          }
        }}
        className='flex'>
        <Lucide icon='CheckCircle' className={notification && notification.type === 'success' ? 'text-success' : 'text-danger'} />
        <div className='mx-4'>
          <div className='font-medium'>{notification && notification.header}</div>
          <div className='mt-1 text-slate-500'>{notification && notification.message}</div>
        </div>
      </Notification>
      {/* END: Notification Content */}
      <div className='container sm:px-10'>
        <div className='block grid-cols-2 gap-4 xl:grid'>
          {/* BEGIN: Login Info */}
          <div className='hidden min-h-screen flex-col xl:flex'>
            <a href='#' className='-intro-x flex items-center pt-5'>
              <img src={logoImg} alt='Milu chatbot' className='h-12 w-12' />
              <span className='logo__text ml-1 text-2xl font-extrabold text-white'>Milu</span>
              <span className='text-2xl font-light text-white'> AI</span>
            </a>
            <div className='my-auto'>
              <IllustrationImg className='-intro-x -mt-16 w-1/2' />
              <div className='-intro-x mt-10 text-4xl font-medium leading-tight text-white'>
                Một vài bước nữa để đăng nhập <br />
                vào tài khoản của bạn.
              </div>
              <div className='-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-slate-400'>Trò chuyện với trợ lý thông minh AI ngay.</div>
            </div>
          </div>
          {/* END: Login Info */}
          {/* BEGIN: Login Form */}
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ isSubmitting }) => (
              <Form className='my-10 flex h-screen py-5 xl:my-0 xl:h-auto xl:py-0'>
                {email && password ? (
                  <div className='m-auto w-full rounded-md bg-white py-8 px-5 shadow-md dark:bg-darkmode-600 sm:w-3/4 sm:px-8 lg:w-2/4 xl:ml-20 xl:w-auto xl:bg-transparent xl:p-0 xl:shadow-none'>
                    <h2 className='intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl'>Xác thực tài khoản</h2>
                    <div className='intro-x text-md mt-2 font-light text-gray-500 dark:text-gray-500'>
                      Kiểm tra hộp thư email của bạn để lấy mã xác thực.
                      <br /> Nhập mã xác thực vào ô trống để xác thực địa chỉ email.
                    </div>
                    {/*BEGIN: Success or error message. Success message in green, error message in red*/}
                    {successMessage && (
                      <div className='mt-4 flex items-center text-center text-green-500'>
                        <Lucide icon='CheckCircle' className='mr-2 text-green-500' />
                        <div className='text-lg'>{successMessage}</div>
                      </div>
                    )}
                    {errorMessage && (
                      <div className='mt-4 flex items-center text-center text-red-500'>
                        <Lucide icon='XCircle' className='mr-2 text-red-500' />
                        <div className='text-lg'>{errorMessage}</div>
                      </div>
                    )}
                    {/* END: Success or error message */}
                    <div className='intro-x mt-2 text-center text-slate-400 xl:hidden'>
                      A few more clicks to sign in to your account. Manage all your e-commerce accounts in one place
                    </div>
                    <div className='intro-x mt-3'>
                      <Field
                        type='text'
                        name='verificationCode'
                        className='intro-x login__input form-control block py-3 px-4'
                        placeholder='Nhập mã'
                      />
                      <ErrorMessage name='verificationCode'>
                        {(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}
                      </ErrorMessage>
                    </div>
                    <div className='intro-x mt-5 text-center xl:mt-8 xl:text-left'>
                      {!isSubmitting ? (
                        <button type='submit' className='btn btn-primary w-full py-3 px-4 align-top xl:mr-3 xl:w-32'>
                          Xác thực ngay
                        </button>
                      ) : (
                        <button type='submit' className='btn btn-outline-primary w-full py-3 px-4 align-top xl:mr-3 xl:w-32' disabled>
                          <LoadingIcon icon='spinning-circles' className='mr-1 h-5 w-5 -translate-x-4' />{' '}
                          <span className='-translate-x-3'>Xác thực ngay</span>
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className='flex h-full w-full items-center justify-center'>
                    <LoadingIcon icon='grid' className='h-12 w-12' />
                  </div>
                )}
              </Form>
            )}
          </Formik>
          {/* END: Login Form */}
        </div>
      </div>
    </div>
  );
};

export default CodeVerification;
