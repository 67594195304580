import * as React from 'react';
import { useState } from 'react';
import { PreviewComponent } from '@/base-components/preview-component';
import ClassicEditor from '@/base-components/ckeditor/ClassicEditor';
import {
  ArrowPathIcon,
  ArrowPathRoundedSquareIcon,
  ArrowUpOnSquareIcon,
  CheckBadgeIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  PencilIcon,
  PencilSquareIcon,
  XCircleIcon
} from '@heroicons/react/24/outline';

function ParaphrasePage() {
  const [originalText, setOriginalText] = useState('');
  const [paraphraseText, setParaphraseText] = useState('');

  const handleOriginalTextChange = (event: any, editor: any) => {
    const data = editor.getData();
    setOriginalText(data);
    console.log('originalText', { event, editor, data });
  };

  const handleParaphraseTextChange = (event: any, editor: any) => {
    if (event) {
      const data = editor.getData();
      setParaphraseText(data);
      console.log('edited paraphrased Text is', paraphraseText);
    } else {
      setParaphraseText(originalText);
      console.log('paraphrased Text is', paraphraseText);
    }
  };

  const handleUploadFileClick = () => {
    console.log('Upload file clicked');
  };

  const handleResetClick = () => {
    console.log('Reset the paraphrasing process');
  };

  return (
    <div className='dark:bg-dark-1 divide-y divide-gray-200 overflow-hidden rounded-lg'>
      <div className='pb-5 dark:bg-darkmode-800 sm:p-6'>
        {/*<div className='flex flex-col'>*/}
        {/*  <h2 className='mr-auto text-lg font-medium'>Paraphrase</h2>*/}
        {/*  <h3 className='text-md w-2/3 font-light'>Let Milu help you with your text and documents in just one click!</h3>*/}
        {/*</div>*/}
        <div className='flex flex-col lg:flex-row '>
          <div className='mt-5 grid grid-cols-12 gap-3'>
            {/* BEGIN: Original Editor */}
            <div className='col-span-6 rounded-md border border-gray-200'>
              <PreviewComponent className='box pt-5'>
                <div className='flex items-center px-5'>
                  <h2 className='mr-auto text-lg font-medium'>Văn bản gốc</h2>
                  <button
                    className='bg-primary-100 flex items-center justify-center rounded-md p-2 hover:bg-gray-100'
                    onClick={handleUploadFileClick}>
                    <p className='text-primary dark:text-gray-300'>Paraphrase</p>
                    <PencilSquareIcon className='h-6 w-6 text-primary dark:text-gray-300' />
                  </button>
                </div>
                <div className='p-5'>
                  <PreviewComponent>
                    <ClassicEditor value={originalText} onChange={handleOriginalTextChange} config={{ placeholder: 'Nhập văn bản gốc vào đây' }} />
                  </PreviewComponent>
                </div>
                <div className='flex flex-col pb-5'>
                  <h2 className='text-md mr-auto pl-5 font-light text-gray-600 dark:text-gray-300'>Phân tích nội dung</h2>
                  <div className='mt-2 ml-auto flex flex-col items-start pr-5 font-light text-gray-600 dark:text-gray-300'>
                    <div className='flex flex-row space-x-2'>
                      <CheckBadgeIcon className='h-4 w-4 text-primary text-green-500' />
                      <p>
                        Số từ: <a className='font-medium'>20</a>
                      </p>
                    </div>
                    <div className='flex flex-row space-x-2'>
                      <XCircleIcon className='h-4 w-4 text-primary text-red-500' />
                      <p>
                        Số câu: <a className='font-medium'>Chưa đủ</a>
                      </p>
                    </div>
                    <div className='flex flex-row space-x-2'>
                      <FaceSmileIcon className='h-4 w-4 text-primary text-yellow-500' />
                      <p>
                        Số đoạn văn: <a className='font-medium'>Vừa đủ</a>
                      </p>
                    </div>
                    <div className='flex flex-row space-x-2'>
                      <FaceFrownIcon className='h-4 w-4 text-primary text-blue-500' />
                      <p>
                        Từ nâng cao: <a className='font-medium'>Dưới trung bình</a>
                      </p>
                    </div>
                  </div>
                </div>
              </PreviewComponent>
            </div>
            {/* END: Original Editor */}

            {/* BEGIN: Paraphrase Editor */}
            <div className='col-span-6 rounded-md border border-gray-200'>
              <PreviewComponent className='box pt-5'>
                <div className='flex items-center px-5'>
                  <h2 className='mr-auto text-lg font-medium'>Văn bản đã paraphrase</h2>
                  <button className='flex items-center justify-center rounded-md p-2 hover:bg-gray-100' onClick={handleResetClick}>
                    <p className='text-primary dark:text-gray-300'>Re-Paraphrase</p>
                    <ArrowPathIcon className='h-6 w-6 text-primary dark:text-gray-300' />
                  </button>
                </div>
                <div className='p-5'>
                  <PreviewComponent>
                    <ClassicEditor
                      value={paraphraseText}
                      onChange={(event: any, editor: any) => handleParaphraseTextChange(event, editor)}
                      className='focus:bg-primary-100'
                      config={{ placeholder: 'Văn bản đã paraphrase sẽ được hiển thị ở đây' }}
                    />
                  </PreviewComponent>
                </div>
                <div className='flex flex-col pb-5'>
                  <h2 className='text-md mr-auto pl-5 font-light text-gray-600 dark:text-gray-300'>Phân tích nội dung</h2>
                  <div className='mt-2 ml-auto flex flex-col items-start pr-5 font-light text-gray-600 dark:text-gray-300'>
                    <div className='flex flex-row space-x-2'>
                      <CheckBadgeIcon className='h-4 w-4 text-primary text-green-500' />
                      <p>
                        Số từ: <a className='font-medium'>20</a>
                      </p>
                    </div>
                    <div className='flex flex-row space-x-2'>
                      <XCircleIcon className='h-4 w-4 text-primary text-red-500' />
                      <p>
                        Số câu: <a className='font-medium'>Chưa đủ</a>
                      </p>
                    </div>
                    <div className='flex flex-row space-x-2'>
                      <FaceSmileIcon className='h-4 w-4 text-primary text-yellow-500' />
                      <p>
                        Số đoạn văn: <a className='font-medium'>Vừa đủ</a>
                      </p>
                    </div>
                    <div className='flex flex-row space-x-2'>
                      <FaceFrownIcon className='h-4 w-4 text-primary text-blue-500' />
                      <p>
                        Từ nâng cao: <a className='font-medium'>Dưới trung bình</a>
                      </p>
                    </div>
                  </div>
                </div>
              </PreviewComponent>
            </div>
            {/* END: Paraphrase Editor */}
          </div>
        </div>
        <div className='mt-5 flex flex-row items-center'>
          <h2 className='text-md ml-auto mr-2 font-light'>Bạn có thể soạn thảo ngay hoặc tải lên file để viết lại nội dung</h2>
          <button className='btn btn-primary' onClick={() => setParaphraseText(originalText)}>
            Tải tệp lên
          </button>
        </div>
      </div>
    </div>
  );
}

export default ParaphrasePage;
