import React, { Fragment, useEffect, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import TopBar from '@/components/TopBar';
import { Link } from 'react-router-dom';
import inteliumImg from '@/assets/images/Intelium-coin-single-transparent.png';
import financeImg from '@/assets/images/Intelium-block-transparent.png';
import { CheckIcon } from '@heroicons/react/24/solid';
import { indexOf } from 'lodash';
import { CircleDot } from 'lucide-react';
import { useMotionValue, useTransform, motion } from 'framer-motion';
import MobileTopBar from '@/components/MobileTopBar';

const steps = [
  { name: 'Còn hiện tại để mua Intelium,\n', description: 'các bạn chuyển tiền vào số tài khoản sau đây:', href: '#', status: 'complete' },
  {
    name: 'NGÂN HÀNG:\n',
    description: 'MBBank - Ngân hàng quân đội',
    href: '#',
    status: 'current'
  },
  { name: 'SỐ TÀI KHOẢN:', description: '0200480999999', href: '#', status: 'upcoming' },
  { name: 'TÊN CHỦ TÀI KHOẢN:', description: 'NGUYỄN HỮU HUÂN', href: '#', status: 'upcoming' },
  {
    name: 'NỘI DUNG:',
    description: 'Milu - INT - [số Intelium muốn mua] - [họ và tên user hoặc email]',
    href: '#',
    status: 'upcoming'
  }
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function UserGuidePage() {
  // 3d card
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [-100, 100], [-30, 30]);
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ['nguyên tố quan trọng'];
  const period = 1000;

  useEffect(() => {
    const ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    const i = loopNum % toRotate.length;
    const fullText = toRotate[i];
    const updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    //add topbar here
    <Fragment>
      <div className='py-5 md:py-0'>
        <MobileTopBar />
        <TopBar className='hidden md:block' />
        <div className='relative overflow-hidden'>
          <main>
            <div className='banner bg-gray-900 pt-16 sm:pt-24 lg:pt-28'>
              <div className='mx-auto max-w-7xl lg:px-8'>
                <div className='lg:grid lg:grid-cols-2 lg:gap-0'>
                  <div className='mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left'>
                    <div className='lg:py-24'>
                      <Link
                        to='/'
                        className='inline-flex items-center rounded-full bg-black p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base'>
                        <span className='text-sm'>Trở thành chũ sở hữu ngay</span>
                        <ChevronRightIcon className='ml-2 h-5 w-5 text-gray-500' aria-hidden='true' />
                      </Link>
                      <h1 className='banner mt-4  tracking-tight text-white sm:mt-5  lg:mt-6 '>
                        <span className='block text-4xl font-extrabold sm:text-6xl xl:text-6xl'> Intelium là </span>
                        <span className='txt-rotate' data-period={period} data-rotate={['nguyên tố quan trọng']}>
                          <span className='wrap bg-gradient-to-r from-teal-200 to-emerald-400 bg-clip-text pb-3 text-4xl font-extrabold text-transparent sm:pb-5 sm:text-6xl xl:text-6xl'>
                            {text}
                          </span>
                        </span>
                        <p className='mt-3 text-base font-bold text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                          trong việc phát triển các ứng dụng AI hiệu quả và có thể tạo ra những cải tiến đáng kể trong công nghệ.
                        </p>
                      </h1>
                      <p className='mt-3 text-base font-light text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        Intelium là một nguyên tố chưa được khám phá, được sử dụng rộng rãi trong các ứng dụng AI, đặc biệt là Milu rất thích Intelium
                        . Nó cung cấp cho các chương trình AI
                      </p>
                      <span className='text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        khả năng học và nhận biết đối tượng một cách nhanh chóng và chính xác.
                      </span>
                      <div className='mt-5 sm:mt-6'>
                        <p className='mt-3 text-base font-light text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                          Nguyên tố này cũng giúp các ứng dụng AI thực hiện các tác vụ phức tạp hơn như xử lý ngôn ngữ tự nhiên, trí tuệ nhân tạo và
                          học sâu.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=' mt-12 lg:relative lg:m-0'>
                    <div className='banner mx-auto flex max-w-md justify-center px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0'>
                      <img
                        className='w-full sm:-ml-24 sm:pr-20 lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none'
                        src={financeImg}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Feature section with screenshot */}
            <div className='relative overflow-hidden bg-gray-900 pt-16'>
              <div aria-hidden='true' className='absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-900' />
              <div className='relative'>
                <div className='mx-auto max-w-xl px-4 sm:max-w-2xl sm:px-6 lg:mx-0 lg:max-w-none lg:px-0'>
                  <p className='glow-text text-center text-xl font-bold text-white'>
                    Trong thời gian tới, <span>Promete sẽ liên kết với các cổng thanh toán</span> như<span>VNPay, Payoo, Nganluong</span>.
                  </p>
                </div>
                <div className='pt-14 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8'>
                  <div className='mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0'>
                    <nav aria-label='Progress' className='pl-4 lg:pl-20'>
                      <ol role='list' className='overflow-hidden'>
                        {steps.map((step, stepIdx) => (
                          <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
                            <>
                              {stepIdx !== steps.length - 1 ? (
                                <div className='absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-emerald-300' aria-hidden='true' />
                              ) : null}
                              <a href={step.href} className='group relative flex items-start'>
                                <span className='flex h-9 items-center' aria-hidden='true'>
                                  <span className='relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-emerald-500 bg-emerald-500 group-hover:border-gray-400'>
                                    <CircleDot className='text-center text-2xl font-bold text-white' />
                                  </span>
                                </span>
                                <span className='ml-4 flex min-w-0 flex-col'>
                                  <span className='text-xl font-medium text-emerald-400'>{step.name}</span>
                                  <span className='text-lg text-white'>{step.description}</span>
                                  {step.name === 'NỘI DUNG:' ? (
                                    <span className='text-sm text-gray-400'>Ví dụ: Milu - Int - 100000 - An Nguyen nguyenvana@gmail.com</span>
                                  ) : null}
                                </span>
                              </a>
                            </>
                          </li>
                        ))}
                      </ol>
                    </nav>
                  </div>
                  <div className='banner sm:mt-16 lg:mt-12'>
                    <div className='lg:h-50 sm:pl-6 lg:relative lg:m-0 lg:mr-10 lg:-ml-20 lg:px-0'>
                      <img
                        className='h-50 lg:h-50 -mt-8 -ml-24 lg:absolute lg:left-0 lg:-ml-48 lg:-mt-20 lg:w-auto lg:max-w-none'
                        src={inteliumImg}
                        alt='Inbox user interface'
                      />
                      <img
                        className='h-50 lg:h-50 -mt-48 -ml-8 lg:absolute lg:left-0 lg:mt-0 lg:-ml-28 lg:w-auto lg:max-w-none'
                        src={inteliumImg}
                        alt='Inbox user interface'
                      />
                      <img
                        className='h-50 lg:h-50 -mt-80 ml-8 lg:absolute lg:left-0 lg:-ml-8 lg:mt-16 lg:w-auto lg:max-w-none'
                        src={inteliumImg}
                        alt='Inbox user interface'
                      />
                      <img
                        className='h-50 lg:h-50 ml-24 -mt-52 lg:absolute lg:left-0 lg:ml-12 lg:mt-32 lg:w-auto lg:max-w-none'
                        src={inteliumImg}
                        alt='Inbox user interface'
                      />
                    </div>
                  </div>
                </div>
                <div className='mx-auto flex max-w-4xl flex-col items-center justify-center px-4 pb-14 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:px-8 lg:pt-24'>
                  <h2 className='bg-gradient-to-r from-teal-200 to-emerald-500 bg-clip-text text-center text-4xl font-extrabold text-transparent'>
                    Thời gian để INT vào tài khoản có thể lên đến 24H
                  </h2>
                  <p className='mt-4 max-w-3xl text-center text-lg text-gray-500'>
                    <span>Còn chần chờ gì nữa? </span>
                    <span>Sử dụng Milu và tham gia cộng đồng sử dụng Milu, để hoàn thành mọi công việc của bạn ngay!</span>
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Fragment>
  );
}
