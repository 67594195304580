import { API, graphqlOperation } from 'aws-amplify';
import {
  GetUserTokenQuery,
  OnCreateUserTokenSubscription,
  OnDeleteUserTokenSubscription,
  OnUpdateUserTokenSubscription,
  UserTokenByUserIdQuery
} from '@/API';
import { GraphQLResult } from '@aws-amplify/api';
import { AppDispatch } from '@/stores/redux/store';
import { userTokenActions } from '@/stores/redux/user-token/user-token-slice';
import { getUserToken, userTokenByUserId } from '@/graphql/queries';
import { cloneSubscribeUserToken, cloneUserToken } from '@/types/IUserToken';
import { onCreateUserToken, onDeleteUserToken, onUpdateUserToken } from '@/graphql/subscriptions';

export const gqlGetUserTokenById = async (userTokenID: string) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get user token by ID ${retry} time(s)`);
    }

    try {
      const result = (await API.graphql(
        graphqlOperation(getUserToken, {
          id: userTokenID,
          limit: 1000
        })
      )) as GraphQLResult<GetUserTokenQuery>;
      return result.data?.getUserToken && !result.data.getUserToken._deleted ? result.data.getUserToken : null;
    } catch (error) {
      console.log(error);
      retry++;
    }

    if (retry >= 3) {
      throw new Error('Failed to get user token by ID');
    }
  }

  return null;
};

export const gqlGetUserTokenByUser = async (userID: string) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get user token by user ${retry} time(s)`);
    }

    try {
      const result = (await API.graphql(graphqlOperation(userTokenByUserId, { userTokenUserId: userID }))) as GraphQLResult<UserTokenByUserIdQuery>;

      const userTokens = result.data?.userTokenByUserId?.items;
      if (userTokens && userTokens.length > 0) {
        return userTokens[0] && !userTokens[0]._deleted ? userTokens[0] : null;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      retry++;
    }

    if (retry >= 3) {
      throw new Error('Failed to get user token by user');
    }
  }

  return null;
};

export class UserTokenSubscription {
  _onCreateUserTokenSubscription: { unsubscribe: () => void } | null = null;
  _onUpdateUserTokenSubscription: { unsubscribe: () => void } | null = null;
  _onDeleteUserTokenSubscription: { unsubscribe: () => void } | null = null;

  constructor(private _dispatch: AppDispatch, private _userID: string) {}

  // Subscribe to the onCreateUserToken subscription
  private _subscribeToOnCreateUserTokenSubscription() {
    // Subscribe to the onCreateUserToken subscription
    this._onCreateUserTokenSubscription = (
      API.graphql(
        graphqlOperation(onCreateUserToken, {
          owner: this._userID
        })
      ) as any
    ).subscribe({
      next: ({ value }: { value: GraphQLResult<OnCreateUserTokenSubscription> }) => {
        if (value.data?.onCreateUserToken) {
          const userToken = cloneSubscribeUserToken(value.data.onCreateUserToken);
          console.log('onCreateUserToken', userToken);
          this._dispatch(userTokenActions.setUserToken(userToken));
        }
      },
      error: (error: any) => {
        console.log('onCreateUserToken', error);
      }
    });
  }

  // Subscribe to the onUpdateUserToken subscription
  private _subscribeToOnUpdateUserTokenSubscription() {
    // Subscribe to the onUpdateUserToken subscription
    this._onUpdateUserTokenSubscription = (
      API.graphql(
        graphqlOperation(onUpdateUserToken, {
          owner: this._userID
        })
      ) as any
    ).subscribe({
      next: ({ value }: { value: GraphQLResult<OnUpdateUserTokenSubscription> }) => {
        if (value.data?.onUpdateUserToken) {
          const userToken = cloneSubscribeUserToken(value.data.onUpdateUserToken);
          console.log('onUpdateUserToken', userToken);
          this._dispatch(userTokenActions.setUserToken(userToken));
        }
      },
      error: (error: any) => {
        console.log('onUpdateUserToken', error);
      }
    });
  }

  // Subscribe to the onDeleteUserToken subscription
  private _subscribeToOnDeleteUserTokenSubscription() {
    // Subscribe to the onDeleteUserToken subscription
    this._onDeleteUserTokenSubscription = (
      API.graphql(
        graphqlOperation(onDeleteUserToken, {
          owner: this._userID
        })
      ) as any
    ).subscribe({
      next: ({ value }: { value: GraphQLResult<OnDeleteUserTokenSubscription> }) => {
        if (value.data?.onDeleteUserToken) {
          const userToken = cloneSubscribeUserToken(value.data.onDeleteUserToken);
          console.log('onDeleteUserToken', userToken);
          this._dispatch(userTokenActions.setUserToken(userToken));
        }
      },
      error: (error: any) => {
        console.log('onDeleteUserToken', error);
      }
    });
  }

  // Subscribe to all subscriptions
  async subscribe() {
    this._subscribeToOnCreateUserTokenSubscription();
    this._subscribeToOnUpdateUserTokenSubscription();
    this._subscribeToOnDeleteUserTokenSubscription();

    // Also get the user token
    const userToken = await gqlGetUserTokenByUser(this._userID);
    if (userToken) {
      this._dispatch(userTokenActions.setUserToken(cloneUserToken(userToken)));
    }

    // TODO (MinhLuan): we might need to check again if the data is not updated
    // Maybe there is some delay between the subscription and the query
  }
}
