import Chart from '../base-components/Chart';
import { colors } from '../utils/colors';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { colorScheme as colorSchemeStore } from '../stores/recoil/color-scheme';
import { darkMode as darkModeStore } from '../stores/recoil/dark-mode';
import React, { useMemo } from 'react';

type IProps = {
  data: {
    date: string;
    value: number;
  }[];
  height?: number;
  width?: number;
};

function VerticalBarChart({ data, height, width }: IProps) {
  const darkMode = useRecoilValue(darkModeStore);
  const colorScheme = useRecoilValue(colorSchemeStore);

  const chartData = useMemo(() => {
    return {
      labels: data.map((d) => d.date),
      datasets: [
        {
          label: 'Số Intelium đã dùng trong ngày',
          barPercentage: 0.5,
          barThickness: 16,
          maxBarThickness: 8,
          minBarLength: 2,
          data: data.map((d) => d.value),
          backgroundColor: colorScheme ? colors.primary() : ''
        }
      ]
    };
  }, [colorScheme, darkMode]);

  const options = useMemo(() => {
    return {
      maintainAspectRatio: true,
      plugins: {
        legend: {
          labels: {
            color: colors.slate['500'](0.8)
          }
        }
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: 12
            },
            color: colors.slate['500'](0.8)
          },
          grid: {
            display: false,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            font: {
              size: 20
            },
            color: colors.slate['500'](0.8),
            callback: function (value: string) {
              return value;
            }
          },
          grid: {
            color: darkMode ? colors.slate['500'](0.3) : colors.slate['300'](),
            borderDash: [2, 2],
            drawBorder: false
          }
        }
      }
    };
  }, [darkMode]);

  return (
    <div className='intro-y box col-span-12'>
      <div className='flex items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400 sm:py-3'>
        <h2 className='mr-auto text-base font-medium'>Tổng số Intelium đã dùng theo ngày</h2>
      </div>
      <div className='grid grid-cols-1 gap-6 p-5'>
        <div className='grid h-96 grid-cols-1 justify-center gap-6 px-20'>
          <Chart type='bar' data={chartData} options={options} height={height} width={width} />
        </div>
      </div>
    </div>
  );
}

VerticalBarChart.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string
};

VerticalBarChart.defaultProps = {
  width: 'auto',
  height: 'auto',
  className: ''
};

export default VerticalBarChart;
