/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateTokenKey = /* GraphQL */ `
  subscription OnCreateTokenKey($filter: ModelSubscriptionTokenKeyFilterInput) {
    onCreateTokenKey(filter: $filter) {
      id
      tokenAmount
      isUsed
      giftCard {
        id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        giftCardTokenKeyId
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tokenKeyGiftCardId
    }
  }
`;
export const onUpdateTokenKey = /* GraphQL */ `
  subscription OnUpdateTokenKey($filter: ModelSubscriptionTokenKeyFilterInput) {
    onUpdateTokenKey(filter: $filter) {
      id
      tokenAmount
      isUsed
      giftCard {
        id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        giftCardTokenKeyId
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tokenKeyGiftCardId
    }
  }
`;
export const onDeleteTokenKey = /* GraphQL */ `
  subscription OnDeleteTokenKey($filter: ModelSubscriptionTokenKeyFilterInput) {
    onDeleteTokenKey(filter: $filter) {
      id
      tokenAmount
      isUsed
      giftCard {
        id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        giftCardTokenKeyId
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tokenKeyGiftCardId
    }
  }
`;
export const onCreateChatRoom = /* GraphQL */ `
  subscription OnCreateChatRoom(
    $filter: ModelSubscriptionChatRoomFilterInput
    $userID: String
  ) {
    onCreateChatRoom(filter: $filter, userID: $userID) {
      id
      chatRoomName
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      messages {
        nextToken
        startedAt
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageTimestamp
      lastMessageText
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateChatRoom = /* GraphQL */ `
  subscription OnUpdateChatRoom(
    $filter: ModelSubscriptionChatRoomFilterInput
    $userID: String
  ) {
    onUpdateChatRoom(filter: $filter, userID: $userID) {
      id
      chatRoomName
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      messages {
        nextToken
        startedAt
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageTimestamp
      lastMessageText
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteChatRoom = /* GraphQL */ `
  subscription OnDeleteChatRoom(
    $filter: ModelSubscriptionChatRoomFilterInput
    $userID: String
  ) {
    onDeleteChatRoom(filter: $filter, userID: $userID) {
      id
      chatRoomName
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      messages {
        nextToken
        startedAt
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageTimestamp
      lastMessageText
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUserToken = /* GraphQL */ `
  subscription OnCreateUserToken(
    $filter: ModelSubscriptionUserTokenFilterInput
    $owner: String
  ) {
    onCreateUserToken(filter: $filter, owner: $owner) {
      id
      totalTokenAmount
      spentTokenAmount
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      userTokenUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onUpdateUserToken = /* GraphQL */ `
  subscription OnUpdateUserToken(
    $filter: ModelSubscriptionUserTokenFilterInput
    $owner: String
  ) {
    onUpdateUserToken(filter: $filter, owner: $owner) {
      id
      totalTokenAmount
      spentTokenAmount
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      userTokenUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onDeleteUserToken = /* GraphQL */ `
  subscription OnDeleteUserToken(
    $filter: ModelSubscriptionUserTokenFilterInput
    $owner: String
  ) {
    onDeleteUserToken(filter: $filter, owner: $owner) {
      id
      totalTokenAmount
      spentTokenAmount
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      userTokenUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onCreateGiftCard = /* GraphQL */ `
  subscription OnCreateGiftCard($filter: ModelSubscriptionGiftCardFilterInput) {
    onCreateGiftCard(filter: $filter) {
      id
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      tokenKey {
        id
        tokenAmount
        isUsed
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tokenKeyGiftCardId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      giftCardTokenKeyId
    }
  }
`;
export const onUpdateGiftCard = /* GraphQL */ `
  subscription OnUpdateGiftCard($filter: ModelSubscriptionGiftCardFilterInput) {
    onUpdateGiftCard(filter: $filter) {
      id
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      tokenKey {
        id
        tokenAmount
        isUsed
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tokenKeyGiftCardId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      giftCardTokenKeyId
    }
  }
`;
export const onDeleteGiftCard = /* GraphQL */ `
  subscription OnDeleteGiftCard($filter: ModelSubscriptionGiftCardFilterInput) {
    onDeleteGiftCard(filter: $filter) {
      id
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      tokenKey {
        id
        tokenAmount
        isUsed
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tokenKeyGiftCardId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      giftCardTokenKeyId
    }
  }
`;
export const onCreatePayment = /* GraphQL */ `
  subscription OnCreatePayment(
    $filter: ModelSubscriptionPaymentFilterInput
    $userID: String
  ) {
    onCreatePayment(filter: $filter, userID: $userID) {
      id
      amountInVND
      tokenAmount
      channel
      note
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePayment = /* GraphQL */ `
  subscription OnUpdatePayment(
    $filter: ModelSubscriptionPaymentFilterInput
    $userID: String
  ) {
    onUpdatePayment(filter: $filter, userID: $userID) {
      id
      amountInVND
      tokenAmount
      channel
      note
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePayment = /* GraphQL */ `
  subscription OnDeletePayment(
    $filter: ModelSubscriptionPaymentFilterInput
    $userID: String
  ) {
    onDeletePayment(filter: $filter, userID: $userID) {
      id
      amountInVND
      tokenAmount
      channel
      note
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage(
    $filter: ModelSubscriptionMessageFilterInput
    $ownerUserID: String
  ) {
    onCreateMessage(filter: $filter, ownerUserID: $ownerUserID) {
      id
      text
      timestamp
      promptTokens
      completionTokens
      isDefaultMessage
      ownerUserID
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      chatRoomID
      chatRoom {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage(
    $filter: ModelSubscriptionMessageFilterInput
    $ownerUserID: String
  ) {
    onUpdateMessage(filter: $filter, ownerUserID: $ownerUserID) {
      id
      text
      timestamp
      promptTokens
      completionTokens
      isDefaultMessage
      ownerUserID
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      chatRoomID
      chatRoom {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage(
    $filter: ModelSubscriptionMessageFilterInput
    $ownerUserID: String
  ) {
    onDeleteMessage(filter: $filter, ownerUserID: $ownerUserID) {
      id
      text
      timestamp
      promptTokens
      completionTokens
      isDefaultMessage
      ownerUserID
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      chatRoomID
      chatRoom {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onCreateUser(filter: $filter, owner: $owner) {
      id
      email
      firstName
      lastName
      phoneNumber
      messages {
        nextToken
        startedAt
      }
      chatRooms {
        nextToken
        startedAt
      }
      userToken {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      giftCards {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      tokenKeys {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserTokenId
      owner
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onUpdateUser(filter: $filter, owner: $owner) {
      id
      email
      firstName
      lastName
      phoneNumber
      messages {
        nextToken
        startedAt
      }
      chatRooms {
        nextToken
        startedAt
      }
      userToken {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      giftCards {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      tokenKeys {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserTokenId
      owner
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onDeleteUser(filter: $filter, owner: $owner) {
      id
      email
      firstName
      lastName
      phoneNumber
      messages {
        nextToken
        startedAt
      }
      chatRooms {
        nextToken
        startedAt
      }
      userToken {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      giftCards {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      tokenKeys {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserTokenId
      owner
    }
  }
`;
export const onCreateAgent = /* GraphQL */ `
  subscription OnCreateAgent($filter: ModelSubscriptionAgentFilterInput) {
    onCreateAgent(filter: $filter) {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAgent = /* GraphQL */ `
  subscription OnUpdateAgent($filter: ModelSubscriptionAgentFilterInput) {
    onUpdateAgent(filter: $filter) {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAgent = /* GraphQL */ `
  subscription OnDeleteAgent($filter: ModelSubscriptionAgentFilterInput) {
    onDeleteAgent(filter: $filter) {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
