import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useAuth } from '@/context/AmplifyAuthProvider';
import LoadingIcon from '@/base-components/LoadingIcon';
import { useAppDispatch, useAppSelector } from '@/stores/redux/hooks';
import { updateUserTotalTokenAmount } from '@/stores/redux/users/users-slice';
import { userTokenActions } from '@/stores/redux/user-token/user-token-slice';

interface IAddTokenFormProps {
  userID: string;
  setHeaderFooterModalPreview: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddTokenForm = ({ userID, setHeaderFooterModalPreview }: IAddTokenFormProps) => {
  const { user } = useAuth();
  const userToken = useAppSelector((state) => state.userToken);
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: any) => {
    setHeaderFooterModalPreview(false);

    if (!user) {
      console.warn('User is not logged in, this should not happen');
      return;
    }

    const { tokenAmount } = values;

    console.log('Adding ' + tokenAmount + ' tokens to user ' + userID);

    try {
      const response = await axios.post(
        `/user_token/add_token`,
        {
          userID,
          tokenAmount
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.idToken}`
          }
        }
      );

      if (response.status === 201) {
        console.log('Token added successfully');

        // Also update the user's token balance in the redux store
        // The reason we have to update this manually is that the user's token is updated using DynamoDB's update expression in the backend
        if (response.data.event === 'tokenAdded' && response.data.data && response.data.data.tokenAmount) {
          await dispatch(updateUserTotalTokenAmount({ userID, totalTokenAmount: response.data.data.totalTokenAmount }));
        }
      } else {
        console.error('Error adding token: ' + response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues = {
    tokenAmount: ''
  };

  const validationSchema = Yup.object({
    // tokenAmount is an integer and is required
    tokenAmount: Yup.number()
      .typeError('Please input a valid integer')
      .integer('Please input a valid integer')
      .min(1000, 'Should be at least 1,000 inteliums')
      .max(1000000, 'Should be at most 1,000,000 inteliums')
      .required('Required')
  });

  if (!user) {
    return (
      <div className='flex h-full w-full items-center justify-center'>
        <LoadingIcon icon='grid' className='h-12 w-12' />
      </div>
    );
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ setFieldValue }) => (
        <Form className='grid grid-cols-12 gap-4 gap-y-3'>
          <div className='col-span-12 mb-4 gap-4 gap-y-3'>
            <label htmlFor='modal-form-1' className='form-label'>
              Token amount
            </label>
            <Field id='tokenAmount' name='tokenAmount' type='text' className='form-control' placeholder='Input token' />
            <ErrorMessage name='tokenAmount'>{(msg: string) => <span className='block py-1 px-4 text-red-600'>{msg}</span>}</ErrorMessage>
          </div>
          <div className='col-span-12 flex justify-between gap-4 gap-y-3'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={() => {
                setFieldValue('tokenAmount', 0);
              }}>
              Clear
            </button>
            <button type='submit' className='btn btn-primary'>
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddTokenForm;
