import React from 'react';
// logo
import LogoImg from '@/assets/images/illustration.svg';
// nike img
import NikeImg from '@/assets/images/Intelium-coin-single-transparent.png';

// import framer hooks
import { useMotionValue, useTransform, motion } from 'framer-motion';
import coinImg from '@/assets/images/Intelium-coin-single-transparent.png';
import blockImg from '@/assets/images/Intelium-block-transparent.png';
import coinsImg from '@/assets/images/Intelium-coins-transparent.png';

type AnimatedCardProps = {
  name: string;
  price: number;
  tokens: number;
  description: string;
};
const AnimatedCard = ({ name, price, tokens, description }: AnimatedCardProps) => {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [-100, 100], [-30, 30]);

  return (
    // card wrapper
    <div style={{ perspective: 2000 }}>
      {/* card */}
      <motion.div
        style={{ x, y, rotateX, rotateY, z: 100 }}
        drag
        dragElastic={0.18}
        dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
        whileTap={{ cursor: 'grabbing' }}
        className='group relative flex h-96 flex-col rounded-xl bg-white p-5 shadow-md hover:shadow-2xl dark:bg-slate-800 dark:shadow-emerald-500 lg:mr-5 lg:p-10'>
        {/* card logo */}
        <div className='mb-6'>
          <h1 className='mb-6 text-3xl font-extrabold lg:text-4xl'>{name}</h1>
          {/* card image */}
          <motion.div style={{ x, y, rotateX, rotateY, z: 100000 }} className='absolute -right-8 -mt-12 h-56 w-56'>
            {name === 'Intelium Sample' ? (
              <img src={coinImg} alt='coin' className='object-fill' />
            ) : name === 'Intelium Container' ? (
              <img src={blockImg} alt='block' className=' object-fill' />
            ) : (
              <img src={coinsImg} alt='Intelium' className=' object-fill' />
            )}
          </motion.div>
        </div>
        {/* card title */}
        <h1 className='mb-6 text-xl font-extrabold lg:pt-6'>{tokens} INT</h1>
        {/* card subtitle */}
        <p className='mb-6 max-w-[300px] text-[#000000] dark:text-gray-300'>{description}</p>
        {/* btn & price wrapper */}
        <div className='flex items-center gap-x-[20px]'>
          <a className='text-md group relative inline-block sm:text-lg'>
            <span className='relative z-10 block overflow-hidden rounded-lg border-2 border-gray-900 px-5 py-3 font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out group-hover:text-white'>
              <span className='absolute inset-0 h-full w-full rounded-lg bg-gray-50 px-5 py-3'></span>
              <span className='ease absolute left-0 -ml-2 h-48 w-48 origin-top-right -translate-x-full translate-y-12 -rotate-90 bg-gray-900 transition-all duration-300 group-hover:-rotate-180'></span>
              <span className='relative'>Thêm vào giỏ </span>
            </span>
            <span
              className='absolute bottom-0 right-0 -mb-1 -mr-1 h-12 w-full rounded-lg bg-gray-900 transition-all duration-200 ease-linear group-hover:mb-0 group-hover:mr-0'
              data-rounded='rounded-lg'></span>
          </a>
          <div className='text-[24px] font-bold text-[#000000] dark:text-gray-300'>
            {' '}
            {
              //if price has lower than 6 zeros digits then show price money with 1 3 zeros digits else show price money with 2 3 zeros digits
              price < 1000000
                ? price.toLocaleString('en-US', { maximumFractionDigits: 3 })
                : price.toLocaleString('en-US', { maximumFractionDigits: 6 })
            }
            đ
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AnimatedCard;
