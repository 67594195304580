import { DropDown, DropdownContent, DropdownItem, DropdownMenu, DropdownToggle } from '../../base-components/DropDown';
import Lucide from '../../base-components/Lucide';
import React, { Fragment, useRef } from 'react';
import { friendlyTimestamp } from '../../utils/friendly_timestamp';
import { IMessage } from '@/types/IMessage';

interface IChatbotMessageProps {
  message: IMessage;
  photo: string;
}

const ChatbotMessage = ({ message, photo }: IChatbotMessageProps) => {
  return (
    <Fragment>
      <div className='chat__box__text-box float-left mb-4 flex items-end'>
        <div className='image-fit relative mr-5 hidden h-10 w-10 flex-none sm:block'>
          <img alt='Milu chatbot' className='rounded-full' src={photo} />
        </div>
        {message ? (
          <div className='rounded-r-md rounded-t-md bg-slate-100 px-4 py-3 text-slate-500 dark:bg-darkmode-400'>
            {
              // split the message into an array of paragraphs and map over them
              message.text.split('\n').map((paragraph, index) => (
                <p key={index} className='mb-2'>
                  {paragraph}
                </p>
              ))
            }
            <div className='mt-1 text-xs text-slate-500'>{friendlyTimestamp(message.timestamp)}</div>
          </div>
        ) : (
          <div className='relative h-36 w-full space-y-3 overflow-hidden rounded-md bg-neutral-200 p-3 shadow before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_1.5s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white/20 hover:shadow-lg dark:bg-darkmode-400'>
            <div className='space-y-3'>
              <div className='space-y-1'>
                <div className='h-4 w-full rounded-full bg-neutral-100 shadow dark:bg-darkmode-500'></div>
                <div className='h-4 w-full rounded-full bg-neutral-100 shadow dark:bg-darkmode-500'></div>
                <div className='h-4 w-full rounded-full bg-neutral-100 shadow dark:bg-darkmode-500'></div>
                <div className='h-4 w-7/12 rounded-full bg-neutral-100 shadow dark:bg-darkmode-500'></div>
              </div>
              <div className='flex gap-2'>
                <div className='h-5 w-12 rounded-full bg-neutral-100 dark:bg-darkmode-500'></div>
              </div>
            </div>
          </div>
        )}
        <DropDown className='my-auto ml-3 hidden sm:block'>
          <DropdownToggle tag='a' href='#' className='h-4 w-4 text-slate-500'>
            <Lucide icon='MoreVertical' className='h-4 w-4' />
          </DropdownToggle>
          <DropdownMenu className='w-40'>
            <DropdownContent>
              <DropdownItem>
                <Lucide icon='CornerUpLeft' className='mr-2 h-4 w-4' />
                Reply
              </DropdownItem>
              <DropdownItem>
                <Lucide icon='Trash' className='mr-2 h-4 w-4' /> Delete
              </DropdownItem>
            </DropdownContent>
          </DropdownMenu>
        </DropDown>
      </div>
      <div className='clear-both'></div>
    </Fragment>
  );
};

export default ChatbotMessage;
