import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import pdfFile from '@/assets/Milu-ebook.pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import HTMLFlipBook from 'react-pageflip';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useMotionValue, useTransform, motion } from 'framer-motion';

const FlipBook = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdf, setPdf] = useState(pdfFile);
  const [isHovering, setIsHovering] = useState(false);

  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [-100, 100], [-30, 30]);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  //create a function for displaying the pdf by document
  const displayPDFPage = (i) => {
    return (
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={i} />
      </Document>
    );
  };

  return (
    <div className='hover:shadow-2xl hover:shadow-emerald-400'>
      <div className='flex flex-row justify-center' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        {displayPDFPage(pageNumber)}
        <div className='absolute -mb-44 flex items-center justify-center space-x-2 text-white'>
          {isHovering && (
            <motion.div style={{ x, y, rotateX, rotateY, z: 100000 }}>
              <a className='group relative inline-flex items-center justify-center overflow-hidden rounded-md px-6 py-3 font-bold text-white shadow-2xl'>
                <span className='absolute inset-0 h-full w-full bg-gradient-to-br from-emerald-700 via-emerald-500 to-blue-400 opacity-0 transition duration-300 ease-out group-hover:opacity-100'></span>
                <span className='absolute top-0 left-0 h-1/3 w-full bg-gradient-to-b from-white to-transparent opacity-5'></span>
                <span className='absolute bottom-0 left-0 h-1/3 w-full bg-gradient-to-t from-white to-transparent opacity-5'></span>
                <span className='absolute bottom-0 left-0 h-full w-4 bg-gradient-to-r from-white to-transparent opacity-5'></span>
                <span className='absolute bottom-0 right-0 h-full w-4 bg-gradient-to-l from-white to-transparent opacity-5'></span>
                <span className='absolute inset-0 h-full w-full rounded-md border border-white opacity-10'></span>
                <span className='absolute h-0 w-0 rounded-full bg-white opacity-5 transition-all duration-300 ease-out group-hover:h-56 group-hover:w-56'></span>
                <span className='relative flex items-center justify-center space-x-2'>
                  <ChevronLeft onClick={goToPrevPage} className='rounded-full border border-white' />
                  <p>
                    {pageNumber}/{numPages}
                  </p>
                  <ChevronRight onClick={goToNextPage} className='rounded-full border border-white' />
                </span>
              </a>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FlipBook;
