import { OnCreateUserTokenSubscription, UserToken } from '../API';
import { cloneObject } from '../utils/object_clone';
import { keys } from 'ts-transformer-keys';

export type IUserToken = Omit<UserToken, 'updatedAt' | 'createdAt' | 'owner' | '_version' | '_deleted' | '_lastChangedAt' | '__typename' | 'user'>;

export const cloneUserToken = (userToken: UserToken): IUserToken => {
  return cloneObject<UserToken, IUserToken>(userToken, keys<UserToken>(), keys<IUserToken>());
};

type SubscribeUserToken = NonNullable<OnCreateUserTokenSubscription['onCreateUserToken']>;
export const cloneSubscribeUserToken = (userToken: SubscribeUserToken): IUserToken => {
  return cloneObject<SubscribeUserToken, IUserToken>(userToken, keys<SubscribeUserToken>(), keys<IUserToken>());
};
