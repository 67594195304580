import dom from '@left4code/tw-starter/dist/js/dom';
import { ReactComponent as LogoImg } from '@/assets/images/logo.svg';
import { ReactComponent as IllustrationImg } from '@/assets/images/illustration.svg';
import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import Lucide from '../base-components/Lucide';
import { useAuth } from '@/context/AmplifyAuthProvider';
import LoadingIcon from '../base-components/LoadingIcon';
import { configureLocalStorage, configureSessionStorage } from '@/configureAmplify';
import logoImg from '@/assets/images/logo-milu-large.png';

const SignInPage = () => {
  const navigate = useNavigate();
  const { isSignedIn, signIn, successMessage, errorMessage, setSuccessMessage, setErrorMessage } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Reset errorMessage and successMessage on unmount
  useEffect(() => {
    return () => {
      setErrorMessage('');
      setSuccessMessage('');
    };
  }, [setErrorMessage, setSuccessMessage]);

  // Navigate to dashboard if already signed in
  useEffect(() => {
    if (isSignedIn) {
      navigate('/', { replace: true });
    }
  }, [isSignedIn, navigate]);

  // Navigate to code verification page if needed
  useEffect(() => {
    if (errorMessage === 'Bạn cần xác thực email trước khi đăng nhập' && email && password) {
      navigate('/auth/code-verification', {
        state: {
          email: email,
          password: password
        }
      });
    }
  }, [email, errorMessage, navigate, password]);

  useEffect(() => {
    dom('body').removeClass('main').removeClass('error-page').addClass('login');
  }, []);

  const initialValues = {
    email: '',
    password: '',
    remember: true
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email không hợp lệ').required('Email không được để trống'),
    password: Yup.string().required('Mật khẩu không được để trống').min(8, 'Mật khẩu phải có ít nhất 8 ký tự')
  });

  const handleSubmit = async (values: any) => {
    const { email, password, remember } = values;

    if (!email || !password) {
      return;
    }

    // Configure amplify storage based on remember me checkbox
    if (remember) {
      console.log('Configuring for remembering user');
      configureLocalStorage();
    } else {
      console.log('Configuring for not remembering');
      configureSessionStorage();
    }

    setEmail(email);
    setPassword(password);

    const user = await signIn(email, password, remember);
    if (user) {
      navigate('/');
    }
  };

  return (
    <div>
      <div className='container sm:px-10'>
        <div className='block grid-cols-2 gap-4 xl:grid'>
          {/* BEGIN: Login Info */}
          <div className='hidden min-h-screen flex-col xl:flex'>
            <a href='/auth/signin' className='-intro-x flex items-center pt-5'>
              <img src={logoImg} alt='Milu chatbot' className='h-12 w-12' />
              <span className='logo__text ml-1 text-2xl font-extrabold text-white'>Milu</span>
              <span className='text-2xl font-light text-white'> AI</span>
            </a>
            <div className='my-auto'>
              <IllustrationImg className='-intro-x -mt-16 w-1/2' />
              <div className='-intro-x mt-10 text-4xl font-medium leading-tight text-white'>
                Một vài bước nữa để đăng nhập <br /> vào tài khoản của bạn.
              </div>
              <div className='-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-slate-400'>Trò chuyện với trợ lý thông minh AI ngay.</div>
            </div>
          </div>
          {/* END: Login Info */}
          {/* BEGIN: Login Form */}
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting }) => (
              <Form className='my-10 flex h-screen py-5 xl:my-0 xl:h-auto xl:py-0'>
                <div className='m-auto w-full rounded-md bg-white py-8 px-5 shadow-md dark:bg-darkmode-600 sm:w-3/4 sm:px-8 lg:w-2/4 xl:ml-20 xl:w-auto xl:bg-transparent xl:p-0 xl:shadow-none'>
                  <h2 className='intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl'>Đăng nhập</h2>
                  {/*BEGIN: Success or error message. Success message in green, error message in red*/}
                  {successMessage && (
                    <div className='mt-4 flex items-center text-center text-green-500'>
                      <Lucide icon='CheckCircle' className='mr-2 text-green-500' />
                      <div className='text-lg'>{successMessage}</div>
                    </div>
                  )}
                  {errorMessage && (
                    <div className='mt-4 flex items-center text-center text-red-500'>
                      <Lucide icon='XCircle' className='mr-2 text-red-500' />
                      <div className='text-lg'>{errorMessage}</div>
                    </div>
                  )}
                  {/* END: Success or error message */}
                  <div className='intro-x mt-2 text-center text-slate-400 xl:hidden'>
                    Một vài bước nữa để đăng nhập vào tài khoản của bạn. Trò chuyện với trợ lý thông minh AI ngay.
                  </div>
                  <div className='intro-x mt-8'>
                    <Field type='email' name='email' className='intro-x login__input form-control block py-3 px-4' placeholder='Email' />
                    <ErrorMessage name='email'>{(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}</ErrorMessage>
                    <Field
                      type='password'
                      name='password'
                      className='intro-x login__input form-control mt-4 block py-3 px-4'
                      placeholder='Mật khẩu'
                    />
                    <ErrorMessage name='password'>{(msg: string) => <span className='block py-1 px-4 text-red-600'>{msg}</span>}</ErrorMessage>
                  </div>
                  <div className='intro-x mt-4 flex text-xs text-slate-600 dark:text-slate-500 sm:text-sm'>
                    <div className='mr-auto flex items-center'>
                      <Field id='remember-me' name='remember' type='checkbox' className='form-check-input mr-2 border' />
                      <label className='cursor-pointer select-none' htmlFor='remember-me'>
                        Nhớ mật khẩu
                      </label>
                    </div>
                    <a href='/auth/reset-password'>Quên mật khẩu?</a>
                  </div>
                  <div className='intro-x mt-5 text-center xl:mt-8 xl:text-left'>
                    {!isSubmitting ? (
                      <button type='submit' className='btn btn-primary w-full py-3 px-4 align-top xl:mr-3 xl:w-32'>
                        Đăng nhập
                      </button>
                    ) : (
                      <button type='submit' className='btn btn-outline-primary w-full py-3 px-4 align-top xl:mr-3 xl:w-32' disabled>
                        <LoadingIcon icon='spinning-circles' className='mr-1 h-5 w-5 -translate-x-4' />{' '}
                        <span className='-translate-x-3'>Đang đăng nhập</span>
                      </button>
                    )}
                    {/*Navigate to /auth/signup to create an account*/}
                    <Link to='/auth/signup'>
                      <button type='button' className='btn btn-outline-secondary mt-3 w-full py-3 px-4 align-top xl:mt-0 xl:w-32'>
                        Đăng ký
                      </button>
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          {/* END: Login Form */}
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
