import React from 'react';
import { useAppSelector } from '@/stores/redux/hooks';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import styled from 'styled-components';

const CartBtnStyled = styled.div`
  position: relative;
  button {
    background: none;
    border: none;
  }
  .cartQtyIndicator {
    content: ${(props: { cartQty: number }) => props.cartQty};
    position: absolute;
    background-color: #ff0000;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default function CartBtn() {
  // const [cartQty, setCartQty] = useState(5);
  const cartQty = useAppSelector((state) => state.cart.totalQuantity);
  return (
    <CartBtnStyled cartQty={cartQty}>
      {cartQty > 0 && (
        <div className='cartQtyIndicator'>
          <p>{cartQty}</p>
        </div>
      )}
      <button>
        <ShoppingCartIcon className='h-6 w-6 text-white' />
      </button>
    </CartBtnStyled>
  );
}
