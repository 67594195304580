export const friendlyCount = (count: number) => {
  if (count < 1000) {
    return count;
  } else if (count < 1000000) {
    return Math.round(count / 1000) + 'K';
  } else if (count < 1000000000) {
    return Math.round(count / 1000000) + 'M';
  } else {
    return Math.round(count / 1000000000) + 'B';
  }
};
