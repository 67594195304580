/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://v4ubnvwnt5gv3nluycrktbnf4m.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-hqdu5fainjagbesv4m6me5wmca",
    "aws_cognito_identity_pool_id": "ap-southeast-1:d47c7b87-e888-45ae-9285-18a051dfed36",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_zBA33rMaP",
    "aws_user_pools_web_client_id": "2drcpihnu19ofttb728ee5qnlr",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "prometenlp-storage-e4c8f21794141-staging",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
