import React, { useEffect, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import TopBar from '@/components/TopBar';
import { Link } from 'react-router-dom';
import financeImg from '@/assets/images/cut-economy-prospect-screenshot.png';
import paperImg from '@/assets/images/literature-review-screenshot.png';
import robotImg from '@/assets/images/robot.png';
import Footer from '@/components/Footer';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import MobileTopBar from '@/components/MobileTopBar';
import FlipBook from '@/components/FlipBook';
import { useMotionValue, useTransform, motion } from 'framer-motion';

export default function AboutPage() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  // typing effect
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ['AI đến từ Promete', 'cân được mọi thứ', 'sẵn sàng hỗ trợ'];
  const period = 1000;
  // 3d card
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [-100, 100], [-30, 30]);

  useEffect(() => {
    const ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    const i = loopNum % toRotate.length;
    const fullText = toRotate[i];
    const updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  //flipbook page count
  function onDocumentLoadSuccess({ numPages }: number | any) {
    setNumPages(numPages);
  }

  return (
    //add topbar here
    <div className='py-5 md:py-0'>
      <MobileTopBar />
      <TopBar className='hidden md:block' />
      <div className='bg-white'>
        <div className='relative overflow-hidden'>
          <main>
            <div className='banner bg-gray-900 pt-16 sm:pt-24 lg:pt-28'>
              <div className='mx-auto max-w-7xl lg:px-8'>
                <div className='lg:grid lg:grid-cols-2 lg:gap-8'>
                  <div className='mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left'>
                    <div className='lg:py-24'>
                      <Link
                        to='/'
                        className='inline-flex items-center rounded-full bg-black p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base'>
                        <span className='rounded-full bg-gradient-to-r from-teal-500 to-cyan-600 px-3 py-0.5 text-sm font-semibold leading-5 text-white'>
                          Newest AI Solution
                        </span>
                        <span className='ml-4 text-sm'>Thử Milu ngay</span>
                        <ChevronRightIcon className='ml-2 h-5 w-5 text-gray-500' aria-hidden='true' />
                      </Link>
                      <TrackVisibility>
                        {({ isVisible }) => (
                          <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>
                            <h1 className='mt-4 text-4xl font-extrabold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl'>
                              <span className='block'>Trợ lý ảo MiLu</span>
                              <span
                                className='txt-rotate'
                                data-period={period}
                                data-rotate={['AI đến từ Promete', 'cân được mọi câu hỏi', 'hỗ trợ tức thì']}>
                                <span className='wrap bg-gradient-to-r from-teal-200 to-cyan-400 bg-clip-text pb-3 text-transparent sm:pb-5'>
                                  {text}
                                </span>
                              </span>
                            </h1>
                          </div>
                        )}
                      </TrackVisibility>
                      <p className='mt-3 text-base font-light text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        Promete là một công ty khởi nghiệp xây dựng trên công nghệ AI và được thành lập vào năm 2022 chuyên về bốn lĩnh vực chính:
                      </p>
                      <span className='text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        Milu, Finance Advisor, Health-tech, và Agri-tech.
                      </span>
                      <div className='mt-5 sm:mt-6'>
                        <p className='mt-3 text-base font-light text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                          Tầm nhìn các AI này sẽ nằm trong cùng một hệ sinh thái (Metaverse). Từ đó phục vụ mục tiêu của Promete là trở thành công ty
                          dẫn đầu về công nghệ tư vấn AI và cung cấp các giải phải chuyên sâu nhất cho người dùng.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mt-12 -mb-16 sm:-mb-48 lg:relative lg:m-0'>
                    <div className='mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0'>
                      <TrackVisibility>
                        {({ isVisible }) => (
                          <div className={isVisible ? 'animate__animated animate__zoomIn' : ''}>
                            <img className='h-full pb-16 md:pb-44 lg:pb-0' src={robotImg} />
                          </div>
                        )}
                      </TrackVisibility>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Feature section with screenshot */}
            <div className='relative overflow-hidden bg-gray-900 pt-16'>
              <div className='relative'>
                <div className='lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8'>
                  <div className='mx-auto max-w-xl px-4 sm:px-6 sm:text-center lg:mx-0 lg:max-w-none lg:py-16 lg:px-0'>
                    <div>
                      <div className='mt-6'>
                        <span className='shine-text block bg-gradient-to-r from-teal-200 to-cyan-400  bg-clip-text text-4xl font-bold tracking-tight text-transparent'>
                          Milu là sản phẩm <a className='invisible sm:visible'>đầu tiên</a>
                        </span>
                        <span className='shine-text -mt-8 block bg-gradient-to-r from-teal-200 to-cyan-400 bg-clip-text pb-3 text-4xl font-bold tracking-tight text-transparent sm:-mt-0 sm:pb-5'>
                          <a className='sm:hidden'>đầu tiên</a> của Promete
                        </span>
                        <p className='mt-4 text-lg font-light text-white'>
                          Đặc biệt AI này có khả năng tư duy, suy nghĩ để trả lời chuyên môn cao về bất cữ lĩnh vực nào. Milu có thể thay bạn làm mọi
                          công việc thường ngày. Ví dụ, từ đơn giản nhất như làm powerpoint, viết email, proofreading, paraphrase cho đến những nhiệm
                          vụ bất khả thi như viết luận văn, tiểu luận, academic paper.
                        </p>
                      </div>
                    </div>
                    <div className='mt-8 border-t border-gray-200 pt-6'>
                      <blockquote>
                        <div>
                          <p className='text-base font-light text-white'>
                            &ldquo;Milu đều có thể <span className='font-bold'>cân</span> tất trong <span className='font-bold'>một nốt nhạc</span>
                            .&rdquo;
                          </p>
                        </div>
                        <footer className='mt-3'>
                          <div className='flex items-center justify-end space-x-3'>
                            <div className='text-base font-light italic text-gray-400'>- Đội phát triển sản phẩm -</div>
                          </div>
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                  <div className='mt-12 sm:mt-16 lg:mt-0'>
                    <div className='sm:pl-6 lg:relative lg:m-0 lg:h-full lg:px-0 lg:py-16'>
                      <img
                        className='w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none'
                        src={financeImg}
                        alt='Inbox user interface'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='pt-24 lg:mt-24'>
                <div className='lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8'>
                  <div className='mx-auto max-w-xl px-4 sm:px-6 sm:text-center lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0'>
                    <div>
                      <div className=' mt-6'>
                        <span className='shine-text block bg-gradient-to-r from-teal-200 to-cyan-400  bg-clip-text pb-3 text-4xl font-bold tracking-tight text-transparent sm:pb-5'>
                          Milu là một trợ lý ảo thực thụ!
                        </span>
                        <p className='mt-4 text-lg font-light text-white'>
                          Với kết quả đáng kinh ngạc, Milu vận dụng và học nguồn kiến thức vô tận trên internet, cùng hệ ngôn ngữ tự nhiên (Natural
                          Language Processing: NLP), Milu đưa ra câu trả lời chuyên sâu và riêng biệt và không rập khuôn, không đạo văn, và có cảm
                          xúc.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mt-12 sm:mt-16 lg:col-start-1 lg:mt-0'>
                    <div className='pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0'>
                      <img
                        className='w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none'
                        src={paperImg}
                        alt='Customer profile user interface'
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='relative h-fit pt-8'>
                <div className='lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-8 lg:px-8'>
                  <div className='mx-auto max-w-xl px-4 sm:px-6 sm:text-center lg:mx-0 lg:max-w-none lg:py-16 lg:px-0 lg:pl-12 lg:text-left'>
                    <div>
                      <div className='mt-6'>
                        <span className='shine-text block bg-gradient-to-r from-teal-200 to-cyan-400  bg-clip-text text-4xl font-bold tracking-tight text-transparent'>
                          Milu Ebook
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='mt-12 sm:mt-16 lg:mt-0'>
                    <div className='sm:pl-6 lg:relative lg:-mb-32 lg:-ml-60 lg:-mr-8 lg:h-full lg:px-0 lg:py-16 lg:pb-36'>
                      <motion.div
                        style={{ x, y, rotateX, rotateY, z: 100 }}
                        drag
                        dragElastic={0.18}
                        dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
                        whileTap={{ cursor: 'grabbing' }}>
                        <FlipBook />
                      </motion.div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mx-auto flex max-w-4xl flex-col items-center justify-center py-20 px-4 pt-24 sm:px-6 sm:pt-20 sm:pb-24 sm:text-center lg:max-w-7xl lg:px-8'>
                <h2 className='shine-text block bg-gradient-to-r from-teal-200 to-cyan-400  bg-clip-text text-4xl font-bold tracking-tight text-transparent'>
                  Còn chần chờ gì nữa?
                </h2>
                <p className='mt-4 max-w-3xl text-lg text-gray-500'>
                  Sử dụng Milu và tham gia cộng đồng sử dụng Milu, để hoàn thành mọi công việc của bạn ngay!
                </p>
              </div>
              <div className='border-t border-gray-200 bg-gray-900 pb-12'>
                <Footer />
                {/*<p className='text-center text-base font-light text-gray-400'>&copy; 2023 Promete, Inc. All rights reserved.</p>*/}
              </div>
            </div>
          </main>
          {/*<footer className='bg-primary' aria-labelledby='footer-heading'>*/}
          {/*  <h2 id='footer-heading' className='sr-only'>*/}
          {/*    Footer*/}
          {/*  </h2>*/}
          {/*<div className='mx-auto max-w-md px-4 pt-12 sm:max-w-7xl sm:px-6 lg:px-8 lg:pt-16'>*/}
          {/*<div className='xl:grid xl:grid-cols-3 xl:gap-8'>*/}
          {/*  <div className='space-y-8 xl:col-span-1'>*/}
          {/*    <LogoImg className='h-8 w-auto' />*/}
          {/*    <span className='text-base text-gray-400'*/}
          {/*    >Promete</span>*/}
          {/*    <p className='text-base text-white'>Giải pháp thông minh cho mọi nhu cầu của bạn.</p>*/}
          {/*    <div className='flex space-x-6'>*/}
          {/*      {footerNavigation.social.map((item) => (*/}
          {/*        <a key={item.name} href={item.href} className='text-gray-400 hover:text-gray-500'>*/}
          {/*          <span className='sr-only'>{item.name}</span>*/}
          {/*          <item.icon className='h-6 w-6' aria-hidden='true' />*/}
          {/*        </a>*/}
          {/*      ))}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className='mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0'>*/}
          {/*    <div className='md:grid md:grid-cols-2 md:gap-8'>*/}
          {/*      <div>*/}
          {/*        <h3 className='text-base font-medium text-white'>Solutions</h3>*/}
          {/*        <ul role='list' className='mt-4 space-y-4'>*/}
          {/*          {footerNavigation.solutions.map((item) => (*/}
          {/*            <li key={item.name}>*/}
          {/*              <a href={item.href} className='text-base  text-white hover:text-gray-300'>*/}
          {/*                {item.name}*/}
          {/*              </a>*/}
          {/*            </li>*/}
          {/*          ))}*/}
          {/*        </ul>*/}
          {/*      </div>*/}
          {/*      <div className='mt-12 md:mt-0'>*/}
          {/*        <h3 className='text-base font-medium text-white'>Support</h3>*/}
          {/*        <ul role='list' className='mt-4 space-y-4'>*/}
          {/*          {footerNavigation.support.map((item) => (*/}
          {/*            <li key={item.name}>*/}
          {/*              <a href={item.href} className='text-base text-white hover:text-gray-300'>*/}
          {/*                {item.name}*/}
          {/*              </a>*/}
          {/*            </li>*/}
          {/*          ))}*/}
          {/*        </ul>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className='md:grid md:grid-cols-2 md:gap-8'>*/}
          {/*      <div>*/}
          {/*        <h3 className='text-base font-medium text-white'>Company</h3>*/}
          {/*        <ul role='list' className='mt-4 space-y-4'>*/}
          {/*          {footerNavigation.company.map((item) => (*/}
          {/*            <li key={item.name}>*/}
          {/*              <a href={item.href} className='text-base text-white hover:text-gray-300'>*/}
          {/*                {item.name}*/}
          {/*              </a>*/}
          {/*            </li>*/}
          {/*          ))}*/}
          {/*        </ul>*/}
          {/*      </div>*/}
          {/*      <div className='mt-12 md:mt-0'>*/}
          {/*        <h3 className='text-base font-medium text-white'>Legal</h3>*/}
          {/*        <ul role='list' className='mt-4 space-y-4'>*/}
          {/*          {footerNavigation.legal.map((item) => (*/}
          {/*            <li key={item.name}>*/}
          {/*              <a href={item.href} className='text-base text-white hover:text-gray-300'>*/}
          {/*                {item.name}*/}
          {/*              </a>*/}
          {/*            </li>*/}
          {/*          ))}*/}
          {/*        </ul>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</footer>*/}
        </div>
      </div>
    </div>
  );
}
