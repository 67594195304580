import React, { Fragment, useEffect, useState } from 'react';
import { helper as $h } from '../utils/helper';
import { nestedMenu } from '@/layouts/utils/SideMenuUtils';
import classnames from 'classnames';
import dom from '@left4code/tw-starter/dist/js/dom';
import SimpleBar from 'simplebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/stores/redux/hooks';
import TopBar from '@/components/TopBar';
import Lucide from '@/base-components/Lucide';
import { linkTo, toggleMobileMenu } from '@/components/utils/MobileMenuUtil';
import logoImg from '@/assets/images/logo-milu-large.png';

const MobileTopBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formattedMenu, setFormattedMenu] = useState([]);
  const sideMenuStore = useAppSelector((state) => state.sideMenu);

  const [activeMobileTopBar, setActiveMobileTopBar] = useState(false);
  const [activeChatList, setActiveChatList] = useState(false);

  useEffect(() => {
    const mobileTopBar = () => nestedMenu($h.toRaw(sideMenuStore.menu), location);

    if (dom('.mobile-menu .scrollable').length) {
      new SimpleBar(dom('.mobile-menu .scrollable')[0]);
    }
    setFormattedMenu(mobileTopBar());
  }, [sideMenuStore, location.pathname, location]);

  return (
    <Fragment>
      {/* BEGIN: Mobile Menu */}
      <div
        className={classnames({
          'mobile-menu md:hidden': true,
          'mobile-menu--active': activeMobileTopBar || activeChatList
        })}>
        <div className='flex h-16 flex-col'>
          <div className='mobile-menu-bar flex items-center justify-start'>
            <a className='flex items-center p-2 sm:pb-8' onClick={() => navigate('/')}>
              {
                //if the current page is the /chat page, then hide the logo
                location.pathname !== '/chat' ? <img src={logoImg} alt='Milu chatbot' className='h-10 w-10' /> : null
              }
              <span className='logo__text ml-1 text-2xl font-extrabold text-white'>Milu</span>
              <span className='text-2xl font-light text-white'> AI</span>
            </a>
            <a href='src/components/MobileMenu#' onClick={(e) => e.preventDefault()} id='mobile-menu-toggler' className='ml-auto'>
              <Lucide
                icon='BarChart2'
                className='h-8 w-8 -rotate-90 text-white'
                onClick={() => {
                  setActiveMobileTopBar(true);
                }}
              />
            </a>
          </div>
          <div className='scrollable'>
            <a href='src/components/MobileMenu#' onClick={(e) => e.preventDefault()} className='mobile-menu-toggler pt-20'>
              <Lucide
                icon='XCircle'
                className='h-8 w-8 -rotate-90 text-white'
                onClick={() => {
                  if (activeChatList) {
                    toggleMobileMenu(activeChatList, setActiveChatList);
                  } else if (activeMobileTopBar) {
                    toggleMobileMenu(activeMobileTopBar, setActiveMobileTopBar());
                  }
                }}
              />
            </a>
            <div className=''>
              {/*{activeMobileMenu === true ? (*/}
              <ul className='scrollable__content py-2'>
                <TopBar />
                {/* BEGIN: First Child */}
                {formattedMenu.map((menu, menuKey) =>
                  menu === 'devider' ? (
                    <li className='menu__devider my-6' key={menu + menuKey}></li>
                  ) : (
                    <li key={menu + menuKey}>
                      <a
                        href={menu.subMenu ? '#' : menu.pathname}
                        className={classnames({
                          menu: true,
                          'menu--active': menu.active,
                          'menu--open': menu.activeDropdown
                        })}
                        onClick={(event) => {
                          event.preventDefault();
                          linkTo(menu, navigate, setActiveMobileTopBar);
                        }}>
                        <div className='menu__icon'>
                          <Lucide icon={menu.icon} />
                        </div>
                        <div className='menu__title'>
                          {menu.title}
                          {menu.subMenu && (
                            <div
                              className={classnames({
                                'menu__sub-icon': true,
                                'rotate-180': menu.activeDropdown
                              })}>
                              <Lucide icon='ChevronDown' />
                            </div>
                          )}
                        </div>
                      </a>
                      {/*/!* BEGIN: Second Child *!/*/}
                      {/*<Transition in={menu.activeDropdown} onEnter={enter} onExit={leave} timeout={300}>*/}
                      {/*  <ul*/}
                      {/*    className={classnames({*/}
                      {/*      'menu__sub-open': menu.activeDropdown*/}
                      {/*    })}>*/}
                      {/*    <div className='menu__devider my-6'>*/}
                      {/*      <ChatRoomList agents={agents} />*/}
                      {/*    </div>*/}
                      {/*  </ul>*/}
                      {/*</Transition>*/}
                      {/*/!* END: Second Child *!/*/}
                    </li>
                  )
                )}

                {/* END: First Child */}
              </ul>
              {/*) : activeChatList === true ? (*/}
              <ul className='scrollable__content py-2 pt-20'>
                {/* BEGIN: First Child */}
                {/* END: First Child */}
              </ul>
              {/*) : null}*/}
            </div>
          </div>
        </div>
      </div>
      {/* END: Mobile Menu */}
    </Fragment>
  );
};

export default MobileTopBar;
