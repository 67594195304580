import React, { Fragment } from 'react';
import TopBar from '@/components/TopBar';
import Footer from '@/components/Footer';

export default function PrivacyPolicyPage() {
  return (
    <Fragment>
      <TopBar />
      <div className='bg-white'>
        <div className='relative overflow-hidden'>
          <main>
            {/* Feature section with screenshot */}
            <div className='relative overflow-hidden bg-gray-900 pt-16'>
              <div aria-hidden='true' className='absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-900' />
              <div className='relative'>
                <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
                  <div>
                    <div className='my-6'>
                      <span className='block bg-gradient-to-r from-teal-200 to-cyan-400  bg-clip-text pt-16 text-4xl font-bold tracking-tight text-transparent'>
                        Milu và Promete tôn trọng quyền riêng tư cá nhân của bạn.{' '}
                      </span>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        Vui lòng tham khảo các điều khoản sau để hiểu rõ hơn cam kết của chúng tôi trong việc đảm bảo quyền riêng tư của Bạn và cách
                        luật pháp bảo vệ Bạn.
                      </p>
                      <p className='mt-4 text-lg font-light text-white'>
                        Chúng tôi thực hiện các dịch vụ thay mặt chúng tôi, thu thập thông tin trong nhiều trường hợp khác nhau khi bạn truy cập và sử
                        dụng Trang web cũng như sử dụng dịch vụ của chúng tôi. Có hai lý do chính giải thích tại sao chúng tôi thu thập thông tin về
                        bạn:
                        <br />
                        (i) tuân thủ luật pháp của khu vực tài phán hiện hành; và
                        <br />
                        (ii) cung cấp Dịch vụ của chúng tôi và để các Trang web của chúng tôi hoạt động như dự định.
                        <br />
                        Chính sách Bảo mật này chủ yếu nhằm mục đích mô tả cách thức sử dụng thông tin cho doanh nghiệp của chúng tôi. Xin lưu ý rằng
                        nếu bất kỳ thông tin nào được yêu cầu thu thập theo luật của khu vực tài phán hiện hành và/hoặc bất kỳ lệnh tòa nào, chúng tôi
                        sẽ thu thập thông tin đó mặc dù một trường hợp cụ thể như vậy có thể không được mô tả rõ ràng ở đây.
                        <br />
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        Trừ khi được trình bày rõ ràng ở đây, Chính sách Bảo mật này không đề cập đến và chúng tôi cũng không chịu trách nhiệm về tính
                        hợp pháp, thông tin hoặc các hoạt động khác của các trang web khác và/hoặc bất kỳ bên thứ ba nào cung cấp liên kết đến bất kỳ
                        trang web nào theo đó liên kết đó hiển thị trên các trang web của chúng tôi. Với mục đích tránh bất kỳ sự mơ hồ nào, việc bất
                        kỳ ai ngoài chúng tôi bao gồm một liên kết đến trang web của bên thứ ba không có nghĩa là chúng tôi hoặc các chi nhánh của
                        chúng tôi chứng thực trang web được liên kết đó. Xin lưu ý rằng nếu bạn chọn truy cập và/hoặc sử dụng bất kỳ trang web nào của
                        bên thứ ba, hành động đó sẽ hoàn toàn do bạn tự chịu rủi ro. Chúng tôi khuyến khích bạn sử dụng Dịch vụ, Trang web, hệ thống
                        thanh toán và nền tảng của chúng tôi. Ngoài ra, bạn có nhiệm vụ đọc các chính sách và điều khoản của mọi trang web mà bạn truy
                        cập.
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        CÁC LOẠI DỮ LIỆU ĐƯỢC THU THẬP{' '}
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Trong khi sử dụng dịch vụ của chúng tôi, chúng tôi có thể yêu cầu Bạn cung cấp cho chúng tôi một số thông tin nhận dạng cá
                        nhân có thể được sử dụng để liên hệ hoặc nhận dạng Bạn nhằm thực hiện giao dịch của Bạn. Thông tin nhận dạng cá nhân có thể
                        bao gồm, nhưng không giới hạn ở: <br />
                        <br />
                        • Họ và tên <br />
                        • Số điện thoại <br />
                        • Địa chỉ, Tỉnh, Thành phố <br />
                        • Địa chỉ email
                        <br />
                        Trong cả hai trường hợp nêu trên, chúng tôi có thể thu thập thông tin:
                        <br />
                        • khi bạn đăng ký dịch vụ của chúng tôi, hãy đăng ký danh sách gửi thư của chúng tôi, hoặc liên lạc với chúng tôi;
                        <br />
                        • khi bạn sử dụng và tương tác với Trang web và dịch vụ của chúng tôi, chẳng hạn như khi bạn đặt hàng, đăng bài đánh giá hoặc
                        tương tác với những người dùng khác hoặc với các doanh nghiệp trên Trang web;
                        <br />
                        • khi bạn thanh toán cho các dịch vụ;
                        <br />
                        • nếu bạn đăng nhập thông qua mạng xã hội hoặc dịch vụ xác thực, chẳng hạn như Facebook hoặc Google, các dịch vụ này sẽ xác
                        thực danh tính của bạn và có thể cung cấp cho bạn tùy chọn chia sẻ Thông tin cá nhân (chẳng hạn như tên, địa chỉ e-mail, sổ
                        địa chỉ và địa chỉ liên hệ, hoặc thông tin khác) với chúng tôi; và
                        <br />
                        • nếu bạn cấp quyền cho các Trang web truy cập sổ địa chỉ hoặc thư viện ảnh trên thiết bị của bạn, chẳng hạn như để gửi lời
                        mời cho bạn bè hoặc tải ảnh hoặc video lên các Trang web.
                        <br />
                        Thông tin chúng tôi thu thập bao gồm Thông tin cá nhân có thể nhận dạng duy nhất bạn (cho dù một mình hay kết hợp với dữ liệu
                        hoặc thông tin khác), chẳng hạn như tên pháp lý đầy đủ của bạn, ảnh hồ sơ, địa chỉ bưu điện, số điện thoại, địa chỉ e-mail,
                        ngày sinh , giới tính, địa chỉ thực và hợp pháp hoặc dữ liệu tương tự. Thông tin Cá nhân cũng bao gồm một số thông tin nhạy
                        cảm nhất định liên quan đến tài chính của bạn hoặc đối với tài khoản doanh nghiệp, liên quan đến tài chính của doanh nghiệp
                        của bạn, chẳng hạn như số thẻ tín dụng hoặc số tài khoản thanh toán khác (bao gồm mã xác thực chữ số cho thẻ ngân hàng của bạn
                        hoặc các thông tin cần thiết để kết nối ví kỹ thuật số của chúng tôi với tài khoản ngân hàng của bạn). Để bạn tận dụng Dịch vụ
                        của chúng tôi, chúng tôi có thể yêu cầu bạn cung cấp Thông tin cá nhân.
                        <br />
                        Chúng tôi cũng có thể thu thập thông tin phi cá nhân từ bạn, bao gồm thông tin về việc bạn sử dụng Trang web của chúng tôi,
                        nhưng khi thông tin phi cá nhân đó được liên kết với Thông tin cá nhân của bạn, chúng tôi sẽ coi đó là Thông tin cá nhân.
                        Chúng tôi cũng có thể nhận thông tin về bạn từ các nguồn của bên thứ ba, chẳng hạn như những người dùng khác trên Trang web
                        của chúng tôi, tài xế, người bán và các đối tác khác.
                        <br />
                        Thông tin được thu thập tự động khi bạn truy cập Trang web của chúng tôi và sử dụng Dịch vụ của chúng tôi. Chúng tôi có thể
                        thu thập một số loại thông tin sử dụng nhất định khi bạn truy cập Trang web của chúng tôi, đọc email của chúng tôi hoặc tương
                        tác với chúng tôi. Chúng tôi có thể sử dụng thông tin này để nâng cao và cá nhân hóa trải nghiệm người dùng của bạn, để theo
                        dõi và cải thiện các trang web và dịch vụ của chúng tôi cũng như cho các mục đích tương tự khác. Trong một số trường hợp,
                        chúng tôi có thể kết hợp thông tin sử dụng này với Thông tin cá nhân. Nếu chúng tôi kết hợp bất kỳ thông tin sử dụng nào với
                        Thông tin cá nhân, thông tin kết hợp sẽ được chúng tôi coi là Thông tin cá nhân dưới đây (miễn là nó được kết hợp như vậy).
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        KIỂM SOÁT THÔNG TIN CÁ NHÂN CỦA BẠN{' '}
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Bạn có thể xem xét, chỉnh sửa và/hoặc cập nhật các yếu tố nhất định của Thông tin cá nhân của mình bằng cách điều chỉnh thông
                        tin được kết nối với tài khoản của bạn trong các phần có liên quan của Trang web. Tuy nhiên, chúng tôi đều không chịu trách
                        nhiệm thay đổi Thông tin Cá nhân từ cơ sở dữ liệu và/hoặc hồ sơ khác của các bên thứ ba mà chúng tôi đã chia sẻ Thông tin Cá
                        nhân của bạn trước đây.
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        SỬ DỤNG DỮ LIỆU CÁ NHÂN CỦA BẠN{' '}
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Công ty có thể sử dụng Dữ liệu Cá nhân cho các mục đích sau:
                        <p className='mt-4 px-2 text-lg font-light text-white'>
                          • Để liên hệ với bạn: Để liên hệ với bạn qua email, cuộc gọi điện thoại, SMS hoặc các hình thức liên lạc điện tử tương đương
                          khác, chẳng hạn như thông báo đẩy của ứng dụng di động về việc bạn thực hiện các mặt hàng hoặc dịch vụ đã mua.
                          <br />• Cung cấp cho bạn tin tức, ưu đãi đặc biệt và thông tin chung về hàng hóa, dịch vụ và sự kiện khác mà chúng tôi cung
                          cấp tương tự như những sản phẩm mà bạn đã mua hoặc thắc mắc; trừ khi bạn đã chọn không nhận thông tin như vậy.
                          <br />• Để quản lý các yêu cầu của bạn: để tham dự và quản lý các yêu cầu của bạn với chúng tôi.
                          <br />
                          Chúng tôi có thể sử dụng công nghệ theo dõi, bao gồm cookie, đèn hiệu web, tập lệnh nhúng, công nghệ xác định vị trí, thông
                          tin tệp và công nghệ tương tự, để tự động thu thập thông tin sử dụng và thiết bị, chẳng hạn như:
                          <br />• Thông tin về thiết bị của bạn và phần mềm của nó, bao gồm địa chỉ IP, loại trình duyệt, nhà cung cấp dịch vụ
                          Internet, loại thiết bị/kiểu máy/nhà sản xuất, hệ điều hành, dấu ngày tháng và một mã nhận dạng duy nhất cho phép chúng tôi
                          xác định trình duyệt, thiết bị di động của bạn hoặc tài khoản của bạn (bao gồm, ví dụ: số nhận dạng thiết bị liên tục) và
                          thông tin tương tự khác. Chúng tôi cũng có thể làm việc với các đối tác bên thứ ba để sử dụng các công nghệ, bao gồm cả việc
                          áp dụng các công cụ lập mô hình thống kê cho phép chúng tôi nhận ra và liên hệ với bạn trên nhiều thiết bị.
                          <br />• Chúng tôi có thể thu thập dữ liệu phân tích hoặc sử dụng nền tảng của chúng tôi hoặc các công cụ phân tích của bên
                          thứ ba để giúp chúng tôi đo lường lưu lượng truy cập và xu hướng sử dụng cho Trang web và để hiểu thêm về nhân khẩu học của
                          người dùng của chúng tôi. Thông tin này sẽ có sẵn cho các doanh nghiệp đã đăng ký trên trang web của chúng tôi để xem xét.
                          <br />
                          Nếu bạn không muốn chấp nhận cookie, hầu hết các trình duyệt sẽ cho phép bạn: (i) thay đổi cài đặt trình duyệt của bạn để
                          thông báo cho bạn khi bạn nhận được cookie, điều này cho phép bạn chọn chấp nhận hay không; (ii) vô hiệu hóa các cookie hiện
                          có; hoặc (iii) đặt trình duyệt của bạn tự động từ chối cookie; tuy nhiên, làm như vậy có thể tác động tiêu cực đến trải
                          nghiệm của bạn khi sử dụng Trang web, vì một số tính năng và dịch vụ trên Trang web của chúng tôi có thể không hoạt động
                          bình thường. Bạn cũng có thể đặt các tùy chọn e-mail của mình để ngăn việc tải xuống tự động các hình ảnh có thể chứa các
                          công nghệ cho phép chúng tôi biết liệu bạn đã truy cập vào e-mail của chúng tôi và thực hiện một số chức năng với nó hay
                          chưa.
                        </p>
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        TIẾT LỘ DỮ LIỆU CÁ NHÂN CỦA BẠN{' '}
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Trong một số trường hợp nhất định, Công ty có thể được yêu cầu tiết lộ Dữ liệu Cá nhân của Bạn nếu luật pháp yêu cầu làm như
                        vậy hoặc theo yêu cầu hợp lệ của cơ quan công quyền (ví dụ: tòa án hoặc cơ quan chính phủ).
                        <br />
                        Công ty có thể tiết lộ Dữ liệu Cá nhân của Bạn với thiện chí tin rằng hành động đó là cần thiết để:
                        <br />• Tuân thủ nghĩa vụ pháp lý • Ngăn chặn hoặc điều tra hành vi sai trái có thể xảy ra liên quan đến Dịch vụ
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        BẢO MẬT DỮ LIỆU CÁ NHÂN CỦA BẠN
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Việc bảo mật Dữ liệu Cá nhân của Bạn là quan trọng đối với Chúng tôi. Milu cam kết bảo vệ thông tin cá nhân của bạn bằng mọi
                        cách có thể bằng việc sử dụng các biện pháp bảo mật thông tin khác nhau để đảm bảo an toàn cho dữ liệu cá nhân của bạn.
                        <br />
                        Chúng tôi sử dụng các biện pháp tổ chức, kỹ thuật và hành chính hợp lý được thiết kế để bảo vệ Thông tin Cá nhân dưới sự kiểm
                        soát của chúng tôi. Tuy nhiên, không thể đảm bảo việc truyền dữ liệu qua Internet hoặc hệ thống lưu trữ dữ liệu là an toàn
                        100%. Nếu bạn có lý do để tin rằng tương tác của bạn với chúng tôi không an toàn (ví dụ: nếu bạn cảm thấy rằng sự bảo mật của
                        bất kỳ tài khoản nào bạn có thể có với chúng tôi đã bị xâm phạm), bạn phải thông báo ngay cho chúng tôi về vấn đề bằng cách
                        liên hệ với chúng tôi theo với phần `&quot;`Liên hệ với chúng tôi`&quot;` bên dưới.
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        HƯỚNG DẪN XÓA DỮ LIỆU FACEBOOK
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Milu Login là ứng dụng đăng nhập Facebook và chúng tôi không lưu dữ liệu cá nhân của bạn vào máy chủ của chúng tôi. Theo chính
                        sách của Facebook, chúng tôi phải cung cấp URL gọi lại xóa dữ liệu người dùng hoặc URL hướng dẫn xóa dữ liệu.
                        <br />
                        Nếu bạn muốn xóa các hoạt động của mình cho Ứng dụng Đăng nhập Milu, bạn có thể xóa thông tin của mình bằng cách làm theo các
                        bước sau:
                        <p className='mt-4 px-2 pl-3 text-lg font-light text-white'>
                          1. Đi tới Cài đặt & Quyền riêng tư của Tài khoản Facebook của bạn. Nhấp vào “Cài đặt”.
                          <br />
                          2. Tìm kiếm “Ứng dụng và trang web” và bạn sẽ thấy tất cả các ứng dụng và trang web mà bạn đã liên kết với Facebook của
                          mình.
                          <br />
                          3. Tìm kiếm và nhấp vào “Milu” trên thanh tìm kiếm.
                          <br />
                          4. Cuộn và nhấp vào “Xóa”.
                          <br />
                          5. Xin chúc mừng, bạn đã xóa thành công các hoạt động ứng dụng của mình.
                        </p>
                        Nếu bạn muốn xóa tất cả dữ liệu của mình trên Trang web Milu, vui lòng gửi email cho chúng tôi theo địa chỉ: help@Milu.com
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>LIÊN HỆ CHÚNG TÔI</p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Nếu bạn có bất kỳ thắc mắc nào liên quan đến Chính sách Bảo mật này, vui lòng liên hệ với chúng tôi bằng cách:
                        <br />
                        Gọi đường dây nóng: 093 824 72 83
                        <br /> E-mail theo địa chỉ: milu@promete.ai
                        <br />
                        Xin lưu ý rằng thông tin liên lạc qua e-mail sẽ không nhất thiết phải an toàn; do đó, vui lòng không bao gồm thông tin thẻ tín
                        dụng và/hoặc Thông tin Cá nhân nhạy cảm khác trong thư điện tử của bạn với chúng tôi.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='border-t border-gray-200 bg-gray-900 pb-12'>
                <Footer />
              </div>
            </div>
          </main>
        </div>
      </div>
    </Fragment>
  );
}
