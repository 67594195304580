import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import { useInterval } from 'usehooks-ts';
import { keys } from 'ts-transformer-keys';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { useAppDispatch, useAppSelector } from '@/stores/redux/hooks';
import { chatRoomActions, selectActiveChatRoom } from '@/stores/redux/chat-room/chat-room-slice';
import { createEmptyMessage, IMessage } from '@/types/IMessage';
import { messageContentHash } from '@/utils/message_content_hasher';
import { currentTimestampString } from '@/utils/friendly_timestamp';
import { generate_uuid } from '@/utils/uuid_generator';
import { isChatRoomsReady } from '@/stores/redux/chat-rooms/chat-rooms-slice';
import { selectAllAgents } from '@/stores/redux/agents/agents-slice';

interface IChatContext {
  sendMessage: (messageText: string) => void;
  isWaitingForResponse: ReturnType<typeof useRef<boolean>>;
  socket: Socket | null;
}

const ChatContext = createContext<IChatContext>({
  sendMessage: (messageText: string) => {
    console.log(messageText);
  },
  isWaitingForResponse: { current: false },
  socket: null
});

interface IChatProviderProps {
  children: ReactNode;
}

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }: IChatProviderProps) => {
  const chat = useProvideChat();
  return <ChatContext.Provider value={chat}>{children}</ChatContext.Provider>;
};

const useProvideChat = () => {
  const { user } = useAuth();
  const [socket, setSocket] = useState<Socket | null>(null);
  const chatRoomsReady = useAppSelector((state) => isChatRoomsReady(state));
  const activeChatRoom = useAppSelector(selectActiveChatRoom);
  const [resetSocketCount, setResetSocketCount] = useState<number>(0);
  const [isUserChanged, setIsUserChanged] = useState<boolean>(false);
  const agents = useAppSelector((state) => selectAllAgents(state));
  const dispatch = useAppDispatch();

  // Used to buffered stream message
  const streamMessageBufferRef = useRef<IMessage>(createEmptyMessage());

  const isWaitingForResponse = useRef<boolean>(false);
  const [delay, setDelay] = useState<number | null>(null);
  const [streamMessageDelay, setStreamMessageDelay] = useState<number | null>(null);

  // Create a new socket when the MessageBoard component is mounted
  useEffect(() => {
    if (!chatRoomsReady) {
      return;
    }

    const newSocket = io('/chat_bot', {
      transports: ['websocket'],
      auth: {
        token: user?.idToken
      }
    });

    setSocket(newSocket);
    console.log('newSocket', newSocket);

    return () => {
      console.log('disconnecting socket');
      newSocket.disconnect();
      setSocket(null);
    };
  }, [activeChatRoom.id, chatRoomsReady, resetSocketCount]);

  // Detect when the user is changed
  useEffect(() => {
    if (!user) {
      return;
    }

    console.log('User changed, need to update the socket');

    // Check again next time
    setIsUserChanged(true);

    // Check if the user is waiting for a response
    if (isWaitingForResponse.current) {
      // Let the check interval run
      setDelay(1000);

      console.log('User is waiting for a response from the server. Retry later...');
    } else {
      // Do not let the check interval run
      setDelay(null);

      // Update a new socket, as user id token and access token are changed
      setResetSocketCount((prev) => prev + 1);

      console.log('User is not waiting for response from server, can update the socket now.');
    }
  }, [user]);

  const addStreamMessage = useCallback(() => {
    if (streamMessageBufferRef.current.text) {
      const streamMessage = {
        ...streamMessageBufferRef.current
      };
      streamMessageBufferRef.current.text = '';
      streamMessageBufferRef.current.timestamp = '';
      dispatch(chatRoomActions.addStreamMessage(streamMessage));
    }
  }, [dispatch]);

  // Listen to even user changed
  useInterval(() => {
    console.log('Socket update checking interval running...');

    if (!isUserChanged) {
      console.log('User is not changed. Checking next time...');
      return;
    }

    if (!isWaitingForResponse.current) {
      console.log('User is not waiting for response from server, can update the socket now.');

      // Do not let the check interval run
      setDelay(null);

      // Update a new socket, as user id token and access token are changed
      setResetSocketCount((prev) => prev + 1);
    } else {
      console.log('User is waiting for a response from the server. Retry later...');
    }
  }, delay);

  useInterval(() => {
    addStreamMessage();
  }, streamMessageDelay);

  const addMessage = useCallback(
    (msg: IMessage) => {
      // Calculate the hash of the message
      msg.contentHash = messageContentHash(msg);
      // Add the message to the redux store
      dispatch(chatRoomActions.addMessage(msg));
    },
    [dispatch]
  );

  const sendMessage = useCallback(
    (messageText: string) => {
      if (!activeChatRoom) {
        console.error('No chat room found');
        return;
      }

      if (!user) {
        console.error('Cannot send message without user signed in');
        return;
      }

      if (socket == null) {
        alert('socket is null');
        return;
      }
      if (messageText) {
        const currentTimeString = currentTimestampString();
        const newMessageData: IMessage = {
          id: 'temporary-message-' + generate_uuid(), // This is just a temporary ID, the server will generate a new ID later
          text: messageText,
          timestamp: currentTimeString,
          createdAt: currentTimeString,
          userID: user.id,
          chatRoomID: activeChatRoom.id,
          isDefaultMessage: false
        };
        newMessageData.contentHash = messageContentHash(newMessageData);

        socket.emit('message', newMessageData);
        addMessage(newMessageData);

        isWaitingForResponse.current = true;
      }
    },
    [activeChatRoom, addMessage, socket, user]
  );

  useEffect(() => {
    (async () => {
      if (!user) {
        return;
      }

      if (!activeChatRoom.id) {
        console.log('No active chat room found');
        return;
      }

      if (socket != null) {
        socket.on('connect', () => {
          // This is a good time to disable the user change flag as the socket connection process has been finished
          setIsUserChanged(false);

          // Get the agent name
          const agentName = agents.find((agent) => agent.id === activeChatRoom.agentID)?.name;
          if (!agentName) {
            console.error('Agent not found');
            return;
          }

          socket.emit('join_room', {
            chatRoomID: activeChatRoom.id,
            agentID: activeChatRoom.agentID
          });
        });

        // On message from the server, add the message to the message list
        socket.on('message', (data: IMessage) => {
          console.log('message received', data);

          // Sometimes, the message does not have an ID, which means we can use the previous message.
          // In this case, we do not need to add a new message to the list.
          if (data && data.ok && data.id) {
            const newMessage: IMessage = keys<IMessage>().reduce((acc, key) => {
              if (key !== 'ok' && key !== 'contentHash') {
                // @ts-ignore
                acc[key] = data[key];
              }
              return acc;
            }, {} as IMessage);

            addMessage(newMessage);
            console.log('Message added', newMessage);
          } else if (!data.ok && data.text === 'Token limit reached. Please top up your account to continue chatting.') {
            alert(data.text);
          }

          isWaitingForResponse.current = false;
        });

        socket.on('message_stream', (data: IMessage) => {
          // Add stream message to the message list
          if (data && data.ok) {
            streamMessageBufferRef.current.text += data.text;
            streamMessageBufferRef.current.timestamp = data.timestamp;
            if (streamMessageDelay === null) {
              setStreamMessageDelay(100);
            }
          }
        });

        socket.on('message_stream_end', (data: IMessage) => {
          // Add stream message to the message list
          if (data && data.ok) {
            dispatch(chatRoomActions.updateAndEndStreamMessage(data));
          }
          isWaitingForResponse.current = false;
          setStreamMessageDelay(null);
        });

        // Disconnect the socket when the component unmounts
        return () => socket.disconnect();
      }
    })();
  }, [addMessage, activeChatRoom.id, dispatch, user, socket]);

  return {
    sendMessage,
    isWaitingForResponse,
    socket
  };
};
