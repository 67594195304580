import { DropDown, DropdownContent, DropdownItem, DropdownMenu, DropdownToggle } from '@/base-components/DropDown';
import { selectActiveChatRoom } from '@/stores/redux/chat-room/chat-room-slice';
import React, { Fragment, useCallback } from 'react';
import { AcademicCapIcon, BookOpenIcon, BugAntIcon, CreditCardIcon } from '@heroicons/react/24/outline';
import { IAgent } from '@/types/IAgent';
import { useAppDispatch, useAppSelector } from '@/stores/redux/hooks';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@/base-components/Tab';
import classnames from 'classnames';
import chatbotImg from '@/assets/images/logo-milu.png';
import { addChatRoom, findNextChatRoom, isChatRoomsReady, selectAllChatRooms, setActiveChatRoom } from '@/stores/redux/chat-rooms/chat-rooms-slice';
import { useAuth } from '@/context/AmplifyAuthProvider';
import LoadingIcon from '@/base-components/LoadingIcon';
import { AgentTypeEnglish, AgentTypeVietnamese } from '@/utils/prompt_utils';
import 'react-swipeable-list/dist/styles.css';
import { TrashIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { generate_uuid } from '@/utils/uuid_generator';

interface ChatRoomListProps {
  agents: IAgent[];
}

export const ChatRoomList = ({ agents }: ChatRoomListProps) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const chatRooms = useAppSelector((state) => selectAllChatRooms(state));
  const activeChatRoom = useAppSelector(selectActiveChatRoom);
  const chatRoomsReady = useAppSelector((state) => isChatRoomsReady(state));
  const nextChatRoom = useAppSelector((state) => findNextChatRoom(state, activeChatRoom.id));
  const dispatch = useAppDispatch();

  const createChatRoomHandler = useCallback(
    async (agent: IAgent) => {
      if (!user) {
        console.warn('User is not logged in, this should not happen');
        return;
      }

      // Create a chat room ID which is compatible with DynamoDB (UUID v4)
      const chatRoomID = generate_uuid();

      const ret = await axios.post(
        '/chat_rooms/create',
        {
          chatRoomID: chatRoomID,
          userID: user.id,
          agentID: agent.id
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.idToken}`
          },
          timeout: 10000
        }
      );

      if (ret.status === 201 && ret.data && ret.data.event === 'chatRoomCreated' && ret.data.data) {
        console.log('Chat room created successfully');
        dispatch(
          addChatRoom({
            ...ret.data.data
          })
        );

        // Set the active chat room to the newly created chat room
        dispatch(setActiveChatRoom(chatRoomID));
      }
    },
    [user]
  );

  const generateButtonOptions = useCallback(() => {
    const buttonOptions = agents.map((agent) => {
      return {
        label: agent.name,
        icon:
          agent.name === AgentTypeEnglish.StockInvestmentAdvisor || agent.name === AgentTypeVietnamese.StockInvestmentAdvisor ? (
            <CreditCardIcon className='h-6 w-6' />
          ) : agent.name === AgentTypeEnglish.EnglishLearningAssistant || agent.name === AgentTypeVietnamese.EnglishLearningAssistant ? (
            <AcademicCapIcon className='h-6 w-6' />
          ) : agent.name === AgentTypeEnglish.ResearchAssistant || agent.name === AgentTypeVietnamese.ResearchAssistant ? (
            <BookOpenIcon className='h-6 w-6' />
          ) : (
            <BugAntIcon className='h-6 w-6' />
          ),
        isActive: false,
        agentLanguage: agent.language
      };
    });
    if (buttonOptions && buttonOptions.length > 0) {
      buttonOptions[0].isActive = true;
    }
    buttonOptions.sort((a, b) => {
      if (a.agentLanguage < b.agentLanguage) {
        return -1;
      }
      if (a.agentLanguage > b.agentLanguage) {
        return 1;
      }
      return 0;
    });
    return buttonOptions;
  }, [agents]);

  if (!user || !chatRoomsReady) {
    return (
      <div className='flex h-full w-full items-center justify-center'>
        <LoadingIcon icon='grid' className='h-12 w-12' />
      </div>
    );
  }

  const removeChatRoomHandler = async (chatRoomID: string) => {
    const chatRoom = chatRooms.find((room) => room.id === chatRoomID);
    if (!chatRoom) {
      console.warn('Chat room not found');
      return;
    } else {
      console.log('Chat room to be deleted: name = ' + chatRoom.chatRoomName, 'id = ' + chatRoom.id);
    }

    if (!user) {
      console.warn('User is not logged in, this should not happen');
      return;
    }

    axios
      .delete(`/chat_rooms/${chatRoom.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.idToken}`
        }
      })
      .then((response) => {
        if (response.status === 200) {
          console.log('Chat room deleted successfully');
        } else {
          console.error('Error deleting chat room: ' + response.data);
        }
      })
      .catch((error) => {
        console.error('Error deleting chat room: ' + error);
      });

    // If the chat room to be deleted is the active chat room, set the active chat room to the next chat room
    if (activeChatRoom.id === chatRoomID) {
      // Set the active chat room to the next chat room in the list
      console.log('Next chat room', nextChatRoom);
      if (nextChatRoom) {
        dispatch(setActiveChatRoom(nextChatRoom.id));
      }
    }
  };

  return (
    <Fragment>
      <TabGroup className='sm: pt-5'>
        <div className='intro-y pr-1'>
          <div className='box p-2'>
            <TabList className='nav-pills'>
              <Tab className='w-full py-2' tag='button'>
                <DropDown placement={window.innerWidth < 768 ? 'bottom-start' : 'right-start'}>
                  <DropdownToggle className='flex w-full items-center justify-center' tag='a'>
                    Bắt đầu Chat
                  </DropdownToggle>
                  <DropdownMenu className='w-fit'>
                    <DropdownContent className='dark:divide-dark-5 divide-y divide-gray-200 p-2'>
                      <div className='scrollbar-hidden h-96 flex-1 overflow-y-scroll'>
                        {generateButtonOptions().map((option, index) => (
                          <DropdownItem key={index}>
                            <div
                              className='flex items-center'
                              onClick={async () => {
                                const agentName = option.label;

                                // Find the agent ID by agent name
                                const agent = agents.find((agent) => agent.name === agentName);

                                if (agent) {
                                  await createChatRoomHandler(agent);
                                }
                              }}>
                              <div className='image-fit mr-1 flex h-8 w-8 items-center'>{option.icon}</div>
                              <div className='ml-2 overflow-hidden'>
                                <div className='truncate font-medium'>{option.label}</div>
                              </div>
                            </div>
                          </DropdownItem>
                        ))}
                      </div>
                    </DropdownContent>
                  </DropdownMenu>
                </DropDown>
              </Tab>
            </TabList>
          </div>
        </div>
        <TabPanels>
          <TabPanel>
            <div className='flex h-screen flex-col pr-1 pt-1 sm:h-24 lg:h-screen'>
              <div className='scrollable scrollbar-hidden flex-1 space-y-2 overflow-y-scroll pb-10 lg:pb-40'>
                {chatRooms.map((chatRoom) => (
                  <div key={chatRoom.id}>
                    <div
                      key={chatRoom.id}
                      className={classnames({
                        'group/listitem intro-x box group flex w-full cursor-pointer items-center justify-between p-5 hover:bg-gray-200 dark:hover:bg-slate-800':
                          true,
                        'text-primary-500 dark:text-primary-300 group w-full bg-gray-200 font-bold dark:bg-slate-700':
                          activeChatRoom && activeChatRoom.id === chatRoom.id
                      })}>
                      <div
                        className='flex flex-row overflow-hidden truncate bg-transparent'
                        onClick={(event) => {
                          event.preventDefault();
                          dispatch(setActiveChatRoom(chatRoom.id));
                          if (window.innerWidth < 768) {
                            // use navigate instead of window.location.href to avoid reloading the page
                            navigate('/chat');
                          }
                        }}>
                        <div className='image-fit mr-1 h-12 w-12 flex-none'>
                          <img alt='Milu chatbot' className='rounded-full' src={chatbotImg} />
                          <div className='absolute right-0 bottom-0 h-3 w-3 rounded-full border-2 border-white bg-success dark:border-darkmode-600'></div>
                        </div>
                        <div className='relative ml-2 flex justify-between overflow-hidden break-all'>
                          <div className='flex flex-col'>
                            <div className='flex items-center'>
                              <a href='#' className='font-medium'>
                                {agents.length ? agents.find((agent) => agent.id === chatRoom.agentID)?.name : 'Chatbot'}
                              </a>
                            </div>
                            <div className='mt-0.5 w-full text-slate-500'>
                              {chatRoom.lastMessageText ? chatRoom.lastMessageText : 'I am a chat bot. I am glad to answer your questions.'}
                            </div>
                          </div>
                          <div
                            // className='absolute inset-y-0 right-0 z-10 w-8 bg-gradient-to-l from-white to-transparent dark:from-darkmode-700 focus:from-gray-50'
                            className={classnames(
                              activeChatRoom && activeChatRoom.id === chatRoom.id
                                ? 'absolute inset-y-0 right-0 z-20 mx-auto w-8 cursor-pointer bg-gradient-to-l from-gray-200 to-transparent group-hover:from-gray-200 dark:from-slate-700 dark:group-hover:from-slate-800'
                                : 'absolute inset-y-0 right-0 z-20 mx-auto w-8 cursor-pointer bg-gradient-to-l from-white to-transparent group-hover:from-gray-200 dark:from-darkmode-600 dark:group-hover:from-slate-800'
                            )}></div>
                        </div>
                      </div>
                      <div
                        className='-my-3 hidden h-12 w-5 items-center justify-end bg-transparent group-hover/listitem:flex'
                        onClick={() => removeChatRoomHandler(chatRoom.id)}>
                        <div className='-mr-4 flex h-16 w-24 scale-75 items-center justify-center bg-transparent text-gray-500 transition duration-500 hover:scale-100 hover:text-red-500'>
                          <TrashIcon className='h-6 w-6' />
                        </div>
                      </div>

                      {/*{faker.trueFalse[0] && (*/}
                      {/*  <div*/}
                      {/*    className='w-5 h-5 flex items-center justify-center absolute top-0 right-0 text-xs text-white rounded-full bg-primary font-medium -mt-1 -mr-1'>*/}
                      {/*    {faker.notificationCount}*/}
                      {/*    /!*TODO (MinhLuan): show unread messages*!/*/}
                      {/*  </div>*/}
                      {/*)}*/}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </Fragment>
  );
};
