import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { AppDispatch } from '@/stores/redux/store';
import { usersActions } from '@/stores/redux/users/users-slice';
import { cloneUserWithUserToken, IUserWithUserToken } from '@/types/IUser';

export type ListUsersWithToken = {
  listUsers?: {
    __typename: 'ModelUserConnection';
    items: Array<IUserWithUserToken | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

const listUsersWithToken = /* GraphQL */ `
  query ListUsersWithToken($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        userToken {
          id
          totalTokenAmount
          spentTokenAmount
          userTokenUserId
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const getAndDispatchAllUsersWithToken = async (dispatch: AppDispatch) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get all users with token ${retry} time(s)`);
    }

    try {
      let nextToken = null;
      const allUsersWithTokens = [];
      do {
        const results = (await API.graphql(
          graphqlOperation(listUsersWithToken, {
            limit: 1000,
            nextToken
          })
        )) as GraphQLResult<ListUsersWithToken>;
        const users = results.data?.listUsers?.items.filter((user) => user && !user._deleted);
        nextToken = results.data?.listUsers?.nextToken;
        if (users && users.length > 0) {
          allUsersWithTokens.push(...users);
        }
      } while (nextToken);

      if (allUsersWithTokens.length > 0) {
        dispatch(
          usersActions.addUsers(
            allUsersWithTokens
              .filter((user) => user !== null)
              .map((user) => {
                return cloneUserWithUserToken(user!);
              })
          )
        );
      }
      return;
    } catch (error) {
      console.log(error);
      retry++;
    }

    if (retry >= 3) {
      throw new Error('Failed to get all users with token');
    }
  }
};
