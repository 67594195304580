export enum AgentLanguage {
  English = 'English',
  Vietnamese = 'Vietnamese'
}

export const getAgentLanguage = (language: string) => {
  const allLanguages = Object.values(AgentLanguage);
  const allLanguagesKey = Object.keys(AgentLanguage);
  const languageIndex = allLanguages.findIndex((lang) => lang.toLowerCase() === language.toLowerCase());
  // return languageIndex >= 0 ? allLanguagesKey[languageIndex] : undefined;
  if (languageIndex >= 0) {
    return allLanguagesKey[languageIndex];
  } else {
    return undefined;
  }
};

export enum AgentType {
  StockInvestmentAdvisor = 'stock_investment_advisor',
  EnglishLearningAssistant = 'english_learning_assistant',
  ResearchAssistant = 'research_assistant',
  GeneralChatBot = 'general_chat_bot'
}

export enum AgentTypeEnglish {
  StockInvestmentAdvisor = 'Stock Investment Advisor',
  EnglishLearningAssistant = 'English Learning Assistant',
  ResearchAssistant = 'Research Assistant',
  GeneralChatBot = 'AI Assistant'
}

export enum AgentTypeVietnamese {
  StockInvestmentAdvisor = 'Nhà đầu tư chứng khoán',
  EnglishLearningAssistant = 'Trợ lý học tiếng Anh',
  ResearchAssistant = 'Trợ lý nghiên cứu',
  GeneralChatBot = 'Trợ lý AI'
}

export const getAgentName = (language: AgentLanguage, agentType: AgentType) => {
  if (language === AgentLanguage.English) {
    switch (agentType) {
      case AgentType.StockInvestmentAdvisor:
        return AgentTypeEnglish.StockInvestmentAdvisor;
      case AgentType.EnglishLearningAssistant:
        return AgentTypeEnglish.EnglishLearningAssistant;
      case AgentType.ResearchAssistant:
        return AgentTypeEnglish.ResearchAssistant;
      case AgentType.GeneralChatBot:
        return AgentTypeEnglish.GeneralChatBot;
    }
  } else {
    switch (agentType) {
      case AgentType.StockInvestmentAdvisor:
        return AgentTypeVietnamese.StockInvestmentAdvisor;
      case AgentType.EnglishLearningAssistant:
        return AgentTypeVietnamese.EnglishLearningAssistant;
      case AgentType.ResearchAssistant:
        return AgentTypeVietnamese.ResearchAssistant;
      case AgentType.GeneralChatBot:
        return AgentTypeVietnamese.GeneralChatBot;
    }
  }

  return undefined;
};

export const getAgentType = (agentType: string, language: string) => {
  const lang = getAgentLanguage(language);
  if (lang) {
    const allAgentTypes = Object.values(AgentType);
    const allAgentTypesKey = Object.keys(AgentType);
    const agentTypeIndex = allAgentTypes.findIndex((type) => type.toLowerCase() === agentType.toLowerCase());
    return agentTypeIndex >= 0 ? allAgentTypesKey[agentTypeIndex] : undefined;
  }
};

export const financialAdvisorPrompt = `MiLu is a renowned and successful stock investor with 20 years of experience in stocks, forex, and bitcoin. He provides top-notch investment advice and only communicates in English. Milu will answer your questions logically and step-by-step.

You: Hello!
`;

export const vietnameseFinancialAdvisorPrompt = `MiLu là một nhà đầu tư chứng khoán nổi tiếng và thành công với 20 năm kinh nghiệm trong các lĩnh vực chứng khoán, forex và bitcoin. Anh ta cung cấp lời khuyên đầu tư tốt nhất và chỉ nói chuyện bằng tiếng Việt. Milu sẽ trả lời bạn một cách logic và có trình tự từng bước.

Bạn: Xin chào!
`;

export const englishLearningAssistantPrompt = `Meet Milu, your personal English language tutor! With a friendly and dedicated approach, Milu provides personalized lessons to help you achieve your language goals. Milu will answer your questions logically and step-by-step. Start chatting with him today and speak English with confidence!

You: Hello!
`;

export const vietnameseLearningAssistantPrompt = `Hãy gặp MiLu, giáo viên tiếng Anh của riêng bạn! Với phương pháp tận tình và chu đáo, MiLu cung cấp các buổi học cá nhân để giúp bạn đạt được mục tiêu học tiếng Anh. Milu sẽ trả lời bạn một cách logic và có trình tự từng bước. Bắt đầu trò chuyện với anh ta ngay hôm nay bằng tiếng Việt.

Bạn: Xin chào!
`;

export const englishResearchAssistantPrompt = `Meet Milu, your expert research assistant. With extensive knowledge and quick analysis, he helps with all your research needs. Get accurate and up-to-date information with his dedication to detail. Milu will answer your questions logically and step-by-step. Start chatting with Milu today.

You: Hello!
`;

export const vietnameseResearchAssistantPrompt = `Gặp MiLu, trợ lý nghiên cứu chuyên nghiệp của bạn. Với kiến thức rộng rãi và phân tích nhanh chóng, anh ấy giúp đỡ mọi nhu cầu nghiên cứu của bạn. Nhận được thông tin chính xác và được cập nhật mới nhất với sự chú ý chi tiết của anh. Milu sẽ trả lời bạn một cách logic và có trình tự từng bước. Bắt đầu trò chuyện với MiLu ngay.

Bạn: Xin chào!
`;

export const englishGeneralChatBotPrompt = `Meet Milu, your AI chat companion. He covers a wide range of interests and holds smooth conversations. Milu will answer your questions logically and step-by-step. Chat with Milu for a delightful and informative experience.

You: Hello!
`;

export const vietnameseGeneralChatBotPrompt = `Gặp Milu, bạn sẽ có một người bạn đồng hành trò chuyện cùng bạn. Với kiến thức đa dạng và năng động trong cuộc trò chuyện, hãy gặp Milu để có những trải nghiệm thú vị và hữu ích. Milu sẽ trả lời bạn một cách logic và có trình tự từng bước.

Bạn: Xin chào!
`;

export const english_template_human = (message: string) => `You: ${message}\n`;
export const english_template_ai = (message: string) => `Milu: ${message}\n`;

export const vietnamese_template_human = (message: string) => `Bạn: ${message}\n`;

export const vietnamese_template_ai = (message: string) => `Milu: ${message}\n`;

export const template_human = (language: AgentLanguage, message: string) => {
  switch (language) {
    case AgentLanguage.English:
      return english_template_human(message);
    case AgentLanguage.Vietnamese:
      return vietnamese_template_human(message);
    default:
      return english_template_human(message);
  }
};

export const template_ai = (language: AgentLanguage, message: string) => {
  switch (language) {
    case AgentLanguage.English:
      return english_template_ai(message);
    case AgentLanguage.Vietnamese:
      return vietnamese_template_ai(message);
    default:
      return english_template_ai(message);
  }
};

export const getPrompt = (language: AgentLanguage, agentType: AgentType) => {
  switch (language) {
    case AgentLanguage.English:
      switch (agentType) {
        case AgentType.StockInvestmentAdvisor:
          return financialAdvisorPrompt;
        case AgentType.EnglishLearningAssistant:
          return englishLearningAssistantPrompt;
        case AgentType.ResearchAssistant:
          return englishResearchAssistantPrompt;
        case AgentType.GeneralChatBot:
          return englishGeneralChatBotPrompt;
        default:
          return englishGeneralChatBotPrompt;
      }
    case AgentLanguage.Vietnamese:
      switch (agentType) {
        case AgentType.StockInvestmentAdvisor:
          return vietnameseFinancialAdvisorPrompt;
        case AgentType.EnglishLearningAssistant:
          return vietnameseLearningAssistantPrompt;
        case AgentType.ResearchAssistant:
          return vietnameseResearchAssistantPrompt;
        case AgentType.GeneralChatBot:
          return vietnameseGeneralChatBotPrompt;
        default:
          return vietnameseGeneralChatBotPrompt;
      }
    default:
      return englishGeneralChatBotPrompt;
  }
};
