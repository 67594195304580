import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ISideMenuItem {
  icon: string;
  title: string;
  pathname: string;
  active?: boolean;
  activeDropdown?: boolean;
  type?: string;
  subMenu?: ISideMenuItem[];
}

interface ISideMenuState {
  menu: ISideMenuItem[];
}

const initialValue: ISideMenuState = {
  menu: [
    {
      icon: 'MessageSquare',
      title: 'Chat',
      pathname: '/'
    },
    {
      icon: 'Briefcase',
      title: 'Top Up',
      pathname: '/product'
    }
    // {
    //   icon: 'Edit',
    //   title: 'Paraphrase',
    //   pathname: '/paraphrase'
    // }
  ]
};

const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState: initialValue,
  reducers: {
    resetSideMenu: () => {
      return initialValue;
    },
    adjustAdminView: (state: ISideMenuState, action: PayloadAction<ISideMenuItem>) => {
      // If menu does not contain the admin page, add it
      if (!state.menu.find((item) => item.pathname === action.payload.pathname)) {
        state.menu.push(action.payload);
      }
    }
  }
});

export const sideMenuActions = sideMenuSlice.actions;
export default sideMenuSlice;
