import { createElement, createRef, useEffect } from 'react';
import tippy, { animateFill, roundArrow } from 'tippy.js';
import PropTypes from 'prop-types';

const init = (el, props) => {
  tippy(el, {
    plugins: [animateFill],
    content: props.content,
    arrow: roundArrow,
    popperOptions: {
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            rootBoundary: 'viewport'
          }
        }
      ]
    },
    animateFill: false,
    animation: 'shift-away',
    ...props.options
  });
};

const Tippy = (props) => {
  const tippyRef = createRef();

  useEffect(() => {
    props.getRef(tippyRef.current);
    init(tippyRef.current, props);
  }, [props, props.content, tippyRef]);

  const { content, tag, options, getRef, ...computedProps } = props;
  return createElement(
    props.tag,
    {
      ...computedProps,
      ref: tippyRef
    },
    props.children
  );
};

Tippy.propTypes = {
  content: PropTypes.string,
  tag: PropTypes.string,
  href: PropTypes.string,
  options: PropTypes.object,
  getRef: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  alt: PropTypes.string,
  src: PropTypes.string
};

Tippy.defaultProps = {
  content: '',
  tag: 'span',
  options: {},
  getRef: () => {
    return null;
  },
  className: '',
  children: null,
  onClick: () => {
    return null;
  }
};

export default Tippy;
