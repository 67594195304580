/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTokenKey = /* GraphQL */ `
  mutation CreateTokenKey(
    $input: CreateTokenKeyInput!
    $condition: ModelTokenKeyConditionInput
  ) {
    createTokenKey(input: $input, condition: $condition) {
      id
      tokenAmount
      isUsed
      giftCard {
        id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        giftCardTokenKeyId
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tokenKeyGiftCardId
    }
  }
`;
export const updateTokenKey = /* GraphQL */ `
  mutation UpdateTokenKey(
    $input: UpdateTokenKeyInput!
    $condition: ModelTokenKeyConditionInput
  ) {
    updateTokenKey(input: $input, condition: $condition) {
      id
      tokenAmount
      isUsed
      giftCard {
        id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        giftCardTokenKeyId
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tokenKeyGiftCardId
    }
  }
`;
export const deleteTokenKey = /* GraphQL */ `
  mutation DeleteTokenKey(
    $input: DeleteTokenKeyInput!
    $condition: ModelTokenKeyConditionInput
  ) {
    deleteTokenKey(input: $input, condition: $condition) {
      id
      tokenAmount
      isUsed
      giftCard {
        id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        giftCardTokenKeyId
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      tokenKeyGiftCardId
    }
  }
`;
export const createChatRoom = /* GraphQL */ `
  mutation CreateChatRoom(
    $input: CreateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    createChatRoom(input: $input, condition: $condition) {
      id
      chatRoomName
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      messages {
        nextToken
        startedAt
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageTimestamp
      lastMessageText
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChatRoom = /* GraphQL */ `
  mutation UpdateChatRoom(
    $input: UpdateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    updateChatRoom(input: $input, condition: $condition) {
      id
      chatRoomName
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      messages {
        nextToken
        startedAt
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageTimestamp
      lastMessageText
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteChatRoom = /* GraphQL */ `
  mutation DeleteChatRoom(
    $input: DeleteChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    deleteChatRoom(input: $input, condition: $condition) {
      id
      chatRoomName
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      messages {
        nextToken
        startedAt
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      lastMessageTimestamp
      lastMessageText
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserToken = /* GraphQL */ `
  mutation CreateUserToken(
    $input: CreateUserTokenInput!
    $condition: ModelUserTokenConditionInput
  ) {
    createUserToken(input: $input, condition: $condition) {
      id
      totalTokenAmount
      spentTokenAmount
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      userTokenUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateUserToken = /* GraphQL */ `
  mutation UpdateUserToken(
    $input: UpdateUserTokenInput!
    $condition: ModelUserTokenConditionInput
  ) {
    updateUserToken(input: $input, condition: $condition) {
      id
      totalTokenAmount
      spentTokenAmount
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      userTokenUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteUserToken = /* GraphQL */ `
  mutation DeleteUserToken(
    $input: DeleteUserTokenInput!
    $condition: ModelUserTokenConditionInput
  ) {
    deleteUserToken(input: $input, condition: $condition) {
      id
      totalTokenAmount
      spentTokenAmount
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      userTokenUserId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createGiftCard = /* GraphQL */ `
  mutation CreateGiftCard(
    $input: CreateGiftCardInput!
    $condition: ModelGiftCardConditionInput
  ) {
    createGiftCard(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      tokenKey {
        id
        tokenAmount
        isUsed
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tokenKeyGiftCardId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      giftCardTokenKeyId
    }
  }
`;
export const updateGiftCard = /* GraphQL */ `
  mutation UpdateGiftCard(
    $input: UpdateGiftCardInput!
    $condition: ModelGiftCardConditionInput
  ) {
    updateGiftCard(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      tokenKey {
        id
        tokenAmount
        isUsed
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tokenKeyGiftCardId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      giftCardTokenKeyId
    }
  }
`;
export const deleteGiftCard = /* GraphQL */ `
  mutation DeleteGiftCard(
    $input: DeleteGiftCardInput!
    $condition: ModelGiftCardConditionInput
  ) {
    deleteGiftCard(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      tokenKey {
        id
        tokenAmount
        isUsed
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        tokenKeyGiftCardId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      giftCardTokenKeyId
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      amountInVND
      tokenAmount
      channel
      note
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      amountInVND
      tokenAmount
      channel
      note
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      amountInVND
      tokenAmount
      channel
      note
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      text
      timestamp
      promptTokens
      completionTokens
      isDefaultMessage
      ownerUserID
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      chatRoomID
      chatRoom {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      text
      timestamp
      promptTokens
      completionTokens
      isDefaultMessage
      ownerUserID
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      chatRoomID
      chatRoom {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      text
      timestamp
      promptTokens
      completionTokens
      isDefaultMessage
      ownerUserID
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserTokenId
        owner
      }
      chatRoomID
      chatRoom {
        id
        chatRoomName
        userID
        agentID
        lastMessageTimestamp
        lastMessageText
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phoneNumber
      messages {
        nextToken
        startedAt
      }
      chatRooms {
        nextToken
        startedAt
      }
      userToken {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      giftCards {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      tokenKeys {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserTokenId
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phoneNumber
      messages {
        nextToken
        startedAt
      }
      chatRooms {
        nextToken
        startedAt
      }
      userToken {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      giftCards {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      tokenKeys {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserTokenId
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phoneNumber
      messages {
        nextToken
        startedAt
      }
      chatRooms {
        nextToken
        startedAt
      }
      userToken {
        id
        totalTokenAmount
        spentTokenAmount
        userTokenUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      giftCards {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      tokenKeys {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserTokenId
      owner
    }
  }
`;
export const createAgent = /* GraphQL */ `
  mutation CreateAgent(
    $input: CreateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    createAgent(input: $input, condition: $condition) {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAgent = /* GraphQL */ `
  mutation UpdateAgent(
    $input: UpdateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    updateAgent(input: $input, condition: $condition) {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAgent = /* GraphQL */ `
  mutation DeleteAgent(
    $input: DeleteAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    deleteAgent(input: $input, condition: $condition) {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
