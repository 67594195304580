// React Router
import { useRoutes } from 'react-router-dom';
import SideMenu from '../layouts/SideMenu';
import ErrorPage from '../views/ErrorPage';
import ChatPage from '../views/ChatPage';
import SignInPage from '../views/SignInPage';
import CodeVerification from '../views/CodeVerification';
import SignUpPage from '../views/SignUpPage';
import ProfilePage from '@/views/ProfilePage';
import AdminTokenPage from '@/views/AdminTokenPage';
import ResetPasswordPage from '@/views/ResetPasswordPage';
import ProductPage from '@/views/ProductPage';
import CheckOutPage from '@/views/CheckOutPage';
import React from 'react';
import ParaphrasePage from '@/views/ParaphrasePage';
import AboutPage from '@/views/AboutPage';
import UserGuidePage from '@/views/UserGuidePage';
import PaymentPolicyPage from '@/views/PaymentPolicyPage';
import TermsAndConditionsPage from '@/views/TermsAndConditionsPage';
import PrivacyPolicyPage from '@/views/PrivacyPolicyPage';
import MobileMenu from '@/components/MobileMenu';
import ForgotPasswordPage from '@/views/ForgotPasswordPage';

const Router = () => {
  const routes = [
    {
      path: '/',
      element: <SideMenu />,
      children: [
        {
          path: '/',
          element: <ChatPage />
        },
        {
          path: 'chat',
          element: <MobileMenu />
        },
        {
          path: 'user/profile',
          element: <ProfilePage />
        },
        {
          path: 'admin/token',
          element: <AdminTokenPage />
        },
        {
          path: 'product',
          element: <ProductPage />
        },
        {
          path: 'checkout',
          element: <CheckOutPage />
        },
        {
          path: 'paraphrase',
          element: <ParaphrasePage />
        }
      ]
    },
    {
      path: '/auth/signin',
      element: <SignInPage />
    },
    {
      path: '/auth/signup',
      element: <SignUpPage />
    },
    {
      path: '/auth/code-verification',
      element: <CodeVerification />
    },
    {
      path: '/auth/reset-password',
      element: <ResetPasswordPage />
    },
    {
      path: '/auth/forgot-password',
      element: <ForgotPasswordPage />
    },
    {
      path: '/error-page',
      element: <ErrorPage />
    },
    {
      path: '*',
      element: <ErrorPage />
    },
    {
      path: 'about',
      element: <AboutPage />
    },
    {
      path: 'payment-policy',
      element: <PaymentPolicyPage />
    },
    {
      path: 'terms-and-conditions',
      element: <TermsAndConditionsPage />
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicyPage />
    },
    {
      path: 'user-guide',
      element: <UserGuidePage />
    }
  ];

  return useRoutes(routes);
};

export default Router;
