import { useAppSelector } from '@/stores/redux/hooks';
import { selectAllCartItems } from '@/stores/redux/cart/cart-slice';
import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { Modal, ModalBody, ModalHeader } from '@/base-components/modal';
import { Link } from 'react-router-dom';

export default function CheckOutPage() {
  const cartItems = useAppSelector((state) => selectAllCartItems(state));
  const { user } = useAuth();
  const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(true);
  const cart = useAppSelector((state) => state.cart);
  const [discount, setDiscount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('Internet Banking');
  const [currency, setCurrency] = useState('VND');

  const handleDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscount(parseInt(e.target.value));
  };

  const handleDiscountClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    console.log('Discount clicked');
  };

  const handlePayClick = async () => {
    if (!user) {
      console.warn('User is not logged in, this should not happen');
      return;
    }
    console.log('Adding ' + cart.totalQuantity + ' tokens to user ' + user.id);

    try {
      const response = await axios.post(
        `/api/v1/chatbot_user_token/add_token`,
        {
          userID: user.id,
          tokenAmount: cart.totalQuantity
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.accessToken}`
          }
        }
      );

      if (response.status === 201) {
        console.log('Token added successfully');
      } else {
        console.error('Error adding token: ' + response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      {/* BEGIN: Modal Content */}
      <Modal
        size='modal-lg'
        show={headerFooterModalPreview}
        onHidden={() => {
          setHeaderFooterModalPreview(false);
        }}>
        <ModalHeader>
          <h2 className='mr-auto text-base font-medium'>Giỏ hàng</h2>
        </ModalHeader>
        <ModalBody>
          {user?.id ? (
            <div className='flex flex-col items-center justify-center'>
              <p>Để nạp Intelium cho tài khoản, vui lòng truy cập vào link sau và làm theo hướng dẫn.</p>
              <Link to='/user-guide'>
                <p className='mt-2 text-emerald-500 hover:text-emerald-700'>Hướng dẫn nạp Intelium</p>
              </Link>
            </div>
          ) : (
            // <TopUpTokenForm userID={modalUserID} setHeaderFooterModalPreview={setHeaderFooterModalPreview}
            //                 cartItems={cartItems} cart={cart} />
            // Loading animation
            <div className='flex items-center justify-center'>
              <svg className='-ml-1 mr-3 h-5 w-5 animate-spin text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1zm0 0h1v1a7 7 0 007 7v1a8 8 0 01-8-8zm0 0v-1a7 7 0 017-7v1a8 8 0 00-8 8zm0 0a8 8 0 008 8v-1a7 7 0 00-7-7h-1z'></path>
              </svg>
              <p className='text-gray-700'>Đang tải...</p>
            </div>
          )}
        </ModalBody>
      </Modal>
      {/* Background color split screen for large screens */}
      <div className='fixed top-0 left-0 hidden h-full w-1/2 bg-gray-50 dark:bg-darkmode-900 lg:block' aria-hidden='true' />
      <div className='fixed top-0 right-0 hidden h-full w-1/2 bg-primary dark:bg-darkmode-900 lg:block' aria-hidden='true' />

      <div className='relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 lg:pt-16 dark:bg-darkmode-700'>
        <h1 className='sr-only'>Thanh toán</h1>

        <section
          aria-labelledby='payment-and-shipping-heading'
          className='py-16 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:pt-0 lg:pb-24'>
          <h2 id='payment-and-shipping-heading' className='sr-only'>
            Payment Details
          </h2>

          <div className='mx-auto max-w-2xl px-4 lg:max-w-none lg:pr-20'>
            <div>
              <h3 id='contact-info-heading' className='text-lg font-medium text-gray-900 dark:text-gray-100'>
                Cách thức thanh toán
              </h3>

              <div className='mt-6'>
                <div className='mt-1'>
                  <select
                    id='payment-method'
                    name='payment-method'
                    className='block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm dark:bg-darkmode-800 dark:border-darkmode-700 dark:text-slate-400'
                    onChange={(e) => setPaymentMethod(e.target.value)}>
                    <option>Internet Banking</option>
                    <option>Credit/ Debit Card</option>
                    <option>Thẻ điện thoại</option>
                  </select>
                </div>
              </div>
            </div>

            <div className='mt-10'>
              <h3 className='text-lg font-medium text-gray-900 dark:text-gray-100'>Thông tin thanh toán</h3>
              {paymentMethod === 'Credit/ Debit Card' ? (
                <div className='mt-6 grid grid-cols-3 gap-y-6 gap-x-4 sm:grid-cols-4'>
                  <div className='col-span-3 sm:col-span-4'>
                    <label htmlFor='card-number' className='block text-sm font-medium text-gray-700 dark:text-gray-100'>
                      Số thẻ
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        id='card-number'
                        name='card-number'
                        autoComplete='cc-number'
                        className='block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm dark:bg-darkmode-800 dark:border-darkmode-700 dark:text-slate-400'
                      />
                    </div>
                  </div>

                  <div className='col-span-2 sm:col-span-3'>
                    <label htmlFor='expiration-date' className='block text-sm font-medium text-gray-700 dark:text-gray-100'>
                      Ngày hết hạn thẻ (MM/YY)
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        name='expiration-date'
                        id='expiration-date'
                        autoComplete='cc-exp'
                        className='block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm dark:bg-darkmode-800 dark:border-darkmode-700 dark:text-slate-400'
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor='cvc' className='block text-sm font-medium text-gray-700 dark:text-gray-100'>
                      CVC
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        name='cvc'
                        id='cvc'
                        autoComplete='csc'
                        className='block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm dark:bg-darkmode-800 dark:border-darkmode-700 dark:text-slate-400'
                      />
                    </div>
                  </div>
                </div>
              ) : paymentMethod === 'Thẻ điện thoại' ? (
                <div className='mt-6 grid grid-cols-3 gap-y-6 gap-x-4 sm:grid-cols-4'>
                  <div className='col-span-3 sm:col-span-4'>
                    <label htmlFor='card-number' className='block text-sm font-medium text-gray-700 dark:text-gray-100'>
                      Tên nhà mạng
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        id='card-number'
                        name='card-number'
                        autoComplete='cc-number'
                        className='block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm dark:bg-darkmode-800 dark:border-darkmode-700 dark:text-slate-400'
                      />
                    </div>
                  </div>

                  <div className='col-span-3 sm:col-span-4'>
                    <label htmlFor='card-number' className='block text-sm font-medium text-gray-700 dark:text-gray-100'>
                      Số thẻ
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        id='card-number'
                        name='card-number'
                        autoComplete='cc-number'
                        className='block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm dark:bg-darkmode-800 dark:border-darkmode-700 dark:text-slate-400'
                      />
                    </div>
                  </div>

                  <div className='col-span-2 sm:col-span-3'>
                    <label htmlFor='expiration-date' className='block text-sm font-medium text-gray-700 dark:text-gray-100'>
                      Giá trị thẻ
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        name='card-value'
                        id='card-value'
                        autoComplete='cc-exp'
                        className='block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm dark:bg-darkmode-800 dark:border-darkmode-700 dark:text-slate-400'
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className='mt-6 grid grid-cols-3 gap-y-6 gap-x-4 sm:grid-cols-4'>
                  <div className='col-span-3 sm:col-span-4'>
                    <label htmlFor='card-number' className='block text-sm font-medium text-gray-700 dark:text-gray-100'>
                      Tên ngân hàng
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        id='card-number'
                        name='card-number'
                        autoComplete='cc-number'
                        className='block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm dark:bg-darkmode-800 dark:border-darkmode-700 dark:text-slate-400'
                      />
                    </div>
                  </div>
                  <div className='col-span-3 sm:col-span-4'>
                    <label htmlFor='card-number' className='block text-sm font-medium text-gray-700 dark:text-gray-100'>
                      Số tài khoản
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        id='card-number'
                        name='card-number'
                        autoComplete='cc-number'
                        className='block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm dark:bg-darkmode-800 dark:border-darkmode-700 dark:text-slate-400'
                      />
                    </div>
                  </div>
                  <div className='col-span-2 sm:col-span-3'>
                    <label htmlFor='expiration-date' className='block text-sm font-medium text-gray-700 dark:text-gray-100'>
                      Số tiền
                    </label>
                    <div className='mt-1'>
                      <input
                        type='text'
                        name='card-value'
                        id='card-value'
                        className='block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm dark:bg-darkmode-800 dark:border-darkmode-700 dark:text-slate-400'
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor='cvc' className='block text-sm font-medium text-gray-700 dark:text-gray-100'>
                      Loại tiền tệ
                    </label>
                    <div className='mt-1'>
                      <select
                        className='block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm dark:bg-darkmode-800 dark:border-darkmode-700 dark:text-slate-400'
                        onChange={(e) => setCurrency(e.target.value)}>
                        <option value='VND'>VND</option>
                        <option value='USD'>USD</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
              <div className='mt-6 flex items-center'>
                <input
                  id='same-as-shipping'
                  name='same-as-shipping'
                  type='checkbox'
                  defaultChecked
                  className='h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary dark:border-darkmode-700 dark:bg-slate-100'
                />
                <div className='ml-2'>
                  <label htmlFor='same-as-shipping' className='text-sm font-medium text-gray-900 dark:text-gray-100'>
                    Lưu thông tin thanh toán
                  </label>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          aria-labelledby='summary-heading'
          className='bg-primary py-12 text-white md:px-10 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:bg-transparent lg:px-0 lg:pt-0 lg:pb-24'>
          <div className='mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0'>
            <h2 id='summary-heading' className='sr-only'>
              Thông tin đơn hàng
            </h2>

            {/*<div className='border-b border-gray-200 pb-8'>*/}
            {/*  <h3 className='text-5xl font-medium text-white'>One more step to own your own Milu!</h3>*/}
            {/*  <p className='mt-5 text-xs font-light text-white'>Milu is a smart personal assistant that helps you conquer every tasks hassle-free.</p>*/}
            {/*  <p className='mt-1 text-xs font-light text-white'>*/}
            {/*    Make the best use out of your time and money by using Milu AI. We are here to help you. If you have any questions, please contact us.*/}
            {/*    Our devoted customer service team is always ready to help you.*/}
            {/*  </p>*/}
            {/*</div>*/}

            <div className='flex items-center justify-between'>
              <dt className='text-lg font-medium'>Số tiền cần thanh toán</dt>
              <dd className='mt-1 text-3xl font-bold tracking-tight text-white'>
                {cartItems.reduce((acc, item) => acc + item.pricePerUnit * item.quantity, 0)} {currency}
              </dd>
            </div>

            <ul role='list' className='divide-y divide-white divide-opacity-10 text-sm font-medium'>
              {cartItems.map((item) => (
                <li key={item.id} className='flex items-start space-x-4 py-6'>
                  <img
                    src={item.product?.imageUrl}
                    alt={item.product?.description}
                    className='h-20 w-20 flex-none rounded-md object-cover object-center'
                  />
                  <div className='flex-auto space-y-1'>
                    <h3 className='text-white'>{item.product?.name}</h3>
                  </div>
                  <div className='h-20 flex-col justify-between whitespace-nowrap text-right text-sm font-medium'>
                    <p className='flex-none'>{item.product?.pricePerUnit}</p>
                    <p className='mt-10 flex-none'>x {item.quantity}</p>
                  </div>
                </li>
              ))}
            </ul>

            <dl className='space-y-6 border-t border-white border-opacity-10 pt-6 text-sm font-medium'>
              <div className='flex items-center justify-between'>
                <dt>Tổng tiền</dt>
                <dd>
                  {cartItems.reduce((acc, item) => {
                    return acc + item.quantity * item.product?.pricePerUnit;
                  }, 0)}
                </dd>
              </div>

              <div className='flex items-center justify-between'>
                <div className='box mt-5 flex w-full p-2'>
                  <input
                    type='text'
                    className='form-control w-full border-slate-200/60 bg-slate-100 py-3 px-4 pr-10'
                    placeholder='Use coupon code...'
                    name='discount'
                    onChange={handleDiscountChange}
                  />
                  <button className='btn btn-primary ml-2 w-24' onClick={handleDiscountClick}>
                    Áp dụng
                  </button>
                </div>
              </div>

              <div className='flex items-center justify-between'>
                <dt>Giảm giá</dt>
                <dd>- {discount}</dd>
              </div>

              {/*<div className='flex items-center justify-between'>*/}
              {/*  <dt>Thuế</dt>*/}
              {/*  <dd>*/}
              {/*    {cartItems.reduce((acc, item) => {*/}
              {/*      return acc + item.quantity * item.product?.pricePerUnit;*/}
              {/*    }, 0) * 0.1}{' '}*/}
              {/*    {currency}*/}
              {/*  </dd>*/}
              {/*</div>*/}

              <div className='flex items-center justify-between border-t border-white border-opacity-10 pt-6 text-white'>
                <dt className='text-base'>Tổng cộng</dt>
                <dd className='text-base'>
                  {cartItems.reduce((acc, item) => {
                    return acc + item.quantity * item.product?.pricePerUnit;
                  }, 0) *
                    1.1 -
                    discount}{' '}
                  {currency}
                </dd>
              </div>
            </dl>
          </div>

          <div className='mt-10 flex justify-center md:justify-end border-t border-gray-200 pt-6 sm:visible sm:flex'>
            <a
              href='#_'
              onClick={() => setHeaderFooterModalPreview(true)}
              className='group relative inline-flex items-center justify-center overflow-hidden rounded-lg p-4 px-5 py-3 font-medium text-indigo-600 shadow-2xl dark:text-indigo-400 hover:bg-slate-300 dark:hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
              <span className='ease absolute top-0 left-0 -mt-10 -ml-3 h-40 w-40 rounded-full bg-red-500 blur-md transition-all duration-700 dark:bg-red-400 group-hover:opacity-0 group-hover:scale-150'></span>
              <span className='ease absolute inset-0 h-full w-full transition duration-700 group-hover:rotate-180'>
                <span className='absolute bottom-0 left-0 -ml-10 h-24 w-24 rounded-full bg-purple-500 blur-md'></span>
                <span className='absolute bottom-0 right-0 -mr-10 h-24 w-24 rounded-full bg-pink-500 blur-md'></span>
              </span>
              <span className='relative text-white'>Thanh toán ngay</span>
            </a>
          </div>
        </section>
      </div>
    </Fragment>
  );
}
