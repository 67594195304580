import { User } from '../API';
import { cloneObject } from '../utils/object_clone';
import { IUserToken } from '@/types/IUserToken';
import * as _ from 'lodash';
import { keys } from 'ts-transformer-keys';

// IUser type is extended from User type, omitting __typename, createdAt, updatedAt, _version, _deleted, _lastChangedAt and owner fields
export type IUser = Omit<
  User,
  | 'updatedAt'
  | 'createdAt'
  | 'owner'
  | '_version'
  | '_deleted'
  | '_lastChangedAt'
  | '__typename'
  | 'messages'
  | 'chatRooms'
  | 'giftCards'
  | 'payments'
  | 'tokenKeys'
  | 'userToken'
> & {
  userToken?: IUserToken;
};

export type IUserWithUserToken = {
  __typename: 'User';
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  userUserTokenId?: string | null;
  owner?: string | null;
  userToken: {
    id: string;
    totalTokenAmount: number;
    spentTokenAmount: number;
    userTokenUserId: string;
  };
};

export const cloneUser = (user: User): IUser => {
  return cloneObject<User, IUser>(user, keys<User>(), keys<IUser>(), ['userToken']);
};

export const cloneUserWithUserToken = (user: IUserWithUserToken): IUser => {
  const newUser = cloneObject<IUserWithUserToken, IUser>(user, keys<IUserWithUserToken>(), keys<IUser>(), ['userToken']);
  newUser.userToken = _.cloneDeep(user.userToken);
  return newUser;
};
