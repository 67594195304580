import { Fragment } from 'react';
import { DropDown, DropdownContent, DropdownItem, DropdownMenu, DropdownToggle } from '../../base-components/DropDown';
import Lucide from '../../base-components/Lucide';
import { friendlyTimestamp } from '../../utils/friendly_timestamp';
import { IMessage } from '@/types/IMessage';

interface IHumanMessageProps {
  message: IMessage;
  photo: string;
}

const HumanMessage = ({ message, photo }: IHumanMessageProps) => {
  return (
    <Fragment>
      <div className='chat__box__text-box float-right mb-4 flex items-end'>
        <DropDown className='my-auto mr-3 hidden sm:block'>
          <DropdownToggle tag='a' href='#' className='h-4 w-4 text-slate-500'>
            <Lucide icon='MoreVertical' className='h-4 w-4' />
          </DropdownToggle>
          <DropdownMenu className='w-40'>
            <DropdownContent>
              <DropdownItem>
                <Lucide icon='CornerUpLeft' className='mr-2 h-4 w-4' />
                Reply
              </DropdownItem>
              <DropdownItem>
                <Lucide icon='Trash' className='mr-2 h-4 w-4' /> Delete
              </DropdownItem>
            </DropdownContent>
          </DropdownMenu>
        </DropDown>
        <div className='rounded-l-md rounded-t-md bg-primary px-4 py-3 text-white'>
          {message.text}
          <div className='mt-1 text-xs text-white text-opacity-80'>{friendlyTimestamp(message.timestamp)}</div>
        </div>
        <div className='image-fit relative ml-5 hidden h-10 w-10 flex-none sm:block'>
          <img alt='Milu chatbot' className='rounded-full' src={photo} />
        </div>
      </div>
      <div className='clear-both'></div>
    </Fragment>
  );
};

export default HumanMessage;
