import { createRoot } from 'react-dom/client';
import './assets/css/app.css';
import App from './App';
import './configureAmplify';
import './i18n';

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);

root.render(<App />);
