import dom from '@left4code/tw-starter/dist/js/dom';
import { ReactComponent as IllustrationImg } from '@/assets/images/illustration.svg';
import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import Lucide from '../base-components/Lucide';
import { useAuth } from '@/context/AmplifyAuthProvider';
import LoadingIcon from '../base-components/LoadingIcon';
import logoImg from '@/assets/images/logo-milu.png';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const { isSignedIn, signIn, successMessage, errorMessage, setSuccessMessage, setErrorMessage } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Reset errorMessage and successMessage on unmount
  useEffect(() => {
    return () => {
      setErrorMessage('');
      setSuccessMessage('');
    };
  }, [setErrorMessage, setSuccessMessage]);

  // Navigate to code verification page if needed
  useEffect(() => {
    if (errorMessage === 'Bạn phải nhập đúng email để lấy lại mật khẩu' && email) {
      navigate('/auth/signin', {
        state: {
          email: email
        }
      });
    }
  }, [email, errorMessage, navigate, password]);

  useEffect(() => {
    dom('body').removeClass('main').removeClass('error-page').addClass('login');
  }, []);

  const initialValues = {
    email: ''
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email không hợp lệ').required('Email không được để trống')
  });

  const handleSubmit = async (values: any) => {
    const { email } = values;

    if (!email) {
      return;
    }

    setEmail(email);
    setPassword(password);
  };

  return (
    <div>
      <div className='container sm:px-10'>
        <div className='block grid-cols-2 gap-4 xl:grid'>
          {/* BEGIN: Info */}
          <div className='hidden min-h-screen flex-col xl:flex'>
            <a href='/auth/forgot-password' className='-intro-x flex items-center pt-5'>
              <img src={logoImg} alt='Milu chatbot' className='h-12 w-12' />
              <span className='logo__text ml-1 text-2xl font-extrabold text-white'>Milu</span>
              <span className='text-2xl font-light text-white'> AI</span>
            </a>
            <div className='my-auto'>
              <IllustrationImg className='-intro-x -mt-16 w-1/2' />
              <div className='-intro-x mt-10 text-4xl font-medium leading-tight text-white'>Bạn đã quên mật khẩu?</div>
              <div className='-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-slate-400'>
                Hãy làm theo hướng dẫn để lấy lại mật khẩu ngay!
              </div>
            </div>
          </div>
          {/* END: Info */}
          {/* BEGIN: Form */}
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting }) => (
              <Form className='my-10 flex h-screen py-5 xl:my-0 xl:h-auto xl:py-0'>
                <div className='m-auto w-full rounded-md bg-white py-8 px-5 shadow-md dark:bg-darkmode-600 sm:w-3/4 sm:px-8 lg:w-2/4 xl:ml-20 xl:w-auto xl:bg-transparent xl:p-0 xl:shadow-none'>
                  <h2 className='intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl'>Nhập email để lấy lại mật khẩu</h2>
                  {/*BEGIN: Success or error message. Success message in green, error message in red*/}
                  {successMessage && (
                    <div className='mt-4 flex items-center text-center text-green-500'>
                      <Lucide icon='CheckCircle' className='mr-2 text-green-500' />
                      <div className='text-lg'>{successMessage}</div>
                    </div>
                  )}
                  {errorMessage && (
                    <div className='mt-4 flex items-center text-center text-red-500'>
                      <Lucide icon='XCircle' className='mr-2 text-red-500' />
                      <div className='text-lg'>{errorMessage}</div>
                    </div>
                  )}
                  {/* END: Success or error message */}
                  <div className='intro-x mt-2 text-center text-slate-400 xl:hidden'></div>
                  <div className='intro-x mt-8'>
                    <Field type='email' name='email' className='intro-x login__input form-control block py-3 px-4' placeholder='Email' />
                    <ErrorMessage name='email'>{(msg: string) => <span className='block py-1 px-2 text-red-600'>{msg}</span>}</ErrorMessage>
                  </div>
                  <div className='intro-x mt-5 text-center xl:mt-8 xl:text-left'>
                    {!isSubmitting ? (
                      <button type='submit' className='btn btn-primary py-3 px-4 align-top xl:mr-3'>
                        Lấy lại mật khẩu
                      </button>
                    ) : (
                      <button type='submit' className='btn btn-outline-primary w-full py-3 px-4 align-top xl:mr-3 xl:w-32' disabled>
                        <LoadingIcon icon='spinning-circles' className='mr-1 h-5 w-5 -translate-x-4' />{' '}
                        <span className='-translate-x-3'>Đang gửi email...</span>
                      </button>
                    )}
                    {/*Navigate to /auth/signup to create an account*/}
                    <Link to='/auth/signin'>
                      <button type='button' className='btn btn-outline-secondary mt-3 w-full py-3 px-4 align-top xl:mt-0 xl:w-32'>
                        Đăng nhập
                      </button>
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          {/* END: Login Form */}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
