import React, { Fragment } from 'react';
import TopBar from '@/components/TopBar';
import Footer from '@/components/Footer';

export default function TermsAndConditionsPage() {
  return (
    <Fragment>
      <TopBar />
      <div className='bg-white'>
        <div className='relative overflow-hidden'>
          <main>
            {/* Feature section with screenshot */}
            <div className='relative overflow-hidden bg-gray-900 pt-16'>
              <div aria-hidden='true' className='absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-900' />
              <div className='relative'>
                <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
                  <div>
                    <div className='my-6'>
                      <span className='block bg-gradient-to-r from-teal-200 to-cyan-400  bg-clip-text pt-16 text-4xl font-bold tracking-tight text-transparent'>
                        Điều khoản Sử dụng
                      </span>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        Chào mừng bạn đã tham gia cộng đồng chủ trợ lý AI Milu từ Promete, chúng tôi hy vọng bạn sẽ có những trải nghiệm tuyệt vời
                        nhất với trợ lý Milu!
                      </p>
                      <p className='mt-4 text-lg font-light text-white'>
                        Milu là trang web tích hợp trợ lý ảo ở Việt Nam cho phép người dung sử dụng trong các tác vụ hằng ngày. Milu vừa có thể là trợ
                        lý giảng dạy, tư vấn tài chính, vừa có thể giải đáp những thắc mắc mà người dùng đưa ra. Để sử dụng Milu, người dùng cần sở
                        hữu Intelium – một loại tiền tệ ảo chỉ sử dụng trong các dịch vụ của Promete tương đương với tiền.
                        <br />
                        Những điều kiện và điều khoản sử dụng sau đây được áp dụng đối với trang web của chúng tôi https://milu.promete.ai/ và các
                        thiết bị liên quan. Khi bạn quyết định sử dụng dịch vụ của chúng tôi, xin lưu ý rằng bạn đã đồng ý chấp nhận với mọi điều
                        khoản mà chúng tôi liệt kê dưới đây.
                        <br />
                        Vui lòng đọc kỹ các Điều khoản sử dụng dưới đây trước khi sử dụng Milu (“Trang Web”) hoặc mở 1 tài khoản trên
                        https://milu.promete.ai/ (“Tài Khoản”) nhằm giúp bạn nắm được các quyền lợi và nghĩa vụ theo pháp luật của bạn (sau đây gọi
                        tắt là “Milu”, hoặc “chúng tôi”).
                        <br />
                        “Dịch Vụ” chúng tôi cung cấp bao gồm (a) Trang Web, và (b) tất cả thông tin, link trang web, tính năng, dữ liệu, chữ, hình
                        ảnh, ảnh, đồ họa, âm nhạc, âm thanh, video, tin nhắn, nhãn mác, nội dung, lập trình, phần mềm, dịch vụ ứng dụng (bao gồm,
                        nhưng không chỉ, bất cứ dịch vụ ứng dụng di động nào) hoặc các tư liệu được cung cấp qua Trang Web hoặc các dịch vụ liên quan
                        (“Nội Dung”). Bất cứ tính năng mới nào được vào hoặc điều chỉnh trên Dịch Vụ đều được áp dụng các Điều Khoản Sử Dụng này. Các
                        Điều Khoản Sử Dụng này sẽ áp dụng vào việc sử dụng Dịch Vụ cung cấp bởi MILU của bạn.
                        <br />
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        QUA VIỆC SỬ DỤNG DỊCH VỤ HOẶC MỞ TÀI KHOẢN, BẠN XÁC NHẬN ĐÃ ĐỒNG Ý VÀ THẤU HIỂU CÁC ĐIỀU KHOẢN SỬ DỤNG CỦA THỎA THUẬN NÀY, BAO
                        GỒM CÁC ĐIỀU KHOẢN SỬ DỤNG VÀ CHÍNH SÁCH KHÁC ĐANG CÓ HIỆN TẠI VÀ SẼ ĐƯỢC THÊM VÀO TRONG TƯƠNG LAI NẾU BẠN KHÔNG ĐỒNG Ý VỚI
                        CÁC ĐIỀU KHOẢN NÀY, VUI LÒNG KHÔNG SỬ DỤNG DỊCH VỤ HOẶC TRUY CẬP VÀO TRANG WEB CỦA CHÚNG TÔI. NẾU BẠN DƯỚI 18 TUỔI HOẶC TUỔI
                        HỢP LỆ THEO PHÁP LUẬT HIỆN HÀNH CÓ THỂ ÁP DỤNG, BẠN PHẢI ĐƯỢC PHÉP CỦA PHỤ HUYNH HOẶC NGƯỜI GIÁM HỘ HỢP PHÁP TRƯỚC KHI MỞ TÀI
                        KHOẢN VÀ PHỤ HUYNH VÀ NGƯỜI GIÁM HỘ HỢP PHÁP PHẢI ĐỒNG Ý VÀ THẤU HIỂU VỚI CÁC ĐIỀU KHOẢN CỦA THỎA THUẬN NÀY. NẾU BẠN KHÔNG
                        BIẾT BẢN TH N ĐÃ QUA TUỔI HỢP PHÁP CHƯA, HOẶC KHÔNG HIỂU PHẦN NÀY, VUI LÒNG KHÔNG TẠO TÀI KHOẢN CHO ĐẾN KHI BẠN ĐÃ ĐƯỢC PHỤ
                        HUYNH HOẶC NGƯỜI GIÁM HỘ HỢP PHÁP GIÚP ĐỠ. NẾU BẠN LÀ PHỤ HUYNH HOẶC NGƯỜI GIÁM HỘ HỢP PHÁP CỦA TRẺ CHƯA ĐẠT ĐỘ TUỔI HỢP PHÁP
                        ĐANG MUỐN TẠO TÀI KHOẢN, BẠN PHẢI THAY MẶT TRẺ ĐỒNG Ý VỚI CÁC ĐIỀU KHOẢN CỦA THỎA THUẬN NÀY VÀ BẠN SẼ CHỊU TRÁCH NHIỆM CHO TẤT
                        CẢ HOẠT ĐỘNG CỦA TÀI KHOẢN VÀ CÁC DỊCH VỤ ĐƯỢC SỬ DỤNG BỞI TÀI KHOẢN NÀY, DÙ TÀI KHOẢN ĐANG HOẠT ĐỘNG HOẶC CHƯA ĐƯỢC MỞ.
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        TÀI KHOẢN/CHIA SẺ THÔNG TIN - BẢO MẬT
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Khi bạn tạo một tài khoản trên trang web Milu, bạn sẽ có trách nhiệm:
                        <br />
                        • Tự bảo mật mật khẩu đăng nhập cá nhân của bạn và chỉ sử dụng Tên Đăng Nhập và mật khẩu của bạn khi đăng nhập.
                        <br />
                        • Đảm bảo là bạn đăng xuất khỏi tài khoản sau mỗi lần sử dụng.
                        <br />
                        • Ngay lập tức thông báo cho MILU nếu có bất cứ lượt truy cập không được phép từ tài khoản của bạn. <br />
                        Bạn hoàn toàn chịu trách nhiệm cho tất cả các hoạt động xảy ra với ID Người dùng và Tài khoản của mình ngay cả khi các hoạt
                        động hoặc việc sử dụng đó không phải do bạn cam kết. MILU sẽ không chịu trách nhiệm đối với bất kỳ mất mát hoặc thiệt hại nào
                        phát sinh từ việc sử dụng trái phép mật khẩu của bạn hoặc việc bạn không tuân thủ Mục này.
                        <br />
                        Milu luôn đảm bảo an toàn cho thông tin và sử dụng cách thức bảo vệ tốt nhất cho các phương tiện thanh toán. Thông tin cá nhân
                        của khách hàng trong quá trình thanh toán sẽ được bảo vệ một cách an toàn.
                        <br />
                        Bạn không nên sử dụng bất kỳ thiết bị hay công cụ nào nhằm cố gắng can thiệp để thay đổi cấu trúc dữ liệu. Tất cả các hoạt
                        động phát tán hay khuyến khích truy cập trái phép hoặc phá hủy dữ liệu của chúng tôi sẽ bị nghiêm cấm. Xin vui lòng không sử
                        dụng trang web của chúng tôi sai mục đích. Tất cả các hành vi vi phạm sẽ bị tước bỏ tất cả các quyền hoặc có thể bị xử lí theo
                        pháp luật nếu cần thiết.
                        <br />
                        Tất cả các dữ liệu trao đổi sẽ được bảo mật và chúng tôi sẽ chỉ buộc phải cung cấp dữ liệu khi có yêu cầu hợp lý.
                        <br />
                        Để biết thêm thông tin, vui lòng truy cập trang chính sách bảo mật trên trang web của chúng tôi để tham khảo thêm.
                      </p>
                      <p className='mt-3 pb-10 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        Milu cam kết đảm bảo thực hiện nghiêm túc các biện pháp bảo mật cần thiết cho mọi hoạt động thanh toán thực hiện trên trang
                        thương mại điện tử https://milu.promete.ai/.
                        <br />
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        ĐIỀU KHOẢN THÀNH VIÊN - NGUYÊN TẮC HOẠT ĐỘNG?
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Dịch vụ của chúng tôi chỉ được cung cấp cho người dùng và hãy chắc chắn rằng bạn đủ 18 tuổi trở lên để tham gia truy cập. Nếu
                        bạn chưa đạt độ tuổi quy định, bạn cần phải được sự đồng ý của bố mẹ để đăng ký người dùng, nếu không bạn sẽ bị xóa khỏi danh
                        sách khách hàng bởi vì bạn cần phải đạt độ tuổi pháp lý để quyết định sử dụng dịch vụ. Khách hàng có thể đăng ký gia nhập Milu
                        thông qua lời mời của bạn bè hoặc hoàn tất mẫu đăng ký để trở thành hội viên. Khi bạn hoàn thành tất cả các bước để đăng ký,
                        bạn đã có thể sử dụng dịch vụ của chúng tôi. Trở thành hội viên, bạn phải cung cấp cho chúng tôi thông tin chính xác một cách
                        kịp thời để hoàn tất việc đăng ký. Các người dùng có thể sử dụng dịch vụ mà không bị giới hạn. Quý người dùng có quyền sử dụng
                        trang web để thử nghiệm, yêu cầu trợ lý ảo giải đáp, quản lý tác vụ hoặc cho các mục đích hợp lý khác. Chúng tôi cũng có thể
                        ngừng cung cấp dịch vụ mà không cần thông báo nếu bạn có vi phạm nào ảnh hưởng đến các điều khoản tại trang này.
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        TRÁCH NHIỆM CỦA NGƯỜI DÙNG VÀ/HOẶC BÊN MUA
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Người dùng và/hoặc Bên Mua phải đọc và đồng ý với tất cả các điều khoản và điều kiện trong và được nhắc đến trong các Điều
                        Khoản Sử Dụng này và phải cho phép việc sử dụng các thông tin cá nhân của bạn theo Điều Khoản Bảo Mật của Milu.
                        <br />
                        Người dùng và/hoặc Bên Mua đồng ý không:
                        <p className='mt-4 px-2 text-lg font-light text-white'>
                          • Sử dụng Dịch Vụ với mục đích gây lợi cho bất cứ bên thứ ba nào hoặc theo bất cứ phương thức không được cho phép từ các
                          giấy phép được cấp dưới đây.
                          <br />
                          • Mở và/hoặc sử dụng nhiều tài khoản liên quan tới bất cứ hành vi nào vi phạm các điều lệ và tinh thần của Các Điều Khoản Sử
                          Dụng này.
                          <br />
                          • Dịch ngược, thiết kế ngược, tháo dỡ hoặc hack Dịch vụ (hoặc bất cứ phần nào của Dịch Vụ), hay phá vỡ hoặc vượt qua bất cứ
                          công nghệ mã hóa hoặc biện pháp bảo mật nào được MILU sử dụng nhằm tôn trọng Dịch Vụ và/hoặc dữ liệu được truyền tải, sử
                          dụng hay lưu trữ bởi MILU.
                          <br />• Thu thập bất cứ thông tin gì về hoặc liên quan đến các chủ Tài Khoản khác, bao gồm, nhưng không hạn chế, các thông
                          tin hoặc dữ liệu cá nhân.
                        </p>
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        THANH TOÁN - NGUYÊN TẮC HOẠT ĐỘNG?
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Milu đảm bảo rằng tất cả các nội dung được gợi ý từ Milu đều hoàn toàn dựa trên công nghệ AI, thông qua phân tích dữ liệu lịch
                        sử và không có sự can thiệp của các bên khác với bất cứ mục đích nào. Các giải đáp không thỏa đáng của Milu, người dùng có thể
                        phản ánh với đội ngũ để điều chỉnh thông tin và cải thiện độ chính xác cura Milu.
                        <br />
                        Để sử dụng trợ lý ảo Milu, người dùng cần tạo tài khoản với thông tin cá nhân được yêu cầu. Mọi thông tin cá nhân sẽ được tuân
                        theo các quy định về bảo mật thông tin cá nhân ở Chính sách Bảo mật tại đây.
                        <p className='mt-4 px-2 text-lg font-light text-white'>
                          - Sau khi đã có tài khoản, người dùng có thể ngay lập tức chọn mua số intelium theo ý muốn và tiến hành thanh toán qua thẻ
                          tín dụng/ ghi nợ / ATM / internet banking / thẻ điện thoại.
                          <br />- Sau khi thanh toán thành công, số intelium sẽ được được xử lý và chuyển ngay lập tức hoặc chậm nhất là trong vòng 24
                          giờ vào tài khoản của người dùng để sử dụng dịch vụ Milu.
                        </p>
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'> ĐỘ CHÍNH XÁC CỦA NỘI DUNG</p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Tại Milu, chúng tôi luôn cố gắng mọi khả năng để đảm bảo nội dung cung cấp trên website luôn được cập nhật chính xác. Tuy
                        nhiên, chúng tôi có thể sẽ có một số lỗi đánh máy, lỗi báo giá hoặc cái lỗi khác mà đội ngũ chúng tôi chưa phát hiện ra. Trong
                        trường hợp đó, xin đừng do dự báo ngay cho chúng tôi biết để có thể sửa ngay lập tức
                        <br />
                        Nhằm nâng cao dịch vụ, chúng tôi luôn sẵn sàng tiếp nhận bất cứ phản hồi nào bạn có thể có liên quan đến nội dung của trang
                        nhằm cải thiện website và độ hài lòng của bạn với trải nghiệm cùng Milu trên trang của chúng tôi.
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        BẢN QUYỀN SỞ HỮU TRÍ TUỆ & ĐIỀU KHOẢN VI PHẠM - CHÚNG TÔI CHUẨN BỊ CÁC THÔNG TIN CỦA MÌNH VÀ CHÚNG TÔI KHÔNG SỬ DỤNG NỘI DUNG
                        KHÁC
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Tất cả nội dung của website này do chúng tôi tự thiết lập. Chúng tôi không sử dụng bất kỳ nội dung nào cho mục đích cá nhân.
                        <br />
                        Hơn nữa, tất cả các vi phạm về tài sản thuộc bản quyền sở hữu trí tuệ ngay cả khi chúng tôi đã đăng ký hay chưa, tất cả các
                        nội dung và thiết kế, đồ họa, phần mềm, hình ảnh, âm thanh, phần mềm lập trình, mã nguồn và các phần mềm cơ bản là tài sản
                        riêng của chúng tôi. Chúng được bảo vệ bởi luật bản quyền Việt Nam và công ước quốc tế khác. Tất cả bản quyền đều được bảo vệ.
                        <br />
                        Nếu bạn tìm thấy bất kỳ phần nào của trang web của chúng tôi vi phạm bản quyền của bạn, bạn có thể yêu cầu gỡ bỏ. Để gửi yêu
                        cầu này, bạn phải xuất trình một chữ ký số hoặc tương đương của người giữ bản quyền hoặc người đại diện theo ủy quyền, và phải
                        bao gồm những điều sau:
                        <p className='mt-4 px-2 text-lg font-light text-white'>
                          (i) xác định các phần bị cáo buộc xâm phạm;
                          <br />
                          (ii) chỉ ra nơi nào trên trang web này có nội dung vi phạm bản quyền;
                          <br />
                          (iii) để lại thông tin cá nhân của bạn để liên lạc;
                          <br />
                          (iv) các quy định của luật pháp là cơ sở để bảo vệ bản quyền của bạn;
                          <br />
                          (v) cơ sở cho thấy các thông tin trong yêu cầu của bạn là chính xác;
                          <br />
                        </p>
                        Nếu bạn cho rằng có ai đó đã thiết lập sai về việc thông báo vi phạm bản quyền của bạn, xin vui lòng liên hệ với chúng tôi
                        càng sớm càng tốt. Xin lưu ý rằng sẽ có những hình phạt cho các khiếu nại sai theo quy định.
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        LIÊN KẾT VỚI ĐỐI TÁC KHÁC- CHÚNG TÔI KHÔNG XÁC NHẬN
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Trang web của Milu có thể chứa các liên kết đến blog của chúng tôi hoặc các trang web khác trên Internet. Xin lưu ý rằng chúng
                        tôi không có quyền kiểm soát các trang web như vậy và rằng chúng tôi không chịu trách nhiệm về tính chính xác, nội dung, tính
                        hợp pháp, hoặc bất kỳ khía cạnh nào khác của bất kỳ trang web nào được liên kết đến.
                        <br />
                        Vui lòng không nhấp vào liên kết đến bất kỳ trang web nếu bạn không thể chắc chắn về cách bảo vệ thông tin riêng của bạn.
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        BÀI ĐĂNG VÀ EMAIL - QUY TẮC VIỆC GỬI EMAIL GIỚI THIỆU, BẢO VỆ HÌNH ẢNH CỦA MILU
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Khi bạn trở thành người dùng của Milu, bạn sẽ được cung cấp khả năng gửi tin nhắn qua email cho người khác và gửi tin nhắn
                        trên trang web của chúng tôi trong quá trình kết nối với các dịch vụ. Bạn hoàn toàn chịu trách nhiệm về tài khoản, mật khẩu,
                        thông tin, tin nhắn, ghi chú, văn bản, thông tin, ý kiến, ý tưởng của bạn và bất kỳ nội dung khác mà bạn gửi, tải lên, gửi,
                        xuất bản, hoặc hiển thị (ở đây là &quot;bài&quot;) hoặc chuyển giao cho hoặc chia sẻ với Người dùng khác (gọi chung là
                        &apos;Nội dung người dùng&apos;). Khi bạn gửi hoặc cung cấp cho chúng tôi bất kỳ nội dung để hiển thị tại bất kỳ phần nào trên
                        trang web của chúng tôi hoặc trong liên kết với các dịch vụ, bạn đồng ý cung cấp cho chúng tôi một giấy phép không thể thu
                        hồi, vĩnh viễn, không độc quyền, có thể chuyển nhượng, miễn phí bản quyền, mang tính toàn cầu để sử dụng, sao chép, chuyển
                        giao, tái sản xuất, điều chỉnh, sửa đổi, thực hiện công khai, công khai hiển thị, định dạng, dịch, trích (toàn bộ hoặc một
                        phần), tạo ra tác phẩm tập thể và phân phối “Nội dung người dùng” của bạn này cho bất kỳ mục đích, thương mại, quảng cáo, hay
                        nói cách khác, từ hoặc trong kết nối với các dịch vụ hay chương trình khuyến mãi, hay cho việc kinh doanh của Milu và không
                        giới hạn đối với việc thúc đẩy và phân phối lại một phần hoặc tất cả các trang web của chúng tôi. Bạn đại diện và bảo đảm rằng
                        bạn sẽ không cung cấp bất kỳ “Nội dung người dùng” nào mà bạn không có đầy đủ các quyền quyết định trong tình huống này. Bạn
                        sẽ từ bỏ tất cả các quyền nhân thân trong &apos;Nội dung người dùng&apos; có thể có sẵn cho bạn trong bất kỳ phần nào và xác
                        nhận rằng không có các quyền như vậy đã được khẳng định. Bạn đại diện và bảo đảm với Milu rằng Milu là miễn phí để thực hiện
                        các quyền của mình và/ hoặc thực hiện các “nội dung người dùng” của bạn khi cần thiết, không phải xin phép hoặc giấy phép từ
                        bất kỳ bên thứ ba và ngoài việc tham khảo ý kiến của bạn hoặc từ người khác.
                        <br />
                        Tất cả nội dung người dùng là trách nhiệm của các người dùng, không phải trách nhiệm của Milu. Sau đây là tất cả các nội dung
                        bài đăng hoặc phương tiện khác nếu bao gồm sẽ bị cấm:
                        <p className='mt-4 px-2 text-lg font-light text-white'>
                          (i) các tài liệu chính trị, bôi nhọ hoặc các tài liệu bất hợp pháp, lạm dụng, hoặc khiêu dâm khác;
                          <br />
                          (ii) thông tin cá nhân của người khác mà không được chấp nhận (như số an sinh xã hội, địa chỉ, số điện thoại, v.v...);
                          <br />
                          (iii) thông tin bí mật của Milu, công ty mẹ, công ty con và các chi nhánh;
                          <br />
                          (iv) bất kỳ chuỗi thư điện tử, bán hàng đa cấp, tin rác, các cuộc thi, hay tài liệu quảng cáo;
                          <br />
                          (v) thông điệp đề nghị tải trái phép bất kỳ thông tin bản quyền hay tư nhân khác;
                          <br />
                          (vi) các thông tin tương tự hoặc liên quan đến các loại kể trên.
                        </p>
                        Bạn cũng sẽ đồng ý để mời bạn bè tham gia người dùng Milu cho chỉ những cá nhân đồng ý để được mời và bạn sẽ không mời bất cứ
                        ai không biết bạn và phải là người chắc chắn để nhận ra bạn để có thể liên lạc. Chúng tôi có thể có quyền sử dụng những thông
                        tin bạn cung cấp để kiểm soát các lời mời của bạn, theo dõi chúng và kiểm tra kết quả, cung cấp thêm thông tin về các dịch vụ
                        để nâng cao chất lượng dịch vụ.
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        BỒI THƯỜNG - CHÚNG TÔI SẼ TỰ BẢO VỆ MÌNH NẾU BẤT KỲ BÊN NÀO SỬ DỤNG SAI MỤC ĐÍCH DỊCH VỤ CỦA CHÚNG TÔI
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Khi lựa chọn dịch vụ của chúng tôi, bạn đồng ý bồi thường và bảo vệ Milu từ bất kỳ khiếu nại, yêu cầu bồi thường, tổn thất,
                        trách nhiệm, và tất cả các chi phí và các chi phí quốc phòng của một bên thứ ba phát sinh trong kết nối với việc sử dụng hoặc
                        lạm dụng các dịch vụ của bạn hoặc khi bạn vi phạm các quy định sử dụng của chúng tôi.
                        <br />
                        Nếu bạn vi phạm bất kỳ luật hoặc quyền của một bên thứ ba, bạn phải đồng ý hợp tác đầy đủ theo yêu cầu hợp lý và cam kết sự
                        bồi thường. Chúng tôi cũng sẽ có quyền để bảo vệ bản thân nếu bất kỳ bên nào sử dụng sai mục đích dịch vụ của chúng tôi.
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        LIÊN KẾT - CHÚNG TÔI KHÔNG CHỊU TRÁCH NHIỆM CHO ĐẠI DIỆN NÀO KHÁC CỦA MILU KHI KHÔNG CÓ HỢP ĐỒNG
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Milu không chịu trách nhiệm cho bất kỳ đại diện nào của chúng tôi mà không có hợp đồng. Nói cách khác, chúng tôi không liên
                        kết với những đơn vị khác để bán, quảng cáo sản phẩm của chúng tôi mà không cần đàm phán thông qua hợp đồng. Nếu bạn sử dụng
                        bất kỳ dịch vụ nào thông qua nhà cung cấp trái phép, chúng tôi không chịu trách nhiệm về bất kỳ thiệt hại nào của bạn.
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        KẾT THÚC VÀ GIAN LẬN - QUYỀN CỦA CHÚNG TÔI VỀ SỰ XÂM PHẠM CỦA KHÁCH HÀNG
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Nếu chúng tôi phát hiện ra bất kỳ thông tin giả mạo nào, chúng tôi sẽ khóa tài khoản của bạn ngay lập tức hoặc áp dụng các
                        giải pháp khác theo quy định của pháp luật Việt Nam.
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        TRỌNG TÀI & LUẬT ĐIỀU CHỈNH - CHÚNG TÔI SẼ GIẢI QUYẾT TRANH CHẤP NHƯ THẾ NÀO?
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Milu luôn cố gắng nỗ lực để giải quyết bất kỳ tranh chấp nào tồn tại. Nếu những nỗ lực này thất bại, xin lưu ý rằng tất cả các
                        khiếu nại, tranh chấp phát sinh từ hoặc liên quan đến việc bạn sử dụng dịch vụ của chúng tôi, các điều kiện và điều khoản sử
                        dụng, việc mua, thanh toán và sử dụng dịch vụ, hoặc các nội dung , hiển thị hoặc tiết lộ bất kỳ thông tin về hoặc liên quan
                        đến các dịch vụ hoặc bất kỳ khiếu nại phải chịu ràng buộc trọng tài. Tất cả các khiếu nại dựa trên hợp đồng, sai lầm cá nhân
                        (bao gồm cả sai lầm cá nhân cố ý), gian lận, sơ suất của bạn hay của chúng tôi, các quy định pháp luật hoặc quy định, hoặc bất
                        kỳ cơ sở pháp luật khác; khiếu nại được thực hiện như yêu cầu tố tụng, yêu cầu bồi thường, khiếu nại của bên thứ ba, bãi nại
                        hoặc hình thức khác; và việc bồi thường thực hiện một cách độc lập hoặc có yêu cầu khác. Mặc dù vậy, chúng ta đều đồng ý rằng
                        bạn hoặc chúng tôi đều có thể khiếu nại với toà án nếu có vi phạm hoặc lạm dụng khác về quyền sở hữu trí tuệ. .
                        <br />
                        Tất cả các khiếu nại sẽ được giải quyết bằng cách thương lượng, hòa giải, trọng tài và / hoặc tòa án theo Luật Bảo vệ người
                        tiêu dùng - Chương 4 về giải quyết tranh chấp giữa người tiêu dùng, các tổ chức và cá nhân kinh doanh hàng hóa và dịch vụ.
                        <br />
                        Các điều khoản, điều kiện sử dụng (cùng với chính sách bảo mật) và mọi khiếu nại sẽ được điều chỉnh bởi pháp luật Việt Nam, mà
                        không bị hạn chế bởi các nguyên tắc pháp lý. Nếu có tranh chấp phát sinh từ các vấn đề trên, bạn có thể gửi đơn khiếu nại lên
                        tòa án ở Việt Nam để giải quyết.
                      </p>
                      <p className='mt-3 text-base font-medium text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                        THAY ĐỔI CHÍNH SÁCH - LẦN CẬP NHẬT GẦN NHẤT, CÁC CẬP NHẬT TRONG TƯƠNG LAI
                      </p>
                      <p className='mt-4 px-2 text-lg font-light text-white'>
                        Những thông tin nêu trên chính là toàn bộ điều kiện và điều khoản sử dụng của Milu, xin vui lòng đọc kỹ để sử dụng và liên hệ
                        với chúng tôi để biết thêm thông tin. Đây là bản cập nhật mới nhất của chúng tôi và được áp dụng mọi thời điểm bạn sử dụng
                        dịch vụ.
                        <br />
                        Nếu có những bản cập nhật khác về các điều kiện và điều khoản sử dụng này, hãy truy cập trang web của chúng tôi để tìm hiểu
                        khi nhận được thông báo của chúng tôi. Chúng tôi sẽ thông báo cho bạn trước bằng cách gửi cho bạn một email đến địa chỉ email
                        cuối cùng mà bạn cung cấp cho chúng tôi khi sử dụng dịch vụ của chúng tôi. Mọi thay đổi sẽ có hiệu lực vào ngày được nêu trong
                        thông báo và các thay đổi này sẽ không áp dụng cho các dịch vụ trước các thay đổi được áp dụng. Nếu bạn không chấp nhận sự
                        thay đổi được áp dụng, bạn không nên tiếp tục làm người dùng trước ngày thay đổi có hiệu lực. Nếu có bất kỳ thay đổi được cho
                        là không hợp lệ, bị vô hiệu hoặc vì lý do nào đó không thể thực thi, nó sẽ không áp dụng cho bất kỳ thay đổi hoặc điều kiện
                        còn lại.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='border-t border-gray-200 bg-gray-900 pb-12'>
                <Footer />
                {/*<p className='text-center text-base font-light text-gray-400'>&copy; 2023 Promete, Inc. All rights reserved.</p>*/}
              </div>
            </div>
          </main>
        </div>
      </div>
    </Fragment>
  );
}
