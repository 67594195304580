import { Transition } from 'react-transition-group';
import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { helper as $h } from '../utils/helper';
import { enter, leave, linkTo, nestedMenu } from './utils/SideMenuUtils';
import Lucide from '../base-components/Lucide';
import classnames from 'classnames';
import TopBar from '../components/TopBar';
import SideMenuTooltip from '../components/SideMenuTooltip';
import dom from '@left4code/tw-starter/dist/js/dom';
import { useAppDispatch, useAppSelector } from '@/stores/redux/hooks';
import { ISideMenuItem, sideMenuActions } from '@/stores/redux/side-menu/side-menu-slice';
import LoadingIcon from '../base-components/LoadingIcon';
import DarkModeSwitcher from '../components/DarkModeSwitcher';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { Auth } from '@aws-amplify/auth';
import { ChatRoomSubscription } from '@/utils/graphql/chat_room_utils';
import { MessageSubscription } from '@/utils/graphql/message_utils';
import { getAndDispatchAllUsersWithToken } from '@/utils/graphql/user_utils';
import { UserTokenSubscription } from '@/utils/graphql/user_token_utils';
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { Hub } from 'aws-amplify';
import { gqlFetchAllAgentsAndDispatch } from '@/utils/graphql/agent_utils';
import MobileTopBar from '@/components/MobileTopBar';

function SideMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const [formattedMenu, setFormattedMenu] = useState<ISideMenuItem[]>([]);
  const sideMenuStore = useAppSelector((state) => state.sideMenu);
  const sideMenu = useCallback(() => nestedMenu($h.toRaw(sideMenuStore.menu), location), [location, sideMenuStore.menu]);
  const { isSignedIn, user, deviceKey, isDeviceRemembered, updateDeviceKey, isAdmin } = useAuth();
  const dispatch = useAppDispatch();
  const currentChatRoomID = useAppSelector((state) => state.chat.chatRoom.id);

  useEffect(() => {
    dom('body').removeClass('error-page').removeClass('login').addClass('main');
    setFormattedMenu(sideMenu());
  }, [sideMenuStore, location.pathname, sideMenu]);

  useEffect(() => {
    if (!isSignedIn) {
      navigate('/auth/signin');
    }
  }, [isSignedIn, navigate]);

  // Adjust admin view
  useEffect(() => {
    if (!dispatch || !user) return;

    (async () => {
      const isUserAdmin = await isAdmin();
      if (isUserAdmin) {
        dispatch(
          sideMenuActions.adjustAdminView({
            icon: 'Settings',
            title: 'Admin',
            pathname: '/admin/token'
          })
        );
      }
    })();
  }, [dispatch, isAdmin, user]);

  // When user is signed in, query and subscribe to the database
  // Also remember the device if required
  useEffect(() => {
    if (!isSignedIn || !user) {
      console.log('User is not signed in');
      return;
    } else {
      console.log('User is signed in');
    }

    // Chat room subscription
    let chatRoomSubscription: ChatRoomSubscription | null = null;
    let userTokenSubscription: UserTokenSubscription | null = null;
    let listener: ReturnType<typeof Hub.listen> | null = null;

    (async () => {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      const isUserAdmin = await isAdmin();

      // Listen to API events
      listener = Hub.listen('api', (data) => {
        const { payload } = data;
        // TODO (MinhLuan): if there is some sudden disconnection, we need to reload the data
        if (payload.event === CONNECTION_STATE_CHANGE) {
          console.log('API event: ', payload.data.connectionState as ConnectionState);
        }
      });

      // Get cached device key
      cognitoUser.getCachedDeviceKeyAndPassword();

      if (isDeviceRemembered) {
        // Remember device
        cognitoUser.setDeviceStatusRemembered({
          onSuccess: (result: string) => {
            console.log('Device status remembered: ', result);
          },
          onFailure: (err: any) => {
            console.error('Error remembering device status: ', err);
          }
        });
      } else {
        // Forget device
        cognitoUser.setDeviceStatusNotRemembered({
          onSuccess: (result: string) => {
            console.log('Device status not remembered: ', result);
          },
          onFailure: (err: any) => {
            console.error('Error forgetting device status: ', err);
          }
        });
      }

      // Update device ID
      updateDeviceKey(cognitoUser.deviceKey);

      // Fetch all agents and dispatch to redux
      await gqlFetchAllAgentsAndDispatch(dispatch);

      // Update user token for the first time
      userTokenSubscription = new UserTokenSubscription(dispatch, user.id);
      await userTokenSubscription.subscribe();

      // Subscribe to chat rooms
      chatRoomSubscription = new ChatRoomSubscription(dispatch, user.id, cognitoUser.deviceKey);
      await chatRoomSubscription.subscribe();

      // Subscribe to users for admin user only
      if (isUserAdmin) {
        await getAndDispatchAllUsersWithToken(dispatch);
      }

      // Unsubscribe when component unmounts
      return () => {
        if (chatRoomSubscription) {
          chatRoomSubscription.unsubscribe();
        }
        if (listener) {
          // Remove listener
          if (listener) listener();
        }
      };
    })();
  }, []);

  // Query and dispatch messages when chat room changes
  useEffect(() => {
    let messagesSubscription: MessageSubscription | null = null;

    (async () => {
      if (!currentChatRoomID) {
        return;
      }

      console.log('Chat room changed: ', currentChatRoomID, ', dispatching messages...');

      // Subscribe and dispatch messages
      messagesSubscription = new MessageSubscription(dispatch, currentChatRoomID, user!.id);
      await messagesSubscription.subscribe();
    })();

    return () => {
      if (messagesSubscription) {
        messagesSubscription.unsubscribe();
      }
    };
  }, [currentChatRoomID, dispatch]);

  if (!sideMenu) {
    return (
      <div className='flex h-full w-full items-center justify-center'>
        <LoadingIcon icon='grid' className='h-12 w-12' />
      </div>
    );
  }

  return (
    <div className='py-5 md:py-0'>
      <MobileTopBar />
      <TopBar className='hidden md:block' />
      <div
        className='flex overflow-hidden'
        style={{
          height: `${location.pathname === '/' ? '100vh' : 'auto'}`
        }}>
        {/* BEGIN: Side Menu */}
        <nav className='side-nav'>
          <ul className='flex h-full flex-col'>
            {/* BEGIN: First Child */}
            {formattedMenu.map((menu: ISideMenuItem, menuKey) =>
              menu.type == 'devider' ? (
                <li className='side-nav__devider my-6' key={menu.title + menuKey}></li>
              ) : (
                <li key={menu.title + menuKey}>
                  <SideMenuTooltip
                    tag='a'
                    content={menu.title}
                    href={menu.subMenu ? '#' : menu.pathname}
                    className={classnames({
                      'side-menu': true,
                      'side-menu--active': menu.active,
                      'side-menu--open': menu.activeDropdown
                    })}
                    onClick={(event) => {
                      event.preventDefault();
                      linkTo(menu, navigate);
                      setFormattedMenu($h.toRaw(formattedMenu));
                    }}>
                    <div className='side-menu__icon zoom-in'>
                      <Lucide icon={menu.icon} />
                    </div>
                    <div className='side-menu__title'>
                      {menu.title}
                      {menu.subMenu && (
                        <div
                          className={classnames({
                            'side-menu__sub-icon': true,
                            'rotate-180': menu.activeDropdown
                          })}>
                          <Lucide icon='ChevronDown' />
                        </div>
                      )}
                    </div>
                  </SideMenuTooltip>
                  {/* BEGIN: Second Child */}
                  {menu.subMenu && (
                    <Transition in={menu.activeDropdown} onEnter={enter} onExit={leave} timeout={300}>
                      <ul
                        className={classnames({
                          'side-menu__sub-open': menu.activeDropdown,
                          'h-full': true
                        })}>
                        {menu.subMenu.map((subMenu, subMenuKey) => (
                          <li key={subMenuKey}>
                            <SideMenuTooltip
                              tag='a'
                              content={subMenu.title}
                              href={subMenu.subMenu ? '#' : subMenu.pathname}
                              className={classnames({
                                'side-menu': true,
                                'side-menu--active': subMenu.active
                              })}
                              onClick={(event) => {
                                event.preventDefault();
                                linkTo(subMenu, navigate);
                                setFormattedMenu($h.toRaw(formattedMenu));
                              }}>
                              <div className='side-menu__icon'>
                                <Lucide icon='Activity' />
                              </div>
                              <div className='side-menu__title'>
                                {subMenu.title}
                                {subMenu.subMenu && (
                                  <div
                                    className={classnames({
                                      'side-menu__sub-icon': true,
                                      'rotate-180': subMenu.activeDropdown
                                    })}>
                                    <Lucide icon='ChevronDown' />
                                  </div>
                                )}
                              </div>
                            </SideMenuTooltip>
                            {/* BEGIN: Third Child */}
                            {subMenu.subMenu && (
                              <Transition in={subMenu.activeDropdown} onEnter={enter} onExit={leave} timeout={300}>
                                <ul
                                  className={classnames({
                                    'side-menu__sub-open': subMenu.activeDropdown
                                  })}>
                                  {subMenu.subMenu.map((lastSubMenu, lastSubMenuKey) => (
                                    <li key={lastSubMenuKey}>
                                      <SideMenuTooltip
                                        tag='a'
                                        content={lastSubMenu.title}
                                        href={lastSubMenu.subMenu ? '#' : lastSubMenu.pathname}
                                        className={classnames({
                                          'side-menu': true,
                                          'side-menu--active': lastSubMenu.active
                                        })}
                                        onClick={(event) => {
                                          event.preventDefault();
                                          linkTo(lastSubMenu, navigate);
                                        }}>
                                        <div className='side-menu__icon'>
                                          <Lucide icon='Zap' />
                                        </div>
                                        <div className='side-menu__title'>{lastSubMenu.title}</div>
                                      </SideMenuTooltip>
                                    </li>
                                  ))}
                                </ul>
                              </Transition>
                            )}
                            {/* END: Third Child */}
                          </li>
                        ))}
                      </ul>
                    </Transition>
                  )}
                  {/* END: Second Child */}
                </li>
              )
            )}
            {/* END: First Child */}
            <li className='grid h-full content-between'>
              <div className='side-nav__devider my-6'></div>
              <div className='flex flex-col items-center'>
                <DarkModeSwitcher />
              </div>
            </li>
          </ul>
        </nav>
        {/* END: Side Menu */}
        {/* BEGIN: Content */}
        <div className='content'>
          <Outlet />
        </div>
        {/* END: Content */}
      </div>
    </div>
  );
}

export default SideMenu;
