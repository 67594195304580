import React, { Fragment, useRef, useState } from 'react';
import MessageBoard from '../components/Chat/MessageBoard';
import LoadingIcon from '../base-components/LoadingIcon';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { useAppSelector } from '@/stores/redux/hooks';
import { selectActiveChatRoom } from '@/stores/redux/chat-room/chat-room-slice';
import { selectAllAgents } from '@/stores/redux/agents/agents-slice';
import { ChatRoomList } from '@/components/Chat/ChatRoomList';

function ChatPage() {
  const chatContentDiv = useRef<HTMLDivElement>(null);
  const [chatContentHeight] = useState<string | number>('calc(100vh - 7rem)');
  const { user, deviceKey } = useAuth();
  const activeChatRoom = useAppSelector(selectActiveChatRoom);
  const agents = useAppSelector((state) => selectAllAgents(state));

  if (!user || !user.id || !deviceKey) {
    return (
      <div className='flex h-full w-full items-center justify-center'>
        <LoadingIcon icon='grid' className='h-12 w-12' />
      </div>
    );
  }

  return (
    <Fragment>
      <div className='intro-y chat mt-5 grid grid-cols-12 gap-5' ref={chatContentDiv}>
        {/* BEGIN: Chat Side Menu */}
        <div className='intro-y absolute inset-0 col-span-12 mb-20 flex flex-col lg:col-span-3 lg:h-full 2xl:col-span-2'>
          <ChatRoomList agents={agents} />
        </div>
        {/* END: Chat Side Menu */}
        {/* BEGIN: Chat Content */}
        <div className='intro-y col-span-12 pt-20 md:p-0 lg:col-span-9 2xl:col-span-10'>
          <div className='flex flex-col'>
            {/*Calculate the height of the parent element and set the height of the child element to the height of the parent element, using Tailwind CSS.*/}
            <div className='chat__box box hidden overflow-y-scroll sm:block' style={{ height: chatContentHeight }}>
              {/*/!* BEGIN: Chat Active *!/*/}
              {/*If the activeChatRoom is loaded, show the message board.*/}
              {activeChatRoom && activeChatRoom.agentID && activeChatRoom.chatRoomName ? (
                <MessageBoard />
              ) : (
                <div className='intro-y flex h-full items-center justify-center'>
                  <div className='text-center'>
                    <div className='text-3xl font-medium'>Welcome to Milu Chatbot</div>
                    <div className='mt-1 text-gray-600'>Click on the chatbot icon to start chatting</div>
                  </div>
                </div>
              )}
              {/*/!* END: Chat Active *!/*/}
            </div>
          </div>
        </div>
        {/* END: Chat Content */}
      </div>
    </Fragment>
  );
}

export default ChatPage;
