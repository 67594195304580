import ScrollToTop from './base-components/ScrollToTop';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Router from './router/Router';
import { Provider } from 'react-redux';
import store from './stores/redux/store';
import { AmplifyAuthProvider } from './context/AmplifyAuthProvider';
import { ErrorBoundary } from 'react-error-boundary';
import { Error, ErrorHandler } from '@/base-components/Error/Error';
import { ChatProvider } from '@/context/ChatProvider';

// TODO (MinhLuan): move chat provider into inner components to reduce the number of re-rendering
function App() {
  return (
    <RecoilRoot>
      <Provider store={store}>
        <ErrorBoundary FallbackComponent={Error} onError={ErrorHandler}>
          <AmplifyAuthProvider>
            <ChatProvider>
              <BrowserRouter>
                <Router />
                <ScrollToTop />
              </BrowserRouter>
            </ChatProvider>
          </AmplifyAuthProvider>
        </ErrorBoundary>
      </Provider>
    </RecoilRoot>
  );
}

export default App;
