import React, { Fragment, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from '@/base-components/modal';
import TopUpTokenForm from '@/components/TopUpTokenForm';
import { useAuth } from '@/context/AmplifyAuthProvider';
import { useAppDispatch, useAppSelector } from '@/stores/redux/hooks';
import { addItem, selectAllCartItems } from '@/stores/redux/cart/cart-slice';
import { selectAllProducts } from '@/stores/redux/product/product-slice';
import { IProduct } from '@/types/helpers/IProduct';
import { ChevronRight } from 'lucide-react';
import { ReactComponent as RobotImg } from '@/assets/images/robot-svg.svg';
import coinsImg from '@/assets/images/Intelium-coins-transparent.png';
import coinImg from '@/assets/images/Intelium-coin-single-transparent.png';
import blockImg from '@/assets/images/Intelium-block-transparent.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AnimatedCard from '@/components/AnimatedCard';

function ProductPage() {
  const { t } = useTranslation();

  const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false);
  const [modalUserID, setModalUserID] = useState('');
  const { user } = useAuth();
  const products = useAppSelector((state) => selectAllProducts(state));
  const cartItems = useAppSelector((state) => selectAllCartItems(state));
  const cart = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();

  const handleAddToCartClick = (product: IProduct) => {
    const cartItem = cartItems.find((item) => item.product.id === product.id);
    if (cartItem) {
      dispatch(
        addItem({
          product: cartItem.product,
          quantity: cartItem.quantity + 1,
          id: cartItem.id,
          pricePerUnit: cartItem.pricePerUnit
        })
      );
    } else {
      dispatch(
        addItem({
          product,
          quantity: 1,
          id:
            // generate a random id
            Math.random().toString(36).substring(2),
          pricePerUnit: product.pricePerUnit
        })
      );
    }
    console.log('Added to cart', product);
    console.log('Cart items', cartItems);
  };

  return (
    <Fragment>
      {/* BEGIN: Modal Content */}
      <Modal
        size='modal-lg'
        show={headerFooterModalPreview}
        onHidden={() => {
          setModalUserID('');
          setHeaderFooterModalPreview(false);
        }}>
        <ModalHeader>
          <h2 className='mr-auto text-base font-medium'>Giỏ hàng</h2>
        </ModalHeader>
        <ModalBody>
          {modalUserID ? (
            <div className='flex flex-col items-center justify-center'>
              <p>Để nạp Intelium cho tài khoản, vui lòng truy cập vào link sau và làm theo hướng dẫn.</p>
              <Link to='/user-guide'>
                <p className='mt-2 text-emerald-500 hover:text-emerald-700'>Hướng dẫn nạp Intelium</p>
              </Link>
            </div>
          ) : (
            // <TopUpTokenForm userID={modalUserID} setHeaderFooterModalPreview={setHeaderFooterModalPreview}
            //                 cartItems={cartItems} cart={cart} />
            <div className='flex items-center justify-center'>
              <svg className='-ml-1 mr-3 h-5 w-5 animate-spin text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1zm0 0h1v1a7 7 0 007 7v1a8 8 0 01-8-8zm0 0v-1a7 7 0 017-7v1a8 8 0 00-8 8zm0 0a8 8 0 008 8v-1a7 7 0 00-7-7h-1z'></path>
              </svg>
              <p className='text-gray-700'>Đang tải...</p>
            </div>
          )}
        </ModalBody>
      </Modal>
      {/* END: Modal Content */}
      <div className='intro-y mt-8 flex items-center'>
        <div className='flex flex-col'>
          <h2 className='mr-auto text-lg font-medium'>Nạp Intelium vào tài khoản</h2>
          <h3 className='text-md w-2/3 font-light'>
            Nạp Intelium vào tài khoản để có thể sử dụng dịch vụ trợ lý ảo Milu, bạn có thể nạp tiền bất cứ lúc nào.
          </h3>
        </div>
        <div className='ml-auto flex items-center'>
          <button
            onClick={() => {
              setHeaderFooterModalPreview(true);
              setModalUserID(user?.id || '');
            }}
            className=''>
            <a className='group relative inline-flex items-center overflow-hidden rounded-full border-2 border-primary dark:border-emerald-500 px-6 sm:px-12 py-3 text-lg font-medium text-primary dark:text-emerald-500 hover:bg-gray-50 hover:text-white dark:hover:text-gray-900'>
              <span className='duration-400 ease absolute left-0 top-1/2 block h-0 w-full bg-primary dark:bg-emerald-500 opacity-100 transition-all group-hover:top-0 group-hover:h-full'></span>
              <span className='ease absolute right-0 flex h-10 w-10 translate-x-full items-center justify-start duration-300 group-hover:translate-x-0'>
                <svg className='h-5 w-5' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M14 5l7 7m0 0l-7 7m7-7H3'></path>
                </svg>
              </span>
              <span className='relative'>Thanh toán</span>
            </a>
          </button>
        </div>
      </div>
      {/* BEGIN: Pricing Layout */}
      <div className='intro-y grid grid-cols-1 pt-6 p-2 gap-y-10 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8'>
        {products.map((product) => (
          <div key={product.id}>
            <AnimatedCard name={product.name} price={product.pricePerUnit} tokens={product.numOfTokens} description={product.description} />
          </div>
        ))}
        <div className='flex h-screen w-full items-center justify-center'></div>
      </div>
      {/* END: Pricing Layout */}
    </Fragment>
  );
}

export default ProductPage;
