import React, { Fragment } from 'react';
import Lucide from '../base-components/Lucide';
import { DropDown, DropdownContent, DropdownDivider, DropdownHeader, DropdownItem, DropdownMenu, DropdownToggle } from '@/base-components/DropDown';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AmplifyAuthProvider';
import logoImg from '@/assets/images/logo-milu-large.png';
import { friendlyCount } from '@/utils/friendly_count';
import { useAppDispatch, useAppSelector } from '@/stores/redux/hooks';
import { usersActions } from '@/stores/redux/users/users-slice';
import { userTokenActions } from '@/stores/redux/user-token/user-token-slice';
import { chatRoomsActions } from '@/stores/redux/chat-rooms/chat-rooms-slice';
import { chatRoomActions } from '@/stores/redux/chat-room/chat-room-slice';
import { BookOpenIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import CartBtn from '@/components/CartButton';
import Tippy from '@/base-components/Tippy';
import avatarImg1 from '@/assets/images/avatar-1.jpg';
import avatarImg2 from '@/assets/images/avatar-2.png';
import avatarImg3 from '@/assets/images/avatar-3.png';
import avatarImg4 from '@/assets/images/avatar-4.png';
import avatarImg5 from '@/assets/images/avatar-5.png';
import avatarImg6 from '@/assets/images/avatar-6.png';
import avatarImg7 from '@/assets/images/avatar-7.png';

interface ITopBarProps {
  className?: string;
}

function TopBar(props: ITopBarProps) {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const { user } = useAuth();
  const userToken = useAppSelector((state) => state.userToken.token);
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    try {
      await signOut();

      // Reset users state
      dispatch(usersActions.resetUsers());

      // Reset user token
      dispatch(userTokenActions.resetUserToken());

      // Reset chat rooms state
      dispatch(chatRoomsActions.resetChatRooms());

      // Reset chat room state
      dispatch(chatRoomActions.resetChatRoom());

      await navigate('/auth/signin', { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      {/* BEGIN: Top Bar */}
      <div
        className={`${props.className} top-bar-boxed relative inset-0 z-[51] -mx-3 h-[70px] border-b border-white/[0.08] px-3 sm:-mx-8 sm:mt-12 sm:px-8 md:fixed md:inset-x-0 md:top-0 md:-mx-0 md:mt-0 md:h-[65px] md:border-b-0 md:bg-gradient-to-b md:from-slate-100 md:to-transparent md:px-10 md:pt-10 dark:md:from-darkmode-700`}>
        <div className='flex h-full items-center justify-center sm:justify-between'>
          {/* BEGIN: Logo */}
          <Link to='/' className='logo -intro-x hidden items-center md:flex xl:w-[180px]'>
            {/*<LogoImg className='h-6 w-6' />*/}
            <img src={logoImg} alt='Milu chatbot' className='h-12 w-12' />
            <span className='logo__text ml-1 text-2xl font-extrabold text-white'>Milu</span>
            <span className='text-2xl font-light text-white'> AI</span>
          </Link>
          {/* END: Logo */}
          {/* BEGIN: Breadcrumb */}
          {/*<nav aria-label='breadcrumb' className='-intro-x ml-auto h-[45px]'>*/}
          {/*  /!*This is to make sure the UI works well, we do not actually need a breadcrumb here*!/*/}
          {/*  <div className='logo__text ml-3 text-lg text-white'></div>*/}
          {/*</nav>*/}
          <div className='intro-x relative flex h-8 w-fit items-center justify-center space-x-4'>
            <div className='zoom-in flex flex-row items-center justify-center' onClick={() => navigate('/user-guide')}>
              <Tippy content='Hướng dẫn'>
                <BookOpenIcon className='h-6 w-6 text-white sm:hidden' />
              </Tippy>
              <p className='mr-1 hidden text-base text-white sm:block'>Hướng dẫn</p>
              <BookOpenIcon className='hidden h-6 w-6 text-white sm:block' />
            </div>
            <div className='zoom-in flex flex-row items-center justify-center' onClick={() => navigate('/about')}>
              <Tippy content='Thông tin'>
                <InformationCircleIcon className='h-6 w-6 text-white sm:hidden' />
              </Tippy>
              <p className='mr-1 hidden text-base text-white sm:block'>Thông tin</p>
              <InformationCircleIcon className='hidden h-6 w-6 text-white sm:block' />
            </div>
            <div
              className='zoom-in'
              onClick={(event) => {
                event.preventDefault();
                navigate('/checkout');
              }}>
              <Tippy content='Giỏ hàng' className='sm:hidden'>
                <CartBtn />
              </Tippy>
              <div className='hidden flex-row items-center justify-center sm:flex'>
                <p className='mr-1 text-base text-white'>Giỏ hàng</p>
                <CartBtn />
              </div>
            </div>
            {/*END: Breadcrumb */}
            {/*BEGIN: Account Menu */}
            <DropDown className='intro-x h-8 w-8'>
              <DropdownToggle tag='div' role='button' className='image-fit zoom-in h-8 w-8 overflow-hidden rounded-full shadow-lg'>
                <img alt='Milu chatbot' src={avatarImg6} />
              </DropdownToggle>
              <DropdownMenu className='w-56'>
                <DropdownContent className='bg-primary/80 text-white before:absolute before:inset-0 before:z-[-1] before:block before:rounded-md before:bg-black'>
                  <DropdownHeader tag='div' className='!font-normal'>
                    <div className='font-medium'>{user ? `${user.firstName} ${user.lastName}` : 'Unknown user'}</div>
                  </DropdownHeader>
                  <DropdownDivider className='border-white/[0.08]' />
                  <DropdownItem className='hover:bg-white/5'>
                    <button
                      onClick={() => {
                        navigate('/user/profile');
                      }}
                      className='flex w-full items-center'>
                      <Lucide icon='User' className='mr-2 h-4 w-4' /> Tài khoản
                    </button>
                  </DropdownItem>

                  <DropdownItem className='hover:bg-white/5'>
                    <button
                      className='flex w-full items-start'
                      onClick={() => {
                        navigate('/auth/reset-password');
                      }}>
                      <Lucide icon='Lock' className='mr-2 h-4 w-4' /> Đổi mật khẩu
                    </button>
                  </DropdownItem>

                  <DropdownDivider className='border-white/[0.08]' />

                  <DropdownItem className='hover:bg-white/5'>
                    <Lucide icon='StopCircle' className='mr-2 h-4 w-4' /> Còn:{' '}
                    {friendlyCount(userToken.id ? userToken.totalTokenAmount - userToken.spentTokenAmount : 0)} INT
                  </DropdownItem>

                  <DropdownDivider className='border-white/[0.08]' />

                  <DropdownItem className='hover:bg-white/5'>
                    <button onClick={handleLogout} className='flex w-full items-center'>
                      <Lucide icon='ToggleRight' className='mr-2 h-4 w-4' /> Đăng xuất
                    </button>
                  </DropdownItem>
                </DropdownContent>
              </DropdownMenu>
            </DropDown>
            {/* END: Account Menu */}
          </div>
        </div>
      </div>
      {/* END: Top Bar */}
    </Fragment>
  );
}

export default TopBar;
